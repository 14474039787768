import React from 'react';

import { ReactComponent as EyeSlash } from 'bootstrap-icons/icons/eye-slash.svg';
import { ReactComponent as Eye } from 'bootstrap-icons/icons/eye.svg';

interface Props {
  onVisibilityToggle: () => void;
  isVisible: boolean;
}

const MapLegendVisibilityButton = ({
  onVisibilityToggle,
  isVisible,
}: Props): React.ReactElement => {
  if (isVisible) {
    return (
      <div className="cursor-pointer">
        <Eye onClick={onVisibilityToggle} />
      </div>
    );
  }
  return (
    <div className="cursor-pointer text-muted">
      <EyeSlash onClick={onVisibilityToggle} />
    </div>
  );
};

export default MapLegendVisibilityButton;
