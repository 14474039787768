import React, {
  useState,
  useEffect,
  ReactElement,
  ReactText,
  ReactFragment,
  SVGProps,
} from 'react';

import { ReactComponent as BuildingIcon } from 'bootstrap-icons/icons/building.svg';
import { ReactComponent as DropletIcon } from 'bootstrap-icons/icons/droplet-fill.svg';
import { ReactComponent as GearIcon } from 'bootstrap-icons/icons/gear-fill.svg';
import { ReactComponent as GeoIcon } from 'bootstrap-icons/icons/geo-fill.svg';
import { ReactComponent as LightningIcon } from 'bootstrap-icons/icons/lightning-fill.svg';
import { ReactComponent as PeopleIcon } from 'bootstrap-icons/icons/people-fill.svg';
import { ReactComponent as ServerIcon } from 'bootstrap-icons/icons/server.svg';
import { ReactComponent as TrashIcon } from 'bootstrap-icons/icons/trash2-fill.svg';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';

import {
  PowerPlant,
  PowerPlantSection,
  PowerPlantField,
  powerPlantSectionsFieldsMap,
  PowerPlantFieldDisplay,
  formatSources,
} from 'src/components/SitePanel/powerPlants';

const iconProps: SVGProps<SVGSVGElement> = {
  width: '12',
  height: '12',
};

const sectionIconMap = {
  building: <BuildingIcon {...iconProps} />,
  droplet: <DropletIcon {...iconProps} />,
  gear: <GearIcon {...iconProps} />,
  geo: <GeoIcon {...iconProps} />,
  lightning: <LightningIcon {...iconProps} />,
  people: <PeopleIcon {...iconProps} />,
  server: <ServerIcon {...iconProps} />,
  trash: <TrashIcon {...iconProps} />,
};

interface Props {
  attributes: PowerPlant | null;
}

function SiteInfo({ attributes }: Props): React.ReactElement {
  const { t } = useTranslation();

  const [siteAttributes, setSiteAttributes] = useState<PowerPlant | null>(null);

  function renderIcon(name: string) {
    return (
      <span className="sitePanel__sectionIcon">
        {sectionIconMap[name as keyof typeof sectionIconMap]}
      </span>
    );
  }

  function renderField(field: PowerPlantField, index: number) {
    const addSourceField = (
      fieldValue: ReactElement | ReactText | ReactFragment,
    ) => {
      const sourcesField =
        siteAttributes?.[(field.name + '_sources') as keyof PowerPlant];
      return (
        <>
          {fieldValue}
          {field.hasSource &&
            sourcesField &&
            ` (${formatSources(sourcesField)})`}
        </>
      );
    };

    const fieldValue = siteAttributes?.[field.name as keyof PowerPlant];
    let fields: PowerPlantFieldDisplay[] = [];
    const emptyFieldCases = [null, undefined, '', 'unknown'];

    if (
      emptyFieldCases.some((emptyFieldCase) => emptyFieldCase === fieldValue)
    ) {
      fields = [
        {
          title: field.label,
          value: t('misc.emptyValue') as string,
        },
      ];
    } else if (field.formatMany) {
      // Display multiple custom formatted fields
      fields = field.formatMany(fieldValue as string | number);
    } else if (field.format) {
      // Display single custom formatted field
      const formattedValue = addSourceField(
        field.format(fieldValue as string | number),
      );
      fields = [
        {
          title: field.label,
          value: formattedValue,
        },
      ];
    } else {
      // Display raw field value
      fields = [
        {
          title: field.label,
          value: addSourceField(fieldValue as string | number),
        },
      ];
    }
    return fields.map((powerPlantField, i: number) => (
      <React.Fragment key={`PowerPlantField-${index}-${i}`}>
        <dt className="dataList__itemLabel">{powerPlantField.title}</dt>
        <dd className="dataList__itemValue">{powerPlantField.value}</dd>
      </React.Fragment>
    ));
  }

  function renderSection(section: PowerPlantSection) {
    return (
      <Card className="mb-2">
        <Card.Body>
          <Card.Title className="sitePanel__sectionTitle">
            {section.icon &&
              section.icon in sectionIconMap &&
              renderIcon(section.icon)}
            {section.sectionLabel}
          </Card.Title>
          <dl className="dataList">
            {section.fields.map((field, index) => renderField(field, index))}
          </dl>
        </Card.Body>
      </Card>
    );
  }

  useEffect(() => {
    setSiteAttributes(attributes);
  }, [attributes]);

  return (
    <>
      {powerPlantSectionsFieldsMap(t).map((section, index) => (
        <React.Fragment key={`PowerPlantSection-${index}`}>
          {renderSection(section)}
        </React.Fragment>
      ))}
    </>
  );
}

export default SiteInfo;
