import bbox from '@turf/bbox';

import { Area } from 'src/pages/Dashboard/context';

import { AreasOfOperation } from './index';

export const areasOfOperationAdapter = (data: AreasOfOperation): Area[] =>
  data.areas_of_operation.map((ao) => ({
    id: ao.ao_id.toString(),
    name: ao.ao_title,
    polygon: ao.ao_polygon,
    boundingBox: bbox(ao.ao_polygon),
    isSwirMonitored: ao.is_swir_monitored,
  }));
