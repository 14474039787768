export const germanyPolygon: GeoJSON.Polygon = {
  type: 'Polygon',
  coordinates: [
    [
      [8.904138224000093, 54.8979422],
      [8.982686401000109, 54.879338684000132],
      [9.194766480000055, 54.85039988199999],
      [9.211509644000103, 54.841821594000137],
      [9.216057169000123, 54.83174469],
      [9.219054403000143, 54.817792054000122],
      [9.226392456000042, 54.805906474],
      [9.244065796000143, 54.801772360000157],
      [9.317032918000081, 54.801617330000127],
      [9.332019084000137, 54.803219300000151],
      [9.341734253000084, 54.809058736000154],
      [9.355996948000069, 54.811332499000159],
      [9.366952352000112, 54.817016907000109],
      [9.385039103000054, 54.819394023000072],
      [9.405192911000114, 54.808386943000144],
      [9.422142781000019, 54.807250061000062],
      [9.436922241000048, 54.810143942000039],
      [9.437503026532056, 54.810411127058742],
      [9.437510613000143, 54.810410874000112],
      [9.451426629000139, 54.810370184000121],
      [9.510996941000144, 54.827826239000089],
      [9.529958530000073, 54.841498114000117],
      [9.554372592000107, 54.847113348000036],
      [9.566905144000117, 54.860174872000087],
      [9.579925977000102, 54.866278387000179],
      [9.583181186000047, 54.830267645000035],
      [9.631602410000141, 54.819891669000114],
      [9.693044467000107, 54.818019924000154],
      [9.734629754000082, 54.807318427000112],
      [9.752126498000052, 54.799627997000115],
      [9.778086785000141, 54.79315827],
      [9.801768425000148, 54.783392645000063],
      [9.81218509200005, 54.766058661000088],
      [9.823741082000083, 54.756740627000127],
      [9.84986412900011, 54.759344794000029],
      [9.895355665000096, 54.769476630000085],
      [9.891449415000096, 54.790269273000078],
      [9.91504967500012, 54.791205145000148],
      [9.94752037900011, 54.779527085000055],
      [9.970469597000118, 54.762640692000062],
      [9.984141472000147, 54.728501695000077],
      [9.990489129000082, 54.720526434000121],
      [9.999522332000083, 54.712958075000145],
      [10.018239780000073, 54.701239325000174],
      [10.00538170700014, 54.69550202],
      [9.992198113000086, 54.696478583000086],
      [9.979828321000127, 54.699774481000119],
      [9.970469597000118, 54.701239325000174],
      [9.971039259000094, 54.699042059000178],
      [9.946299675000091, 54.687648830000015],
      [9.943125847000147, 54.687567450000031],
      [9.936696811000047, 54.685003973000121],
      [9.932383660000113, 54.685248114000089],
      [9.929942254000082, 54.683091538999989],
      [9.92945397200009, 54.673895575000088],
      [9.944102410000113, 54.673325914000131],
      [9.970225457000083, 54.677923895000063],
      [9.99634850400011, 54.67877838700015],
      [10.01140384200005, 54.667059637000179],
      [10.022146030000044, 54.672430731000034],
      [10.033539259000122, 54.672919012000037],
      [10.039073113000143, 54.666815497000059],
      [10.031911655000101, 54.652777411],
      [10.03695722700013, 54.638902085],
      [10.037608269000089, 54.619086005],
      [10.031911655000101, 54.577704169000143],
      [10.02686608200014, 54.559963283000073],
      [10.020030144000145, 54.545843817000119],
      [10.010427280000073, 54.534328518],
      [9.99781334700009, 54.523667710000083],
      [9.965586785000141, 54.506170966000028],
      [9.925303582000055, 54.493109442000147],
      [9.882009311000076, 54.484849351000051],
      [9.840098504000082, 54.482123114000089],
      [9.840098504000082, 54.475287177000055],
      [9.883311394000089, 54.462103583000115],
      [10.124685092000107, 54.495754299000126],
      [10.143402540000096, 54.491848049000126],
      [10.203868035000085, 54.461004950000174],
      [10.183929884000122, 54.449367580000157],
      [10.183848504000139, 54.438421942],
      [10.191742384000094, 54.426174221000124],
      [10.19703209700009, 54.410101630000057],
      [10.191254102000102, 54.396714585000055],
      [10.177093946000099, 54.384263414000131],
      [10.159027540000068, 54.375311591000113],
      [10.141774936000076, 54.37225983300003],
      [10.144379102000073, 54.370021877000127],
      [10.14779707100007, 54.368312893000123],
      [10.148285352000073, 54.365179755000142],
      [10.141774936000076, 54.358587958000086],
      [10.152517123000081, 54.35179271000014],
      [10.155528191000087, 54.343410549000097],
      [10.151621941000087, 54.333970445000077],
      [10.141774936000076, 54.324408270000092],
      [10.163747592000078, 54.329169012000179],
      [10.180349155000044, 54.341131903000118],
      [10.203868035000085, 54.37225983300003],
      [10.217133009000122, 54.405422268000123],
      [10.224375847000147, 54.413804429],
      [10.234385613000143, 54.417710679],
      [10.272146030000101, 54.420070705000128],
      [10.285817905000044, 54.424750067000062],
      [10.292491082000083, 54.430161851000108],
      [10.297618035000113, 54.43573639500012],
      [10.306895379000139, 54.441107489000089],
      [10.318369988000086, 54.443304755000057],
      [10.360850457000083, 54.441107489000089],
      [10.382823113000086, 54.434759833000143],
      [10.443369988000086, 54.406398830000015],
      [10.601084832000083, 54.365423895000092],
      [10.690196160000141, 54.316229559000178],
      [10.731293165000125, 54.310207424000069],
      [10.777110222000118, 54.315130927000112],
      [10.810801629000139, 54.32737864799999],
      [10.867930535000113, 54.358587958000086],
      [10.900889519000089, 54.37103913],
      [10.935313347000118, 54.379543361000017],
      [11.018809441000087, 54.385891018000152],
      [11.018809441000087, 54.37909577000012],
      [11.000824415000068, 54.378566799000154],
      [10.975840691000144, 54.378404038999989],
      [10.99317467500012, 54.373846747000059],
      [11.010508660000113, 54.377183335000083],
      [11.033946160000085, 54.367621161000088],
      [11.104177280000044, 54.392889716000028],
      [11.135508660000085, 54.385891018000152],
      [11.098399285000085, 54.361517645],
      [11.08773847700013, 54.351752020000148],
      [11.081797722000118, 54.354641018000095],
      [11.066579623000052, 54.358587958000086],
      [11.077403191000144, 54.342230536000059],
      [11.086599155000101, 54.302435614000061],
      [11.094493035000085, 54.283514716000141],
      [11.087087436000104, 54.270900783000158],
      [11.08773847700013, 54.253892320000105],
      [11.094493035000085, 54.225083726000079],
      [11.090179884000094, 54.20799388200011],
      [11.080577019000117, 54.198879299000126],
      [11.070974155000044, 54.192572333],
      [11.066579623000052, 54.183905341000084],
      [10.950450066000116, 54.139471747000172],
      [10.910329623000109, 54.109198309000178],
      [10.892832879000139, 54.102525132000139],
      [10.852386915000125, 54.094305731000034],
      [10.844574415000125, 54.094468492000019],
      [10.836110873000081, 54.089992580000128],
      [10.829356316000144, 54.09284088700015],
      [10.822276238000086, 54.097357489000117],
      [10.813324415000068, 54.097886460000026],
      [10.805430535000085, 54.094875393],
      [10.801442905000101, 54.092230536000116],
      [10.762868686000047, 54.056830145],
      [10.752614780000101, 54.050116278000147],
      [10.752614780000101, 54.043890692],
      [10.765879754000139, 54.024359442000119],
      [10.780446811000076, 54.009955145000148],
      [10.799652540000125, 54.000555731000034],
      [10.826914910000113, 53.995428778000118],
      [10.865570509000065, 53.997788804000081],
      [10.875498894000145, 53.995428778000118],
      [10.882578972000118, 53.985052802000112],
      [10.883555535000113, 53.973130601000079],
      [10.887380405000101, 53.963934637000094],
      [10.902110222000118, 53.961371161],
      [10.90601647200009, 53.961208401000036],
      [10.917653842000107, 53.960760809000035],
      [10.922618035000141, 53.961371161],
      [10.935394727000102, 53.967271226000136],
      [10.96469160200013, 53.985419012000037],
      [10.981211785000113, 53.989243882000054],
      [11.000254754000082, 53.991603908000101],
      [11.053477410000085, 54.008490302],
      [11.175059441000144, 54.018011786],
      [11.18384850400011, 54.016017971000153],
      [11.190765821000099, 54.010321356000148],
      [11.197276238000086, 53.996242580000128],
      [11.204356316000144, 53.989243882000054],
      [11.23991946700005, 53.983465887000179],
      [11.245371941000059, 53.97842031500015],
      [11.240733269000145, 53.955633856000034],
      [11.242930535000141, 53.945217190000122],
      [11.25562584700009, 53.94086334800015],
      [11.28541100400011, 53.939683335000112],
      [11.293711785000085, 53.94086334800015],
      [11.334157748000109, 53.961371161],
      [11.341807488000143, 53.95831940300009],
      [11.352061394000089, 53.944159247000172],
      [11.358246290000039, 53.94086334800015],
      [11.399912957000112, 53.94086334800015],
      [11.400401238000086, 53.937730210000083],
      [11.438731316000116, 53.90912506700009],
      [11.446299675000148, 53.906154690000065],
      [11.457692905000044, 53.906154690000065],
      [11.458994988000114, 53.916693427000141],
      [11.469086134000094, 53.932766018000038],
      [11.47681725400011, 53.952704169000114],
      [11.472422722000118, 53.968817450000174],
      [11.479828321000099, 53.976141669000171],
      [11.489512566000059, 53.980617580000128],
      [11.49789472700013, 53.981838283000158],
      [11.495941602000073, 53.986314195000134],
      [11.493500196000127, 53.997626044000114],
      [11.49170983200014, 54.002346096000011],
      [11.525889519000145, 54.02277252800009],
      [11.525889519000145, 54.030218817000062],
      [11.51905358200014, 54.030218817000062],
      [11.51905358200014, 54.036444403000033],
      [11.544606967000107, 54.03017812700007],
      [11.553233269000117, 54.030218817000062],
      [11.573496941000116, 54.039048570000105],
      [11.591319207000112, 54.065619208000086],
      [11.604746941000087, 54.071234442000062],
      [11.61280358200014, 54.072902736000074],
      [11.61939537900011, 54.077297268000066],
      [11.624522332000112, 54.083644924000012],
      [11.628916863000114, 54.09105052299999],
      [11.629567905000073, 54.096014716000056],
      [11.622080925000091, 54.099554755000113],
      [11.622080925000091, 54.104681708000115],
      [11.625254754000139, 54.109808661000145],
      [11.629405144000089, 54.113226630000057],
      [11.633148634000122, 54.115912177000141],
      [11.657888217000107, 54.142238674000126],
      [11.689707879000139, 54.155218817000119],
      [11.72877037900011, 54.15851471600017],
      [11.81226647200009, 54.149807033000101],
      [12.050629102000102, 54.179917710000112],
      [12.088145379000139, 54.194077867000047],
      [12.09327233200014, 54.165432033000073],
      [12.089528842000107, 54.137640692],
      [12.09164472700013, 54.11399974199999],
      [12.115000847000118, 54.097886460000026],
      [12.105316602000073, 54.110012111000103],
      [12.100352410000113, 54.13597239799999],
      [12.095062696000127, 54.145697333000143],
      [12.105479363000114, 54.157375393000123],
      [12.13428795700014, 54.16697825700011],
      [12.142832879000082, 54.180487372000087],
      [12.131602410000085, 54.181138414000046],
      [12.120860222000147, 54.179999091000113],
      [12.110606316000116, 54.177435614],
      [12.10132897200009, 54.173651434000149],
      [12.108897332000112, 54.183010158000016],
      [12.142832879000082, 54.209173895000148],
      [12.163096550000091, 54.219916083000058],
      [12.186859571000099, 54.243353583000143],
      [12.200938347000118, 54.248724677],
      [12.214040561000104, 54.250962632000082],
      [12.289398634000065, 54.275091864],
      [12.327321811000076, 54.293768622000172],
      [12.342133009000065, 54.303941148000106],
      [12.378184441000116, 54.344916083000143],
      [12.438324415000096, 54.395819403000147],
      [12.444834832000083, 54.405462958000115],
      [12.486094597000118, 54.447333075000031],
      [12.501963738000143, 54.473578192000062],
      [12.513438347000118, 54.482245184000149],
      [12.533864780000073, 54.488267320000077],
      [12.533864780000073, 54.482123114000089],
      [12.527028842000078, 54.482123114000089],
      [12.548350457000112, 54.462062893000123],
      [12.59148196700005, 54.451727606000119],
      [12.819102410000085, 54.451727606000119],
      [12.856618686000076, 54.441107489000089],
      [12.879649285000141, 54.448797919000086],
      [12.908376498000109, 54.444647528000147],
      [12.921153191000144, 54.433498440000122],
      [12.896332227000102, 54.420070705000128],
      [12.870778842000107, 54.416815497000087],
      [12.763682488000086, 54.422186591000141],
      [12.720957879000082, 54.431586005000085],
      [12.694590691000087, 54.433661200000088],
      [12.686778191000087, 54.431626695000077],
      [12.676117384000065, 54.422552802000084],
      [12.670420769000089, 54.420070705000128],
      [12.650075717000078, 54.420070705000128],
      [12.624196811000076, 54.422919012000179],
      [12.614512566000116, 54.419745184000121],
      [12.60840905000012, 54.406398830000015],
      [12.602224155000073, 54.413804429],
      [12.596934441000087, 54.411932684000035],
      [12.588063998000052, 54.407456773000078],
      [12.581716342000107, 54.406398830000015],
      [12.583506707000083, 54.397853908000073],
      [12.587657097000118, 54.394029039000074],
      [12.594004754000053, 54.392767645000063],
      [12.602224155000073, 54.392075914000131],
      [12.598643425000091, 54.391546942000147],
      [12.595876498000024, 54.391506252000156],
      [12.594411655000073, 54.390204169000143],
      [12.594737175000091, 54.385891018000152],
      [12.575531446000127, 54.39199453300013],
      [12.554047071000099, 54.390814520000035],
      [12.535980665000096, 54.383734442000147],
      [12.527028842000078, 54.37225983300003],
      [12.51075280000012, 54.384263414000131],
      [12.490977410000113, 54.390082098000093],
      [12.454763217000078, 54.392075914000131],
      [12.438487175000148, 54.387518622000172],
      [12.424978061000076, 54.375921942000062],
      [12.418467644000089, 54.36066315300009],
      [12.424001498000109, 54.344916083000143],
      [12.409678582000112, 54.346625067000147],
      [12.403819207000083, 54.348089911],
      [12.39673912900011, 54.351752020000148],
      [12.389903191000087, 54.344916083000143],
      [12.396494988000086, 54.332953192],
      [12.391612175000091, 54.321112372000144],
      [12.379079623000109, 54.312445380000057],
      [12.362559441000116, 54.310207424000069],
      [12.369639519000089, 54.296576239000117],
      [12.377940300000148, 54.285874742000104],
      [12.380137566000144, 54.277085679000137],
      [12.368825717000078, 54.269191799000154],
      [12.412445509000094, 54.251410223000065],
      [12.436778191000144, 54.247707424000126],
      [12.458181186000104, 54.255560614000117],
      [12.442637566000087, 54.25519440300009],
      [12.430837436000019, 54.258612372000115],
      [12.410329623000081, 54.269191799000154],
      [12.416840040000068, 54.280951239000146],
      [12.426280144000089, 54.289618231000034],
      [12.451426629000139, 54.303941148000106],
      [12.45728600400011, 54.306301174000069],
      [12.46827233200014, 54.308335679000024],
      [12.47242272200009, 54.310207424000069],
      [12.475596550000148, 54.315130927000112],
      [12.476573113000143, 54.327785549000012],
      [12.478688998000052, 54.331854559000149],
      [12.49561608200014, 54.340277411000031],
      [12.560557488000114, 54.358587958000086],
      [12.547048373000024, 54.364488023000106],
      [12.54078209700009, 54.365423895000092],
      [12.54078209700009, 54.37225983300003],
      [12.559336785000085, 54.376288153000175],
      [12.566091342000107, 54.375921942000062],
      [12.574229363000143, 54.37225983300003],
      [12.574229363000143, 54.37909577000012],
      [12.592946811000104, 54.369330145000092],
      [12.622731967000107, 54.373928127000042],
      [12.677256707000083, 54.392075914000131],
      [12.675059441000116, 54.395412502000156],
      [12.672373894000117, 54.402980861000103],
      [12.670420769000089, 54.406398830000015],
      [12.680837436000076, 54.410874742000075],
      [12.692556186000047, 54.413641669000029],
      [12.704844597000118, 54.414618231000091],
      [12.717539910000085, 54.413804429],
      [12.717539910000085, 54.406398830000015],
      [12.70248457100007, 54.404120184000035],
      [12.692393425000091, 54.396185614000061],
      [12.686534050000148, 54.384751695000105],
      [12.684092644000117, 54.37225983300003],
      [12.696625196000099, 54.384955145000063],
      [12.701182488000143, 54.391669012000122],
      [12.704600457000083, 54.399603583000086],
      [12.713552280000101, 54.390082098000093],
      [12.723806186000019, 54.381252346000124],
      [12.735036655000044, 54.37815989800005],
      [12.746104363000143, 54.385891018000152],
      [12.757823113000143, 54.37978750200007],
      [12.768565300000148, 54.380072333],
      [12.778330925000091, 54.384751695000105],
      [12.787119988000086, 54.392075914000131],
      [12.789886915000125, 54.384344794000114],
      [12.794688347000118, 54.377142645000092],
      [12.800954623000081, 54.370754299000154],
      [12.818044467000107, 54.357977606000119],
      [12.82178795700014, 54.356146552000141],
      [12.825368686000104, 54.357245184000178],
      [12.835459832000083, 54.358587958000086],
      [12.854665561000047, 54.357855536000145],
      [12.864756707000112, 54.359564520000148],
      [12.875987175000091, 54.365423895000092],
      [12.884532097000118, 54.373480536000116],
      [12.894867384000122, 54.38971588700015],
      [12.903819207000055, 54.399603583000086],
      [12.92937259200005, 54.414374091000141],
      [12.969086134000065, 54.429388739000089],
      [13.008799675000148, 54.438055731000176],
      [13.034190300000091, 54.433661200000088],
      [13.021494988000114, 54.426459052000055],
      [13.02100670700014, 54.414536851000108],
      [13.028575066000087, 54.401841539000074],
      [13.04037519600007, 54.392075914000131],
      [13.08366946700005, 54.378973700000145],
      [13.095550977000102, 54.37225983300003],
      [13.076670769000145, 54.352525132000082],
      [13.080739780000016, 54.337144273000135],
      [13.10922285200013, 54.310207424000069],
      [13.112071160000085, 54.299302475999987],
      [13.112071160000085, 54.288478908000101],
      [13.114919467000107, 54.280015367000047],
      [13.125987175000148, 54.27659739800005],
      [13.170664910000113, 54.27659739800005],
      [13.170664910000113, 54.269191799000154],
      [13.161306186000076, 54.269029039000102],
      [13.154633009000122, 54.267320054],
      [13.143402540000125, 54.26300690300009],
      [13.176117384000065, 54.264837958000058],
      [13.228363477000073, 54.247381903000118],
      [13.263194207000083, 54.242499091000056],
      [13.288828972000147, 54.234279690000122],
      [13.32178795700014, 54.194525458000143],
      [13.348887566000144, 54.180487372000087],
      [13.33334394600007, 54.174017645000092],
      [13.321543816000087, 54.166815497000144],
      [13.346527540000096, 54.166937567000119],
      [13.383799675000091, 54.178127346000124],
      [13.403493686000047, 54.173651434000149],
      [13.395681186000047, 54.161932684000178],
      [13.390879754000139, 54.15729401200015],
      [13.382985873000081, 54.153143622000115],
      [13.394786004000139, 54.148911850999987],
      [13.402110222000147, 54.151312567000147],
      [13.408702019000117, 54.15460846600017],
      [13.417735222000118, 54.153143622000115],
      [13.42318769600007, 54.148098049000097],
      [13.437673373000109, 54.125189520000092],
      [13.450368686000076, 54.110419012000094],
      [13.465098504000082, 54.098456122000172],
      [13.483571811000104, 54.091294664000131],
      [13.507090691000144, 54.09105052299999],
      [13.507090691000144, 54.097886460000026],
      [13.498871290000068, 54.102484442000147],
      [13.487478061000104, 54.113918361000103],
      [13.479258660000085, 54.118353583000058],
      [13.479258660000085, 54.125189520000092],
      [13.602712436000019, 54.139471747000172],
      [13.632334832000083, 54.147528387000122],
      [13.68344160200013, 54.168036200000174],
      [13.711924675000148, 54.173651434000149],
      [13.716481967000107, 54.168443101000079],
      [13.714691602000102, 54.16404857],
      [13.708262566000087, 54.160874742000132],
      [13.69825280000012, 54.159328518000095],
      [13.69825280000012, 54.153143622000115],
      [13.728770379000139, 54.143866278000147],
      [13.808116082000083, 54.104681708000115],
      [13.808116082000083, 54.097886460000026],
      [13.793711785000113, 54.083197333],
      [13.785492384000122, 54.063910223000065],
      [13.773203972000147, 54.046332098000121],
      [13.746104363000143, 54.036444403000033],
      [13.758148634000065, 54.030991929],
      [13.787119988000143, 54.009100653000147],
      [13.797618035000113, 53.998928127000013],
      [13.803477410000141, 53.996323960000112],
      [13.831797722000118, 53.989243882000054],
      [13.838633660000113, 53.986314195000134],
      [13.843109571000099, 53.979559637000179],
      [13.846202019000089, 53.972235419000086],
      [13.849131707000083, 53.967596747000144],
      [13.850271030000044, 53.964016018000095],
      [13.848968946000127, 53.959458726000136],
      [13.84864342500012, 53.955552476000051],
      [13.852549675000091, 53.953843492000047],
      [13.866872592000078, 53.954657294000143],
      [13.87012780000012, 53.953843492000047],
      [13.875498894000089, 53.95123932500006],
      [13.881602410000085, 53.949042059000149],
      [13.887950066000116, 53.947577216000084],
      [13.89356530000012, 53.947088934000092],
      [13.906016472000118, 53.943060614000117],
      [13.90756269600007, 53.933539130000142],
      [13.901377800000091, 53.922308661000145],
      [13.890147332000112, 53.912909247000115],
      [13.818532748000081, 53.877997137000094],
      [13.808116082000083, 53.865139065000065],
      [13.817067905000101, 53.852932033000101],
      [13.835785352000073, 53.847479559000149],
      [13.854340040000096, 53.844916083000143],
      [13.86280358200014, 53.841253973],
      [13.869151238000143, 53.830308335000112],
      [13.883799675000063, 53.817694403000147],
      [13.89991295700014, 53.809149481000034],
      [13.910655144000145, 53.810492255000113],
      [13.98422285200013, 53.773993231000148],
      [14.017344597000147, 53.76951732000002],
      [14.033539259000122, 53.757513739000089],
      [14.037445509000122, 53.755275783000101],
      [14.061534050000148, 53.756415106000034],
      [14.068858269000145, 53.755275783000101],
      [14.09400475400011, 53.746323960000055],
      [14.106130405000016, 53.743353583000058],
      [14.18653405000012, 53.739813544000171],
      [14.214691602000102, 53.744859117000104],
      [14.226573113000143, 53.758693752000013],
      [14.23414147200009, 53.761786200000088],
      [14.250173373000081, 53.759955145],
      [14.264496290000068, 53.751613674000126],
      [14.267425977000102, 53.735419012000179],
      [14.259532097000118, 53.727972723],
      [14.21273847700013, 53.708075262000122],
      [14.225352410000113, 53.703762111000017],
      [14.240000847000147, 53.700751044000114],
      [14.256195509000094, 53.699448960000112],
      [14.263900720266179, 53.699976159552662],
      [14.263862752000136, 53.699875794000079],
      [14.265102986000102, 53.671763815000034],
      [14.260968872000149, 53.656209209000011],
      [14.27605839000006, 53.636623841000088],
      [14.297039022000149, 53.597763164000028],
      [14.298899373000069, 53.587892965000052],
      [14.296212199000138, 53.551306051000111],
      [14.29693566900005, 53.529136862000186],
      [14.304170370000094, 53.508517965000081],
      [14.328458293000097, 53.486090394000186],
      [14.338896932000125, 53.465213115000111],
      [14.359877564000044, 53.444077454000151],
      [14.370316202000083, 53.392297669000115],
      [14.391260511000098, 53.334017003000113],
      [14.398014770000088, 53.315222270000064],
      [14.399565064000114, 53.306747335000139],
      [14.400185180000079, 53.288608908000029],
      [14.403285767000028, 53.278790385000079],
      [14.409383586000104, 53.272434184000033],
      [14.424679810000043, 53.264656881000022],
      [14.428607219000099, 53.259075827000018],
      [14.441629680000062, 53.251841126000144],
      [14.410686845982541, 53.225095628717206],
      [14.408246704000078, 53.21440155099999],
      [14.39835776680246, 53.206688569460049],
      [14.380109443072636, 53.202859068540931],
      [14.380651489000087, 53.189855245],
      [14.377860962000113, 53.176548564000186],
      [14.381478312000098, 53.160296326000079],
      [14.38747277900012, 53.146136984000023],
      [14.389539835000107, 53.13166758300018],
      [14.359567505000143, 53.075056051],
      [14.356776977000095, 53.066684469000123],
      [14.343341105000064, 53.04862355500002],
      [14.31689016121531, 53.038840510597431],
      [14.253734172000094, 53.000874533000157],
      [14.192859334000076, 52.981650900000133],
      [14.144490193000109, 52.959920960000133],
      [14.144490193000109, 52.95369395000013],
      [14.150484660000131, 52.943642884],
      [14.162680298000055, 52.908167013000153],
      [14.164954061000088, 52.895661317000148],
      [14.160819946000061, 52.876747742000035],
      [14.150484660000131, 52.864629619000041],
      [14.123922973000049, 52.850651144000139],
      [14.13539514200005, 52.836595154000022],
      [14.15275842300008, 52.82832692500007],
      [14.173739054000066, 52.824347839],
      [14.195959920000092, 52.823365987000116],
      [14.216010375000053, 52.817991639000141],
      [14.248669882000115, 52.794582215],
      [14.275955037000131, 52.78571970600008],
      [14.382201782000095, 52.738151551000058],
      [14.39770471200012, 52.727118633000075],
      [14.431914510000098, 52.686087546000024],
      [14.442353149000041, 52.679963888000074],
      [14.461370076000037, 52.674563701000082],
      [14.49010217300011, 52.650637512000102],
      [14.549943481000071, 52.636193950000163],
      [14.607964828000092, 52.596462963000036],
      [14.613712199000105, 52.592527364000105],
      [14.644821411000066, 52.57692108200014],
      [14.616812785000064, 52.541031800000113],
      [14.609061320000137, 52.517803243000102],
      [14.627664836000093, 52.507416280000157],
      [14.632315715000061, 52.496745098000176],
      [14.615779256000081, 52.473283997000109],
      [14.591594686000093, 52.449797059000119],
      [14.539814901000113, 52.421865947000029],
      [14.54539595600005, 52.382152608000141],
      [14.569167114000095, 52.338511861000185],
      [14.590147746000099, 52.309418030000174],
      [14.584153280000066, 52.291227926000133],
      [14.606580851000075, 52.275750835000125],
      [14.640480590000095, 52.265053813000023],
      [14.668902628000097, 52.260997213000124],
      [14.696911255000117, 52.253478293000072],
      [14.712414184000124, 52.235908305000109],
      [14.712104126000042, 52.215444438000063],
      [14.69257043500005, 52.199579773000139],
      [14.69257043500005, 52.193378601000134],
      [14.707039836000149, 52.179451803000163],
      [14.703215780000022, 52.165163270000093],
      [14.692467082000093, 52.146973165000034],
      [14.686369263000131, 52.121031596000094],
      [14.696394491000063, 52.106691386000094],
      [14.76140344300012, 52.076667379000057],
      [14.74300663300005, 52.062533875],
      [14.735358520000148, 52.054033102000076],
      [14.732154582000078, 52.045067241000069],
      [14.730294230000084, 52.035894674000147],
      [14.726056762000042, 52.026437887],
      [14.704456014000073, 52.002227478000023],
      [14.699081665000108, 51.992512309000077],
      [14.695567667000148, 51.931456604000076],
      [14.687092733000043, 51.911897074000152],
      [14.67169315600006, 51.893887838000168],
      [14.653296346000076, 51.878617453000047],
      [14.595935507000092, 51.84241811200009],
      [14.5958707890469, 51.830733489178897],
      [14.600174130651517, 51.814392358058456],
      [14.618566401058573, 51.803257036443732],
      [14.644489809686638, 51.795885897632502],
      [14.655152568842901, 51.773855466715673],
      [14.651299866851884, 51.763780517281496],
      [14.657343137013443, 51.759435782500631],
      [14.664353790985437, 51.757826750306087],
      [14.657605632585977, 51.753428142576041],
      [14.65989913854235, 51.749146293484806],
      [14.656756713602354, 51.74199166901272],
      [14.666837886302977, 51.730295954648028],
      [14.678152754506575, 51.722667858637294],
      [14.688602165663308, 51.709878806911647],
      [14.713994566180174, 51.697293089103766],
      [14.728872838587208, 51.687910722881796],
      [14.740311814566827, 51.684418582149533],
      [14.752602542645286, 51.668007508635945],
      [14.753800308847188, 51.652086260955038],
      [14.754691203244573, 51.6285144898415],
      [14.765276550061326, 51.615699352964029],
      [14.761895815064422, 51.602993947086645],
      [14.732154582000078, 51.586619772000056],
      [14.72682321975066, 51.574265260366296],
      [14.712724243000112, 51.567421977000095],
      [14.710483563904942, 51.562089727691038],
      [14.719494393491303, 51.554810265730048],
      [14.730606460285493, 51.549356932905596],
      [14.729955239471417, 51.533490804975166],
      [14.744889150645694, 51.524559731447162],
      [14.796820537197787, 51.51714162436005],
      [14.813880353067292, 51.507117091570223],
      [14.813880353067292, 51.50711709157023],
      [14.836190055663227, 51.499506222995336],
      [14.852649485840772, 51.489865816571864],
      [14.865841537527869, 51.489770308717844],
      [14.872120586831882, 51.485616642300194],
      [14.889726423459912, 51.485779260098433],
      [14.899846167039284, 51.481965568147118],
      [14.916832531221146, 51.483418009512228],
      [14.927214374802446, 51.472518139235305],
      [14.950938976221195, 51.470548118936385],
      [14.961600789622292, 51.454544624832742],
      [14.968472643477329, 51.445301001835247],
      [14.973588159390957, 51.435572626525556],
      [14.971250367314186, 51.431594842093567],
      [14.959726881710642, 51.43324424889385],
      [14.965353629761942, 51.42654298112938],
      [14.965668106453833, 51.394502074268487],
      [14.981453086488953, 51.373725472958355],
      [14.966291447329354, 51.361199378425013],
      [14.979916413267908, 51.343450854750245],
      [14.991849708500599, 51.324311843772797],
      [15.009043760895974, 51.308822765627021],
      [15.025147490872623, 51.297414430296193],
      [15.041679408279695, 51.272086326677424],
      [15.02383582055875, 51.252904132613857],
      [15.034904676860851, 51.246102039092982],
      [15.022059367000054, 51.236770325000052],
      [15.012249658526422, 51.214074796651737],
      [15.000506333232833, 51.185694989872033],
      [14.993647566837549, 51.159271034706848],
      [14.995122904046868, 51.134410170771332],
      [14.99044362783744, 51.118288920950853],
      [14.981869880430533, 51.110391778928864],
      [14.984567292267871, 51.102485586092563],
      [14.978539888465566, 51.088587069457127],
      [14.97874205387259, 51.080790312597649],
      [14.970698690244518, 51.069358952252102],
      [14.96443618423285, 51.054601488181731],
      [14.910644979000097, 50.992650859000108],
      [14.860208781000097, 50.916841533000095],
      [14.824655395000036, 50.891830139000106],
      [14.810496053000065, 50.877050680000096],
      [14.810392700000136, 50.85844716400014],
      [14.794579712000143, 50.831627096000048],
      [14.785071248000065, 50.820051575000107],
      [14.775356079000119, 50.812971904000094],
      [14.759233032000054, 50.810181376000045],
      [14.737528931000043, 50.81069814100006],
      [14.700425252000059, 50.815969137000096],
      [14.662184692000068, 50.834365946],
      [14.647921997000111, 50.839378561000061],
      [14.61319543500008, 50.845579732000047],
      [14.608337850000055, 50.853072815000175],
      [14.611851847000111, 50.870952861000134],
      [14.628801717000101, 50.896119283000175],
      [14.634589478000095, 50.909400127000183],
      [14.629215128000112, 50.920717266000153],
      [14.617122843000118, 50.920872294],
      [14.561829061000111, 50.906351217000136],
      [14.550356893000099, 50.912087301000113],
      [14.555834594000089, 50.924282939000037],
      [14.567823527000144, 50.938648987000036],
      [14.575574992000043, 50.950689596000089],
      [14.577435343000047, 50.965727437000098],
      [14.574334758000106, 50.975494283000145],
      [14.566686645000118, 50.983400777999989],
      [14.554594360000038, 50.992650859000108],
      [14.542295369000101, 50.998748678000155],
      [14.515320272000082, 51.003037822000024],
      [14.502917928000102, 51.008515524000117],
      [14.493512818000056, 51.01670623800014],
      [14.49010217300011, 51.022700704000059],
      [14.48793176300012, 51.028721009000108],
      [14.482144002000069, 51.0371959430001],
      [14.473462362000106, 51.023372498000143],
      [14.462093547000109, 51.01965179400004],
      [14.425816691000136, 51.020943706000182],
      [14.39780806400006, 51.013114726000154],
      [14.386749308000049, 51.013269756000014],
      [14.375897257000105, 51.016990459000013],
      [14.369386027000132, 51.022442322000089],
      [14.364115031000097, 51.027997538000093],
      [14.356570272000056, 51.032338359000093],
      [14.319363240000115, 51.040012309000176],
      [14.287530558000071, 51.036834208000116],
      [14.263552694000083, 51.021434632000123],
      [14.249496704000137, 50.992650859000108],
      [14.238334595000111, 50.982470602000134],
      [14.250013469000095, 50.977613017000024],
      [14.283809855000072, 50.974822490000165],
      [14.303756958000093, 50.96970652300017],
      [14.302206665000085, 50.967691142000106],
      [14.293731730000076, 50.963970439000107],
      [14.292801554000107, 50.953480124000166],
      [14.318329712000065, 50.937512106000113],
      [14.355226685000076, 50.930639140000054],
      [14.381891724000127, 50.920872294],
      [14.375897257000105, 50.895964254000134],
      [14.346545044000038, 50.880202942000054],
      [14.268100220000093, 50.884130351000024],
      [14.23213342300005, 50.879376119000128],
      [14.221281372000078, 50.8726065070001],
      [14.202987915000108, 50.855139873000141],
      [14.190792277000099, 50.847905172000154],
      [14.034936157000061, 50.802584941000148],
      [14.015712524000037, 50.801758118000137],
      [13.979125610000096, 50.804755351000054],
      [13.959591919000076, 50.802068177000123],
      [13.948843221000061, 50.797210592000013],
      [13.933546997000093, 50.784808248000118],
      [13.923315064000121, 50.779950664000111],
      [13.910706014000084, 50.778762105000098],
      [13.900990844000148, 50.780622457],
      [13.892515910000071, 50.780415752000138],
      [13.883317505000065, 50.773232727000092],
      [13.879080037000108, 50.763517558000146],
      [13.880320272000034, 50.755042623000136],
      [13.882697388000082, 50.748014628000035],
      [13.882180624000057, 50.742743632],
      [13.87535933400008, 50.736542461000013],
      [13.869571574000105, 50.735250549000071],
      [13.863473755000143, 50.735147197000131],
      [13.834534953000087, 50.72367502900012],
      [13.816551554000114, 50.721349589000098],
      [13.770662882000039, 50.724605204000071],
      [13.74906213400007, 50.723209941],
      [13.71102828000005, 50.71426991800017],
      [13.691752970000096, 50.711841126000095],
      [13.601939331000096, 50.712151185],
      [13.556567423000075, 50.706725159000101],
      [13.515639689000068, 50.691015524000065],
      [13.527215210000094, 50.675667623000081],
      [13.523287801000038, 50.662180075000109],
      [13.509955281000117, 50.651224671000037],
      [13.493315471000102, 50.643214823000065],
      [13.49910323000006, 50.635204977000072],
      [13.498173055000109, 50.629158834000137],
      [13.492178589000076, 50.624456279000142],
      [13.482256713000083, 50.620890605000099],
      [13.464893432000082, 50.607092998000141],
      [13.447943563000052, 50.597274476000067],
      [13.429340048000114, 50.594328919000176],
      [13.407015828000056, 50.601305237000176],
      [13.400401245000126, 50.606576234],
      [13.386345256000112, 50.621614075000181],
      [13.380970906000073, 50.625489807000136],
      [13.368775268000149, 50.628332011000012],
      [13.365984741000091, 50.62709177699999],
      [13.365364624000108, 50.62347442700009],
      [13.359370158000104, 50.619340312000176],
      [13.321853068000109, 50.602390443000061],
      [13.316272013000088, 50.59603424100014],
      [13.305626668000087, 50.575777080000037],
      [13.284646037000101, 50.568955790000174],
      [13.268006226000068, 50.573658346000158],
      [13.251779825000142, 50.580893047000032],
      [13.232349487000079, 50.582029928000125],
      [13.199069865000126, 50.525340882000037],
      [13.184703817000127, 50.508752747000031],
      [13.160105835000138, 50.497022197000135],
      [13.107395874000133, 50.498985901000154],
      [13.079180542000074, 50.492784729000093],
      [13.078973836000102, 50.492681376000164],
      [13.078870483000088, 50.492681376000164],
      [13.069775431000096, 50.491079407000129],
      [13.06037032100005, 50.490614319000187],
      [13.051068563000115, 50.491131084000031],
      [13.042283570000052, 50.492784729000093],
      [13.03453210400005, 50.496505432],
      [13.026263875000097, 50.497642314000117],
      [13.017892293000102, 50.496350404000154],
      [13.00962406400015, 50.492681376000164],
      [13.003112833000102, 50.451908672000073],
      [12.996601603000045, 50.433666891000129],
      [12.982442261000074, 50.422969870000102],
      [12.960631103000111, 50.409234123000132],
      [12.952573283000078, 50.404159648000117],
      [12.918363485000071, 50.40539988200014],
      [12.825759318000109, 50.441418356000113],
      [12.817387736000029, 50.443020325000148],
      [12.80839603700008, 50.441831767000124],
      [12.794340047000077, 50.435888977000118],
      [12.788138875000101, 50.43433868400011],
      [12.770258830000046, 50.435940654000049],
      [12.754239136000081, 50.435320536000077],
      [12.737909383000044, 50.431548157000137],
      [12.725093628000138, 50.423434958000044],
      [12.702976115000041, 50.4015241500001],
      [12.691814006000101, 50.394651184000125],
      [12.678791544000092, 50.393721008000071],
      [12.625358114000051, 50.39992218100015],
      [12.550530640000119, 50.396356507000078],
      [12.510223022000076, 50.388760071000107],
      [12.482524454000043, 50.373567200000096],
      [12.475083048000045, 50.369433085000153],
      [12.471569051000074, 50.364472148000019],
      [12.469501994000098, 50.359407858000154],
      [12.466814819000092, 50.354912008000113],
      [12.467021525000121, 50.352844951000165],
      [12.46836511300009, 50.34948598300015],
      [12.468985229000083, 50.345558574000094],
      [12.466814819000092, 50.341889547000093],
      [12.462784058000096, 50.340494283000012],
      [12.453792359000147, 50.340700990000144],
      [12.450175008000144, 50.33935740200009],
      [12.438289429000122, 50.332536113000131],
      [12.408937215000066, 50.321994121000088],
      [12.398188517000051, 50.315172831000112],
      [12.367182658000104, 50.279051005000113],
      [12.355813842000117, 50.271402894000133],
      [12.344651733000092, 50.266390280000067],
      [12.336486857000068, 50.258587138000152],
      [12.333799683000052, 50.242722473000057],
      [12.314059286000088, 50.226496074000025],
      [12.308684936000105, 50.21709096299999],
      [12.305584351000078, 50.19931427000013],
      [12.308581583000091, 50.186911927000082],
      [12.314369344000056, 50.176214905000066],
      [12.314369344000056, 50.167326559000159],
      [12.300416707000068, 50.160815328000083],
      [12.28336348400012, 50.162210592000164],
      [12.273234904000077, 50.172339173000026],
      [12.26124597200004, 50.199882711],
      [12.260212443000057, 50.205050355000097],
      [12.258455445000067, 50.210889791000099],
      [12.254321330000039, 50.217401021000157],
      [12.249980510000057, 50.221173401],
      [12.232513875000109, 50.232490540000143],
      [12.229930054000107, 50.234815979000089],
      [12.228896525000039, 50.237038066000096],
      [12.229826701000093, 50.239053447000131],
      [12.232513875000109, 50.240965475000067],
      [12.233754110000064, 50.241688944000074],
      [12.235097697000128, 50.242257385000116],
      [12.23644128400008, 50.242567445000091],
      [12.237991577000116, 50.242722473000057],
      [12.242435750000112, 50.248355204000077],
      [12.24295251500007, 50.252954407000018],
      [12.239645223000139, 50.256571758000106],
      [12.232513875000109, 50.259103902],
      [12.20378177900011, 50.259568990000119],
      [12.189209025000139, 50.262462871000096],
      [12.178046916000113, 50.268767395000012],
      [12.174119507000057, 50.276570537000126],
      [12.174429565000139, 50.293727112000013],
      [12.168538452000121, 50.302977193000018],
      [12.149314819000011, 50.311710511000072],
      [12.101343547000056, 50.313980338000036],
      [12.076140991000045, 50.315172831000112],
      [12.099188680000083, 50.299773255000147],
      [12.107250203000092, 50.290884908000024],
      [12.110970906000091, 50.276622213000152],
      [12.109110555000086, 50.263806458000076],
      [12.102392619000057, 50.259155579000108],
      [12.092057333000071, 50.254814758000023],
      [12.079551635000144, 50.242722473000057],
      [12.089886922000062, 50.230836894],
      [12.139289592000097, 50.21104482000014],
      [12.163784220000139, 50.193784892000124],
      [12.175049682000093, 50.182622783000014],
      [12.182697795000081, 50.170737203000087],
      [12.185591675000069, 50.155699361000089],
      [12.178563680000053, 50.132961731000123],
      [12.179183797000121, 50.117975566000055],
      [12.187865438000102, 50.102834372000032],
      [12.202024780000045, 50.094514465000159],
      [12.218044474000095, 50.088571676000143],
      [12.232513875000109, 50.080510152000059],
      [12.241402221000044, 50.070639954000072],
      [12.243469279000095, 50.060408020000111],
      [12.243675984000049, 50.051312969000136],
      [12.246879923000108, 50.044956767000102],
      [12.398188517000051, 49.992763570000037],
      [12.399222046000119, 49.992763570000037],
      [12.414518270000087, 49.98304840100009],
      [12.451311889000067, 49.980567932000113],
      [12.468158406000043, 49.970232646000014],
      [12.470742228000063, 49.95560821600013],
      [12.463817586000062, 49.942120666000065],
      [12.462680705000139, 49.931268616000025],
      [12.503608439000089, 49.915765687000103],
      [12.517871135000064, 49.912200012000042],
      [12.524072306000051, 49.904965312000073],
      [12.520971720000091, 49.885534973000077],
      [12.51291019700011, 49.870238749000052],
      [12.489862508000044, 49.842695211000049],
      [12.482524454000043, 49.827243958000153],
      [12.46247399900011, 49.831223043000037],
      [12.456376180000063, 49.817115377],
      [12.455859415000106, 49.796134746000106],
      [12.452655477000121, 49.779701640000141],
      [12.436739135000096, 49.769263001000084],
      [12.395397990000077, 49.75789418600003],
      [12.38361576300008, 49.742856344000032],
      [12.388576700000044, 49.732314351000142],
      [12.398911987000133, 49.719498597000083],
      [12.419582560000066, 49.700223287000043],
      [12.433948608000065, 49.691800029000049],
      [12.449244832000119, 49.685030416000117],
      [12.482524454000043, 49.674695130000103],
      [12.49637373900012, 49.669992575000109],
      [12.504641968000072, 49.659398905000145],
      [12.505365437000137, 49.646118063000031],
      [12.496890503000145, 49.633870748],
      [12.499474324000062, 49.632527161000056],
      [12.507432495000103, 49.629995016000137],
      [12.502678263000121, 49.622140198000025],
      [12.501954793000039, 49.61971140600015],
      [12.512186727000113, 49.611649882000066],
      [12.536061239000105, 49.603123271000143],
      [12.546603230000073, 49.595578512000102],
      [12.553631225000061, 49.584726461000159],
      [12.558282104000028, 49.56255727200012],
      [12.562312867000117, 49.550826722000139],
      [12.568720744000075, 49.538837789000084],
      [12.575025268000076, 49.529897766000076],
      [12.58288008700012, 49.522404683000119],
      [12.593732137000075, 49.515014954000108],
      [12.604997599000114, 49.512792867000101],
      [12.61553959200009, 49.513774720000086],
      [12.622980998000116, 49.509847310000012],
      [12.624324585000068, 49.493000794000054],
      [12.627218465000055, 49.460186259000025],
      [12.632179402000105, 49.443959859000117],
      [12.643548218000092, 49.429490457999989],
      [12.663081909000113, 49.41889679000009],
      [12.709177286000028, 49.404737447000159],
      [12.729331095000106, 49.391146546000144],
      [12.762093953000118, 49.343242493000147],
      [12.777906941000111, 49.33254547200012],
      [12.797750692000108, 49.327842917000069],
      [12.856661825000117, 49.322210185000031],
      [12.875368693000098, 49.323863831],
      [12.870924519000084, 49.33673126300009],
      [12.884670451000119, 49.339573467000051],
      [12.92291101100011, 49.33270050099999],
      [12.939860881000101, 49.326757711000155],
      [12.954330282000058, 49.3191095990001],
      [12.982442261000074, 49.299627584000106],
      [12.999805542000104, 49.294925029000026],
      [13.005386597000012, 49.286760153],
      [13.006730184000077, 49.277096659000065],
      [13.011277710000115, 49.267794902000148],
      [13.043213745000088, 49.24293853799999],
      [13.043213745000088, 49.242886861000088],
      [13.043420451000145, 49.242783509000148],
      [13.05582279500004, 49.238184306],
      [13.149460489000148, 49.154830221000154],
      [13.162586303000126, 49.135141500000103],
      [13.178502645000066, 49.118346660000142],
      [13.206821330000139, 49.107494609000085],
      [13.267282755000082, 49.105634257000091],
      [13.287333211000117, 49.100569967000112],
      [13.299528849000126, 49.093645325000026],
      [13.32991459100009, 49.066928610000062],
      [13.372392618000049, 49.038506572000145],
      [13.383554728000149, 49.021091614000014],
      [13.384174845000132, 48.993031311],
      [13.398540893000131, 48.977683411000115],
      [13.427272990000063, 48.959958395000015],
      [13.458898966000049, 48.945023906000145],
      [13.482256713000083, 48.937634176000032],
      [13.492592, 48.95504913300006],
      [13.50210046400008, 48.966056214000062],
      [13.515949747000036, 48.970242005],
      [13.522395447000093, 48.969341930000056],
      [13.538894083000088, 48.967038066000114],
      [13.55646407100005, 48.959958395000015],
      [13.573413940000137, 48.951018372000178],
      [13.590467163000085, 48.9448172],
      [13.608657267000126, 48.946160787000153],
      [13.611137736000103, 48.927247213000115],
      [13.621783081000103, 48.909057109000074],
      [13.637389363000125, 48.893450826000119],
      [13.654442586000073, 48.882237040000078],
      [13.673356160000111, 48.87634592799999],
      [13.71061486800005, 48.872470195000133],
      [13.724670858000138, 48.867302552000027],
      [13.747925252000073, 48.843376363000047],
      [13.796191040000082, 48.779814352000145],
      [13.815724731000103, 48.766430156000027],
      [13.785855754000067, 48.72477895100009],
      [13.783581991000062, 48.715270488000115],
      [13.788749634000055, 48.716510723000155],
      [13.799394979000056, 48.70260976200008],
      [13.804355916000105, 48.699560852000062],
      [13.81686161200011, 48.695581767000149],
      [13.803529093000066, 48.687158508],
      [13.800945272000064, 48.675221253000032],
      [13.806216268000099, 48.64292348300016],
      [13.805492798000103, 48.626128642000097],
      [13.802392211000068, 48.611710918000185],
      [13.796191040000082, 48.598585104000037],
      [13.779034464000119, 48.573935446000021],
      [13.774280233000127, 48.569207052000039],
      [13.766012003000071, 48.563755188000144],
      [13.758053833000105, 48.561481425000053],
      [13.737279907000072, 48.559259339000121],
      [13.733869263000145, 48.559776103000061],
      [13.72539432800005, 48.548381450000178],
      [13.716505981000125, 48.521690572000026],
      [13.714335571000049, 48.52321502700012],
      [13.673356160000111, 48.535488180000172],
      [13.658059936000143, 48.551094463000098],
      [13.641833537000139, 48.559104309000091],
      [13.624366902000105, 48.565357158],
      [13.520703979000132, 48.584580791000022],
      [13.48721765200014, 48.581583557],
      [13.454558146000068, 48.573444519000091],
      [13.445566447000118, 48.567915141000086],
      [13.440088745000111, 48.560964661000085],
      [13.440708862000093, 48.557347311000015],
      [13.443706095000096, 48.552851461],
      [13.447736857000109, 48.534816386],
      [13.457038615000045, 48.525178732000157],
      [13.459209025000121, 48.51639373799999],
      [13.456418498000062, 48.506652731000045],
      [13.442982625000099, 48.487713319000093],
      [13.438228394000106, 48.478540752000086],
      [13.436368042000112, 48.46923899400015],
      [13.438228394000106, 48.440997824000092],
      [13.436161336000055, 48.430585022000074],
      [13.431717163000144, 48.423712057],
      [13.427169637000105, 48.418544413000106],
      [13.425205932000097, 48.413376770000085],
      [13.41952152500005, 48.392163595000127],
      [13.405568888000062, 48.37658315100002],
      [13.307797079000096, 48.319635722000115],
      [13.274207397000055, 48.307104187000121],
      [13.136438029000118, 48.291058655000157],
      [13.032775106000145, 48.263566793000095],
      [13.01665205900008, 48.255892843000098],
      [12.979445027000082, 48.232018332000038],
      [12.947198934000113, 48.220003561000155],
      [12.931696004000088, 48.211631979000074],
      [12.933039591000068, 48.209254863000027],
      [12.877642456000103, 48.202046001000085],
      [12.862552938000107, 48.196645813000103],
      [12.853457886000029, 48.1899795530001],
      [12.835164429000145, 48.170704244000049],
      [12.822141968000039, 48.160679017000135],
      [12.782351115000012, 48.142023825000152],
      [12.778320353000112, 48.138716533000078],
      [12.764057658000127, 48.123756205000106],
      [12.75795983900008, 48.121895854000101],
      [12.751448608000118, 48.122102560000044],
      [12.745040731000074, 48.120629782000051],
      [12.738942912000113, 48.113446757000091],
      [12.736979207000076, 48.099959209000033],
      [12.742043498000072, 48.086781718000154],
      [12.750931844000092, 48.07474111000009],
      [12.760233602000113, 48.064870911000114],
      [12.830616903000134, 48.015468242000068],
      [12.845603068000088, 47.993144023000028],
      [12.84824627100005, 47.982388920000105],
      [12.848600301000118, 47.980948385000104],
      [12.853974650000083, 47.970483907000144],
      [12.862036173000149, 47.962551575000091],
      [12.88601403800007, 47.952888082000172],
      [12.906167847000063, 47.938909607000156],
      [12.914229370000044, 47.935989889],
      [12.931075887000134, 47.924879456000056],
      [12.964975626000125, 47.871962790000097],
      [12.986576375000112, 47.850258688000096],
      [12.99122725400008, 47.847106426000138],
      [12.926735067000038, 47.777394918000098],
      [12.921257365000145, 47.769540101000146],
      [12.91929366100004, 47.763183899000026],
      [12.918983602000139, 47.756776022000096],
      [12.917226603000074, 47.750161438000092],
      [12.910818726000116, 47.743081767000078],
      [12.910818726000116, 47.743030090000062],
      [12.910612019000069, 47.742926738000122],
      [12.892008504000103, 47.723548076000057],
      [12.90833825600015, 47.712360129000118],
      [12.964045451000089, 47.705357971000026],
      [12.979031616000071, 47.707089132000092],
      [13.004353068000029, 47.714608053000134],
      [13.019855998000139, 47.712928569000084],
      [13.032258341000102, 47.706624044000151],
      [13.044557332000068, 47.696572978000134],
      [13.064091024000078, 47.67399037700001],
      [13.072049194000044, 47.65946929900015],
      [13.074839722000092, 47.646653545000063],
      [13.07421960400012, 47.634509583000167],
      [13.072049194000044, 47.622417298000087],
      [13.069568725000067, 47.620246887000107],
      [13.057683146000045, 47.600428976000032],
      [13.057063029000062, 47.597664286000068],
      [13.040939982000083, 47.583349915000085],
      [13.038252807000049, 47.58402170900014],
      [13.039079631000078, 47.561335755000087],
      [13.041250041000069, 47.561904196000128],
      [13.040113159000043, 47.560457255000145],
      [13.030604696000069, 47.54425669400014],
      [13.028124227000092, 47.541647034000121],
      [13.028124227000092, 47.524232077000093],
      [13.028951050000103, 47.518030905000117],
      [13.031224812000119, 47.512553203000138],
      [13.034428751000092, 47.507437236000143],
      [13.037012573000112, 47.50136525500001],
      [13.03732263100008, 47.493045350000116],
      [13.008797241000138, 47.470101014000093],
      [13.001872599000052, 47.466018576000081],
      [12.991123901000037, 47.465682679000011],
      [12.984922729000061, 47.468266500000098],
      [12.979341674000125, 47.472245586],
      [12.969729858000022, 47.475733745000028],
      [12.959291219000079, 47.475527039000085],
      [12.951022990000126, 47.472478130000141],
      [12.942548055000145, 47.470488587000105],
      [12.931592651000074, 47.473511658000021],
      [12.882603393000068, 47.498574728000122],
      [12.83692142700005, 47.532655335000086],
      [12.828446492000126, 47.537254538000141],
      [12.81904138100009, 47.539528300000157],
      [12.803641805000098, 47.54133697500005],
      [12.778940470000094, 47.554824524000125],
      [12.773669474000144, 47.579500021000044],
      [12.785865112000067, 47.602676901000123],
      [12.81315026900009, 47.611823629000114],
      [12.793306518000094, 47.624794413000032],
      [12.767364950000058, 47.636369935000076],
      [12.751655314000089, 47.64939239600001],
      [12.761990600000075, 47.66685902900015],
      [12.744834025000017, 47.665360413000045],
      [12.688713419000067, 47.675127259000092],
      [12.653056681000095, 47.675127259000092],
      [12.617399943000095, 47.669339498000127],
      [12.598383015000138, 47.662879944000068],
      [12.563374919000069, 47.641761370000111],
      [12.553837931000118, 47.636008199000074],
      [12.538231649000096, 47.631331482000022],
      [12.517147664000049, 47.628540955000133],
      [12.496580444000074, 47.628902690000146],
      [12.482524454000043, 47.633579407000028],
      [12.452758830000079, 47.649754130000119],
      [12.44469730600008, 47.65629119900008],
      [12.439219604000073, 47.66448191400012],
      [12.428780965000044, 47.685798442000092],
      [12.424130086000076, 47.691560365000086],
      [12.407696980000111, 47.693730774000144],
      [12.368422892000041, 47.683550517000086],
      [12.35147302300004, 47.681690166000081],
      [12.293698771000038, 47.690010071000145],
      [12.271891317000041, 47.687891337000067],
      [12.249567098000057, 47.680010682000116],
      [12.238715047000113, 47.678899639000051],
      [12.22879317200011, 47.684868266000151],
      [12.223315470000017, 47.69590118400005],
      [12.226622762000119, 47.70287750200005],
      [12.233030639000077, 47.708871969000157],
      [12.236544637000122, 47.716778463000125],
      [12.239128459000113, 47.727449646000125],
      [12.242229044000055, 47.732023011000152],
      [12.239748576000096, 47.731687114000081],
      [12.22517582200004, 47.727372132000099],
      [12.216494181000058, 47.723703105000098],
      [12.192412964000113, 47.71016388000011],
      [12.176599976000119, 47.705823059000139],
      [12.178563680000053, 47.697658183000115],
      [12.181767619000141, 47.69212880500011],
      [12.189105672000039, 47.685565898000149],
      [12.197270549000052, 47.680088196000057],
      [12.202334839000116, 47.677788595000138],
      [12.20512536600009, 47.672285055000046],
      [12.206779012000112, 47.645568339000178],
      [12.205745483000044, 47.636214905000045],
      [12.202128133000144, 47.629832866000086],
      [12.173602742000099, 47.605079855000085],
      [12.165644572000133, 47.603968811000172],
      [11.935891154000046, 47.610738424000104],
      [11.851968627000133, 47.599162903000078],
      [11.840703166000054, 47.594615377000039],
      [11.830677938000122, 47.577613831000022],
      [11.820342651000118, 47.575288391000086],
      [11.764015340000128, 47.583117371000114],
      [11.682573283000096, 47.583349915000085],
      [11.63895837400014, 47.589241028000075],
      [11.620458211000113, 47.589654440000075],
      [11.6170475670001, 47.586760560000087],
      [11.589142293000123, 47.570379131000053],
      [11.58232100400005, 47.559785462000079],
      [11.574466186000109, 47.536634420000084],
      [11.569401896000045, 47.527384339000079],
      [11.551211792000117, 47.513690084000061],
      [11.529507690000116, 47.507850648000058],
      [11.482585490000076, 47.502579651000033],
      [11.459951212000107, 47.507437236000143],
      [11.435353231000136, 47.509710999000063],
      [11.412615600000066, 47.506093648000089],
      [11.36548669400014, 47.46932586700008],
      [11.377785685000077, 47.467052104000075],
      [11.388637736000135, 47.462220357000049],
      [11.392461792000063, 47.454778951000137],
      [11.383883504000039, 47.444857076000133],
      [11.36765710500012, 47.44012868300014],
      [11.324248901000146, 47.439431051000057],
      [11.305645385000076, 47.435400290000175],
      [11.286318400000141, 47.423204651000063],
      [11.273089233000121, 47.411112366000069],
      [11.258826538000079, 47.400725403000067],
      [11.237019083000121, 47.393955790000021],
      [11.213041219000019, 47.395816142000015],
      [11.215211629000123, 47.422997945000091],
      [11.193817587000098, 47.428940735000126],
      [11.168599487000051, 47.424264018000045],
      [11.103280477000112, 47.393568218000027],
      [11.083746785000102, 47.3895116180001],
      [10.979463745000146, 47.390545146000179],
      [10.965511108000072, 47.396074524000184],
      [10.955279175000101, 47.409613750000162],
      [10.95765629100012, 47.409717103],
      [10.960860229000104, 47.414316305000128],
      [10.96313399200011, 47.421240947000044],
      [10.962823934000141, 47.427958883000045],
      [10.959826701000111, 47.432506409],
      [10.909907267000051, 47.46898997000001],
      [10.858437540000068, 47.485164693000101],
      [10.851616252000042, 47.493097026000058],
      [10.883862346000086, 47.508005676],
      [10.892233928000053, 47.514878643000159],
      [10.86928959100004, 47.526583354000039],
      [10.858644247000115, 47.53066579200005],
      [10.844794962000066, 47.531311747000032],
      [10.830635620000123, 47.528572896000085],
      [10.79032800300007, 47.51606720000008],
      [10.760769083000127, 47.51363840700013],
      [10.752190795000104, 47.51539540600011],
      [10.747229858000139, 47.520149638000092],
      [10.743922567000141, 47.525317281000113],
      [10.73989180500007, 47.528469544000146],
      [10.607910197000109, 47.562265930000038],
      [10.583725627000121, 47.56247263600001],
      [10.569876343000061, 47.55593556700002],
      [10.549619181000082, 47.536789450000029],
      [10.536906779000105, 47.529864808000056],
      [10.525124552000108, 47.528469544000146],
      [10.482543172000135, 47.532862040000154],
      [10.466936890000113, 47.53771962500015],
      [10.458772013000072, 47.541647034000121],
      [10.453501017000065, 47.546246236000101],
      [10.451744018000085, 47.554824524000125],
      [10.455464721000084, 47.561180726000046],
      [10.460322306000108, 47.565986633000151],
      [10.461562540000045, 47.569914043000111],
      [10.457118368000124, 47.579241638000056],
      [10.453604370000079, 47.58110199000005],
      [10.445956258000109, 47.579060771000016],
      [10.429213094000147, 47.577019552000067],
      [10.41422692900008, 47.573014628000166],
      [10.415673868000084, 47.564384665000105],
      [10.424355509000122, 47.553351746000104],
      [10.430970093000042, 47.54216379800009],
      [10.429729858000087, 47.532913717000056],
      [10.41960127800013, 47.493045350000116],
      [10.433657267000058, 47.487851868000021],
      [10.442235555000082, 47.481392314000075],
      [10.447609904000046, 47.47258148300007],
      [10.451744018000085, 47.460204977000032],
      [10.451950724000113, 47.438759257000086],
      [10.442855672000064, 47.41628001],
      [10.428282918000093, 47.396048686000157],
      [10.411643107000089, 47.381036683],
      [10.403064819000065, 47.377109273000045],
      [10.381980835000121, 47.372122498000081],
      [10.371645549000107, 47.367394104000098],
      [10.365651082000085, 47.361115418000097],
      [10.343430216000058, 47.330755514000046],
      [10.325446818000074, 47.31432240900007],
      [10.305913126000064, 47.302178447000173],
      [10.2611613360001, 47.283497416],
      [10.239353882000074, 47.277735494000027],
      [10.17495085000013, 47.272375554000021],
      [10.159875529000061, 47.27112091100004],
      [10.155431355000076, 47.272877909000115],
      [10.15946211800005, 47.279130758000022],
      [10.168660522000067, 47.29042205800009],
      [10.172691284000052, 47.292308248],
      [10.185920451000129, 47.294995423000117],
      [10.190778036000069, 47.298251038],
      [10.193258504000141, 47.30491729800012],
      [10.192741739000098, 47.310911764000039],
      [10.190467977000083, 47.317474671000028],
      [10.208554729000099, 47.354474996000178],
      [10.209278198000106, 47.372484233000179],
      [10.190881388000093, 47.379228008000112],
      [10.146956420000066, 47.373801982000046],
      [10.135070842000118, 47.364887797],
      [10.130833374000076, 47.362665711000105],
      [10.125665731000083, 47.362691549000104],
      [10.11533044400008, 47.366954855000174],
      [10.110369507000115, 47.367394104000098],
      [10.097243693000053, 47.363130799000047],
      [10.089595581000083, 47.359280904000101],
      [10.082877644000064, 47.359074199000034],
      [10.073162475000117, 47.36548207599999],
      [10.067788127000142, 47.375042217000086],
      [10.066857951000088, 47.385790914],
      [10.064170776000083, 47.396177878000017],
      [10.053215373000086, 47.404859518000151],
      [10.07595300300008, 47.416874288000045],
      [10.080603881000116, 47.427390442],
      [10.071818888000053, 47.439120993000088],
      [10.022622924000075, 47.487955221000149],
      [10.011254110000095, 47.484441224000093],
      [10.002365764000075, 47.479170227000068],
      [9.993580770000079, 47.476586406000152],
      [9.982625366000121, 47.481030579000063],
      [9.981075073000085, 47.48687001600014],
      [9.980765015000117, 47.489918925],
      [9.980765015000117, 47.492941997000017],
      [9.971669962000135, 47.50640370700016],
      [9.95916426600013, 47.514878643000159],
      [9.948725626000112, 47.524232077000093],
      [9.945935099000053, 47.540768534],
      [9.933842814000059, 47.534205628000038],
      [9.918753296000148, 47.532190247000173],
      [9.888987671000081, 47.533792216],
      [9.858911988000102, 47.54133697500005],
      [9.853847697000106, 47.540406800000099],
      [9.841548706000083, 47.53503245000006],
      [9.833487182000084, 47.534619039000049],
      [9.81312666900007, 47.541750387000079],
      [9.809302612000124, 47.552318217000121],
      [9.811266317000047, 47.564488017000045],
      [9.807959025000059, 47.576321920000069],
      [9.796280152000094, 47.584951885000109],
      [9.782120809000048, 47.588414205000149],
      [9.767134643000077, 47.587277324000056],
      [9.75266524200012, 47.582058004000103],
      [9.730857788000094, 47.564901429000074],
      [9.718455444000142, 47.546711324000015],
      [9.7039860430001, 47.53141510000016],
      [9.676700887000095, 47.522630107000069],
      [9.612622111000093, 47.521880799000158],
      [9.554143852000095, 47.532743111000073],
      [9.549886922000042, 47.533533834000039],
      [9.547481674000068, 47.534547102000133],
      [9.273211304000142, 47.650090027000104],
      [9.234350627000111, 47.656162008000152],
      [9.196936890000131, 47.656136170000039],
      [9.173275314563824, 47.655250651258562],
      [9.155220679406478, 47.666922484183125],
      [9.144925417388691, 47.668252149659089],
      [9.137085147841969, 47.664769390126509],
      [9.113021234783183, 47.671570929222284],
      [9.090071826262708, 47.678269036605229],
      [9.016586141000118, 47.678899639000051],
      [8.997672566000091, 47.673835348000139],
      [8.981756225000083, 47.662156474000184],
      [8.945376017000086, 47.654301656000044],
      [8.906205281000041, 47.651795349000068],
      [8.881710652000095, 47.656136170000039],
      [8.852668498000099, 47.670786438000121],
      [8.851935119000075, 47.671281723000064],
      [8.837785685000142, 47.680837504000138],
      [8.837682333000117, 47.687787985000128],
      [8.856079142000112, 47.690681864000126],
      [8.830240926000101, 47.707192485000022],
      [8.797581420000114, 47.720034079000115],
      [8.770709676000109, 47.720860901000137],
      [8.761717977000075, 47.701249695000016],
      [8.769882853000098, 47.695074361000039],
      [8.717069540000068, 47.694557597000184],
      [8.715105835000116, 47.701068827000071],
      [8.712625366000054, 47.708691101000127],
      [8.70466719500007, 47.715331523000131],
      [8.700429728000131, 47.723496399000155],
      [8.703737020000119, 47.730033468000116],
      [8.717069540000068, 47.743546855000019],
      [8.719756714000084, 47.74731923400013],
      [8.71314213000008, 47.757421977000078],
      [8.703323608000119, 47.758713887000013],
      [8.692264852000108, 47.757163595000108],
      [8.681929565000104, 47.758739726000115],
      [8.674488159000077, 47.766697897000185],
      [8.66663334200004, 47.778273417000136],
      [8.657021525000118, 47.788117778000114],
      [8.644102417000113, 47.791011658000102],
      [8.635007365000149, 47.784603780000154],
      [8.629839721000053, 47.762796326000128],
      [8.617437378000091, 47.757318624000121],
      [8.607618856000101, 47.762253723000086],
      [8.604104858000142, 47.774397685000068],
      [8.603174682000116, 47.787316793000102],
      [8.601624390000097, 47.794603170000173],
      [8.58312422700007, 47.800235901000107],
      [8.558216187000085, 47.801166077000133],
      [8.542299846000077, 47.795016581000183],
      [8.55160160300008, 47.779255270000093],
      [8.536512085000084, 47.774087626],
      [8.482665242000053, 47.766852926000027],
      [8.471503133000112, 47.767059632],
      [8.463648315000086, 47.763907370000041],
      [8.450109090000126, 47.75047149699999],
      [8.445458211000044, 47.743185120000092],
      [8.437913452000089, 47.723186341000158],
      [8.427268107000117, 47.716468404000139],
      [8.401946655000131, 47.707089132000092],
      [8.392128133000142, 47.699518535000138],
      [8.390991251000059, 47.69212880500011],
      [8.395228719000102, 47.68481659000004],
      [8.397709188000078, 47.676289979000117],
      [8.391301310000131, 47.665463765000183],
      [8.40701094500011, 47.661562195000116],
      [8.411971883000149, 47.661045430000073],
      [8.437603394000121, 47.647842102000098],
      [8.458273966000121, 47.639883932000131],
      [8.476050659000066, 47.640400696000157],
      [8.490830119000094, 47.645568339000178],
      [8.504679402000136, 47.65226043700001],
      [8.519665568000107, 47.65735056600009],
      [8.568241414000113, 47.662931621],
      [8.582194051000101, 47.661329651000145],
      [8.593588683000092, 47.65816856],
      [8.598213745000066, 47.656885478000149],
      [8.607308797000115, 47.65629119900008],
      [8.601624390000097, 47.632597555000146],
      [8.595113159000107, 47.63481964100005],
      [8.594493042000039, 47.640969137000027],
      [8.593562866000099, 47.642571106000062],
      [8.589222046000117, 47.642467754000123],
      [8.583951050000081, 47.641124166000068],
      [8.580333699000107, 47.63900543199999],
      [8.578059936000074, 47.633476054000184],
      [8.579713583000114, 47.628928529000135],
      [8.582090698000087, 47.625026957],
      [8.582504110000087, 47.621590475000161],
      [8.581263875000047, 47.614769186],
      [8.581780639000101, 47.607663677000076],
      [8.580643758000093, 47.600170594000033],
      [8.576305366000042, 47.595023058000109],
      [8.574132527000103, 47.592444967000134],
      [8.560696655000072, 47.589396057000116],
      [8.551719149000036, 47.596863329000044],
      [8.549637899000061, 47.598594462],
      [8.537752319000049, 47.612133688000185],
      [8.522352742000038, 47.621900534000062],
      [8.492380411000113, 47.619833476000096],
      [8.461787964000081, 47.606139221000078],
      [8.450109090000126, 47.589034323000121],
      [8.448868856000075, 47.58428009100011],
      [8.421100326000044, 47.581111870000157],
      [8.418069702000111, 47.580766094000168],
      [8.354094279000037, 47.58102447500012],
      [8.316163777000043, 47.587845764000079],
      [8.306345255000053, 47.592186585000078],
      [8.299317260000038, 47.601824239000095],
      [8.293942912000091, 47.611461894000016],
      [8.288568562000108, 47.615802715000186],
      [8.276993042000072, 47.616629537],
      [8.251051473000132, 47.622003886000172],
      [8.232964721000116, 47.621952210000146],
      [8.17901452600006, 47.615802715000186],
      [8.173846883000067, 47.613528951000077],
      [8.168885946000103, 47.608645528000139],
      [8.162064656000069, 47.603762105000115],
      [8.143771199000071, 47.600067241000104],
      [8.122067098000088, 47.592134908000062],
      [8.113902221000075, 47.587845764000079],
      [8.105427286000065, 47.581257019000091],
      [8.101396525000069, 47.576192729000027],
      [8.096952351000141, 47.571851909000046],
      [8.087237182000109, 47.567381897000033],
      [8.042278686000117, 47.560560608000074],
      [7.91215743000015, 47.560560608000074],
      [7.909470256000105, 47.564798076000116],
      [7.907506551000097, 47.57417734799999],
      [7.904302613000112, 47.583556621000028],
      [7.898204794000066, 47.587845764000079],
      [7.83371260600012, 47.590481263000115],
      [7.819656616000117, 47.595338847000122],
      [7.801466512000076, 47.576089376000098],
      [7.785550171000068, 47.563196106000092],
      [7.766739949000055, 47.555961406000122],
      [7.727320447000125, 47.550422624000149],
      [7.683437540000114, 47.54425669400014],
      [7.661423380000116, 47.546246236000101],
      [7.64690103500007, 47.551445236000021],
      [7.609746948000094, 47.564746399000015],
      [7.612337281000094, 47.564731041000087],
      [7.635895223000091, 47.564591370000173],
      [7.646540568000091, 47.571541850000145],
      [7.65966638200004, 47.596579082000162],
      [7.637032104000099, 47.594977112000137],
      [7.586028488000125, 47.584618544000151],
      [7.590109904000144, 47.594822083000096],
      [7.592487020000107, 47.598413595000167],
      [7.590316609000098, 47.607947896000056],
      [7.58504561400008, 47.61316721600015],
      [7.578637735000115, 47.616939596000108],
      [7.5726432700001, 47.622003886000172],
      [7.550112345000088, 47.652441305000039],
      [7.536573120000099, 47.665205384000032],
      [7.5217936600001, 47.670424704000041],
      [7.517866251000015, 47.67502390599999],
      [7.514662312000041, 47.685798442000092],
      [7.511871785000096, 47.707321676000063],
      [7.515385783000056, 47.713316142000096],
      [7.531922241000132, 47.725201722000023],
      [7.537813355000139, 47.73181630400002],
      [7.538330119000079, 47.748146057000056],
      [7.525927775000099, 47.78315684000016],
      [7.528614949000144, 47.797031963000123],
      [7.542877645000118, 47.829433086000122],
      [7.548458699000122, 47.834238993000142],
      [7.5619979250001, 47.839225769],
      [7.564168334000101, 47.850413717000137],
      [7.559000691000108, 47.869017233000093],
      [7.559207397000137, 47.8734614050001],
      [7.558380575000115, 47.875993551000178],
      [7.5578638100001, 47.878525697000086],
      [7.559000691000108, 47.882711488000112],
      [7.5619979250001, 47.88705230699999],
      [7.568612508000115, 47.891367290000161],
      [7.5726432700001, 47.896302389000098],
      [7.578947795000119, 47.906327617000116],
      [7.580808146000038, 47.91079762800014],
      [7.579567911000083, 47.927049866000161],
      [7.584942261000037, 47.94040822400008],
      [7.611607300000088, 47.959399313000134],
      [7.621115763000063, 47.971439922000016],
      [7.618945353000072, 48.00265248700002],
      [7.575743856000145, 48.053708802000173],
      [7.5726432700001, 48.094972433000081],
      [7.578947795000119, 48.114480286000074],
      [7.58566573000013, 48.129724833000026],
      [7.592900431000118, 48.138664856000062],
      [7.599721720000076, 48.144710999],
      [7.604786010000055, 48.15256581600012],
      [7.606749715000149, 48.16698354100005],
      [7.613364298000079, 48.179205018000076],
      [7.676616252000144, 48.238400371000139],
      [7.687158243000113, 48.252921448],
      [7.693462769000121, 48.269871318000114],
      [7.695529825000079, 48.290180156000034],
      [7.702454467000081, 48.311186625000047],
      [7.71857751500005, 48.319997457000042],
      [7.736974325000062, 48.326663717000045],
      [7.75072025500009, 48.341365662000115],
      [7.751960490000045, 48.355344137000131],
      [7.746896199000048, 48.368676657000051],
      [7.740384969000075, 48.381440736000016],
      [7.737181030000102, 48.39348134400008],
      [7.738317912000127, 48.406607158000057],
      [7.745655965000111, 48.43301381400012],
      [7.75072025500009, 48.44440846800002],
      [7.757644897000148, 48.453891093000024],
      [7.764156128000138, 48.460350648000073],
      [7.769220419000106, 48.47006581700002],
      [7.771287475000065, 48.489108582000156],
      [7.776455119000076, 48.498177796000121],
      [7.788444051000056, 48.506342672000144],
      [7.813455444000027, 48.519468486000122],
      [7.813455444000027, 48.526341451000079],
      [7.801673217000115, 48.547580465000138],
      [7.801776571000062, 48.582358704000015],
      [7.810251506000071, 48.615018209000183],
      [7.852419475000119, 48.658684794000138],
      [7.864098348000084, 48.664162497000135],
      [7.880634806000074, 48.667728170000103],
      [7.895931030000043, 48.676513163],
      [7.92331953900009, 48.698217265],
      [7.935205119000102, 48.70333323199999],
      [7.948434285000104, 48.711859844000102],
      [7.959182983000119, 48.72136830699999],
      [7.963627157000133, 48.72927480100013],
      [7.96486739100007, 48.748343405000114],
      [7.970138387000105, 48.757283427000132],
      [7.981507202000103, 48.759763896000109],
      [8.009619181000119, 48.760073954000106],
      [8.017267293000117, 48.761830954000075],
      [8.022951701000068, 48.765086569000161],
      [8.025122111000144, 48.770615947000138],
      [8.027292521000049, 48.780021058000116],
      [8.031943400000102, 48.78534373000015],
      [8.03659427900007, 48.788289287000069],
      [8.038764689000061, 48.790769756000046],
      [8.047549682000124, 48.791028137000112],
      [8.084239950000097, 48.804050598000131],
      [8.090337769000143, 48.807512920000178],
      [8.102740112000049, 48.821310526000119],
      [8.14811202000007, 48.903734436000136],
      [8.179324585000131, 48.942698467000028],
      [8.199891545000099, 48.958250331],
      [8.200305216000061, 48.95856313100002],
      [8.188729695000092, 48.96574615499999],
      [8.090441121000083, 48.979182028000039],
      [7.931897827000114, 49.034837546000134],
      [7.913811075000098, 49.036181133000028],
      [7.856863647000125, 49.032253723000153],
      [7.773664591000113, 49.048118388000049],
      [7.762605835000102, 49.046154683000125],
      [7.739144735000139, 49.037731425000132],
      [7.726949096000112, 49.035664368000155],
      [7.717337281000113, 49.036594544000039],
      [7.696149943000052, 49.041555481000088],
      [7.685091187000069, 49.042795716000015],
      [7.672482137000117, 49.04103871700012],
      [7.660803264000066, 49.037731425000132],
      [7.648814331000096, 49.035716045000086],
      [7.644938840000066, 49.036294476000151],
      [7.634965047000037, 49.03778310100013],
      [7.625663289000102, 49.043002421000082],
      [7.61594812000007, 49.05597320599999],
      [7.608610066000068, 49.061967672000108],
      [7.586389201000145, 49.06956410700009],
      [7.542670939000061, 49.074576722000145],
      [7.52086348500012, 49.083671774000109],
      [7.507737671000058, 49.094575501000079],
      [7.485103394000106, 49.118708395000127],
      [7.471047404000103, 49.128320212000133],
      [7.472804403000083, 49.131627503000132],
      [7.475284871000042, 49.133901266000166],
      [7.482726278000087, 49.137053528000123],
      [7.476111694000082, 49.151522929000137],
      [7.458024942000066, 49.155863750000137],
      [7.414823446000128, 49.157775778000044],
      [7.410482625000043, 49.168937887000183],
      [7.39105228700015, 49.170023092000079],
      [7.371633809000088, 49.166173614000073],
      [7.367591186000084, 49.165372213000026],
      [7.350951376000069, 49.15917104200004],
      [7.346300496000112, 49.154210104000086],
      [7.339065796000057, 49.139689026000141],
      [7.334001506000078, 49.134159648000136],
      [7.326456746000105, 49.131834208],
      [7.305992879000144, 49.13012888700014],
      [7.297104533000038, 49.128113505],
      [7.290800008000133, 49.123100892000039],
      [7.280258016000062, 49.10899322600001],
      [7.274366902000054, 49.105014140000108],
      [7.268062378000138, 49.105634257000091],
      [7.241190633000144, 49.113902486000043],
      [7.154994344000045, 49.114419251000172],
      [7.139284708000048, 49.117933248000114],
      [7.123575073000097, 49.123307597000078],
      [7.085334513000106, 49.14154937800005],
      [7.079856812000116, 49.142014465000059],
      [7.07076175900005, 49.136123353000173],
      [7.071588582000061, 49.130593974000149],
      [7.07479252100012, 49.125374655000044],
      [7.073242228000112, 49.12031036399999],
      [7.042546427000076, 49.107597962000014],
      [7.020532267000078, 49.118966777000097],
      [7.008956746000138, 49.14640696300016],
      [7.009473510000078, 49.181701965000158],
      [6.987872762000109, 49.182425436000145],
      [6.925447632000072, 49.205576477000037],
      [6.914388876000146, 49.206661683000121],
      [6.885243367000044, 49.204801331000127],
      [6.843488810000082, 49.211054180000033],
      [6.833463583000054, 49.20945221],
      [6.827675822000089, 49.195551250000037],
      [6.835220581000044, 49.179376526000041],
      [6.839044637000086, 49.16278839200011],
      [6.821784709000099, 49.147750550000026],
      [6.809072306000132, 49.145838522000119],
      [6.770108276000144, 49.155295309000067],
      [6.725563192000038, 49.155553691000065],
      [6.713780965000126, 49.158860982000064],
      [6.700965210000078, 49.172865296000154],
      [6.703755737000108, 49.18511261],
      [6.708820027000087, 49.197773336000026],
      [6.702515503000086, 49.21363800100012],
      [6.698484741000101, 49.213586324000019],
      [6.685048868000138, 49.207798564000043],
      [6.680604696000046, 49.207488505000143],
      [6.677917521000097, 49.211054180000033],
      [6.675953817000078, 49.218908997000156],
      [6.674816935000138, 49.221131084000078],
      [6.671612996000079, 49.225316875000104],
      [6.66375817900007, 49.243868713000055],
      [6.659520711000113, 49.246762594000032],
      [6.645671427000139, 49.250018209000118],
      [6.640297078000089, 49.25275706000015],
      [6.639160196000148, 49.256787822000049],
      [6.641227254000114, 49.26691640300011],
      [6.639676961000106, 49.271153870000049],
      [6.573169393000057, 49.313115133000068],
      [6.556426228000078, 49.326395976000171],
      [6.550121704000077, 49.338023173000025],
      [6.555599406000056, 49.347169902000118],
      [6.573376098000097, 49.347738343000074],
      [6.577716919000068, 49.355799866000055],
      [6.574306274000065, 49.361794332],
      [6.525730428000145, 49.395487366000125],
      [6.518805786000058, 49.402153626000043],
      [6.520769491000095, 49.402567038000157],
      [6.520562785000038, 49.40814809199999],
      [6.517875610000118, 49.416416321000113],
      [6.511674438000114, 49.424736226000093],
      [6.494517863000084, 49.435536601000123],
      [6.402740519000133, 49.465457255000061],
      [6.391681763000122, 49.466697490000101],
      [6.383516886000109, 49.464630432000135],
      [6.364499959000057, 49.456103821000013],
      [6.353854614000056, 49.454760234000148],
      [6.345307071000093, 49.455348652000069],
      [6.345379679000132, 49.462718404000029],
      [6.342279094000105, 49.493000794000054],
      [6.348808550000086, 49.525289755000088],
      [6.350754028000097, 49.534910381000131],
      [6.350754028000097, 49.566588034000048],
      [6.356438435000143, 49.577595113000129],
      [6.371527954000044, 49.592994690000111],
      [6.397572876000112, 49.613871969000073],
      [6.407701457000087, 49.627411194000061],
      [6.415246216000128, 49.63464589400003],
      [6.417933390000144, 49.63914174500006],
      [6.417003215000108, 49.645756327000058],
      [6.410905396000146, 49.64963206],
      [6.404497518000113, 49.652474264000077],
      [6.402740519000133, 49.655781555000075],
      [6.418243449000045, 49.669889222],
      [6.49172733600011, 49.700791728000084],
      [6.489763631000074, 49.70296213799999],
      [6.482942342000115, 49.708078105000155],
      [6.486352986000043, 49.71293569],
      [6.49100386500001, 49.714434306],
      [6.499685506000077, 49.712212218999987],
      [6.492554158000132, 49.718413391000084],
      [6.48779992600015, 49.725596416000045],
      [6.485526163000117, 49.733761292000153],
      [6.486249634000018, 49.74270131499999],
      [6.495964803000078, 49.748230693],
      [6.496378214000089, 49.752106425000036],
      [6.493691040000044, 49.756550598000146],
      [6.493794392000069, 49.763630269000075],
      [6.49854862400008, 49.785386048000177],
      [6.499995564000074, 49.786057841000158],
      [6.500098918000077, 49.794481100000056],
      [6.502269327000079, 49.795876363000119],
      [6.502579387000139, 49.795669658000079],
      [6.4972050370001, 49.799442038],
      [6.493277629000119, 49.799597066000146],
      [6.462478475000069, 49.805488180000125],
      [6.414522746000046, 49.805488180000125],
      [6.397056111000097, 49.808588766000085],
      [6.380106242000096, 49.815565084000085],
      [6.351374145000079, 49.833290100000099],
      [6.33442427500006, 49.840214742000072],
      [6.325639282000083, 49.840369772000102],
      [6.310653116000111, 49.834478659000027],
      [6.30259159400012, 49.834943747000139],
      [6.301558065000052, 49.838302714000136],
      [6.295567892000122, 49.848615692000081],
      [6.291532837000119, 49.855562643000056],
      [6.286778605000109, 49.86104034500012],
      [6.27220585200007, 49.867138164],
      [6.238306111000071, 49.876905009000055],
      [6.222699829000106, 49.887136943000129],
      [6.18983361800008, 49.938296611000126],
      [6.172677043000107, 49.956228333000084],
      [6.161928345000092, 49.942482402000067],
      [6.153970174000051, 49.951215719000132],
      [6.138777303000097, 49.979327698000176],
      [6.12151737500011, 49.996277568],
      [6.117176554000139, 50.004390768000079],
      [6.119967081000112, 50.015501200000088],
      [6.113765910000012, 50.036378480000067],
      [6.10725467900005, 50.044595032000117],
      [6.096402629000096, 50.048625794000102],
      [6.105704386000127, 50.060304668000171],
      [6.10942509000003, 50.063560283000143],
      [6.107151327000111, 50.063766988000012],
      [6.101156861000078, 50.063405254000102],
      [6.099399861000109, 50.064128723000024],
      [6.105704386000127, 50.092240702000069],
      [6.110561971000038, 50.10598663399999],
      [6.11748661300004, 50.120456035000032],
      [6.126581665000089, 50.127329000000188],
      [6.129475545000076, 50.134046937],
      [6.125961547000117, 50.140196432],
      [6.115832967000074, 50.145467428000117],
      [6.11800337700015, 50.150376689000055],
      [6.121310668000149, 50.161590475000096],
      [6.123171020000086, 50.164897767000085],
      [6.13174930800011, 50.168308411000041],
      [6.151696411000017, 50.169341939],
      [6.159137817000044, 50.172442526000125],
      [6.165132284000066, 50.181899312000112],
      [6.165752400000145, 50.19316477500017],
      [6.160171346000112, 50.204533590000054],
      [6.146838827000096, 50.214042054000132],
      [6.15717411300011, 50.222723694000095],
      [6.179808390000062, 50.233007304000111],
      [6.189626912000023, 50.242722473000057],
      [6.210400838000084, 50.249026998000048],
      [6.253395630000057, 50.25677846300006],
      [6.271792439000137, 50.267423808000061],
      [6.267348267000045, 50.276415507000095],
      [6.269415324000107, 50.286492412000044],
      [6.276029907000094, 50.296104228000146],
      [6.285951782000097, 50.303855692000141],
      [6.299491007000086, 50.308868307000026],
      [6.310653116000111, 50.308248190000043],
      [6.322021932000098, 50.305405986000082],
      [6.335457804000043, 50.303855692000141],
      [6.36201949000008, 50.30695627900009],
      [6.374525187000074, 50.31548289100003],
      [6.373399684000106, 50.323080035000046],
      [6.372458130000098, 50.329435527],
      [6.337731567000077, 50.368089498000117],
      [6.336387980000097, 50.379923402000131],
      [6.343312622000099, 50.39330759700006],
      [6.350443969000111, 50.416872051000055],
      [6.35116744000004, 50.433563538],
      [6.347343384000084, 50.436974183000032],
      [6.340212036000139, 50.437852682000155],
      [6.331633748000115, 50.446896057000018],
      [6.326156046000108, 50.457024638000163],
      [6.32326216600012, 50.466016337000113],
      [6.325742635000097, 50.474026184000181],
      [6.336904744000037, 50.48100250200018],
      [6.31871464000011, 50.481674296000065],
      [6.27540979000014, 50.488030497000082],
      [6.260113566000086, 50.492681376000164],
      [6.257839803000053, 50.494076640000131],
      [6.255566040000133, 50.494593404000071],
      [6.253085571000071, 50.494076640000131],
      [6.25091516100008, 50.492681376000164],
      [6.217118774000085, 50.486015117000065],
      [6.207196900000099, 50.486325175000118],
      [6.199238729000058, 50.49040761300013],
      [6.190660441000119, 50.501828105000143],
      [6.182598917000121, 50.506582337000125],
      [6.190557088000077, 50.515212301000176],
      [6.178878214000093, 50.515780742000132],
      [6.170816691000113, 50.517951152000123],
      [6.169473103000144, 50.522498678000076],
      [6.177327922000075, 50.530198466000158],
      [6.160688110000081, 50.543582663000095],
      [6.17908492000015, 50.56197947200009],
      [6.210607543000037, 50.578670959000121],
      [6.232931763000096, 50.587145894000045],
      [6.232931763000096, 50.590763245000019],
      [6.244300577000075, 50.5995482390001],
      [6.251328572000091, 50.608436585],
      [6.249054809000086, 50.614431051000125],
      [6.180015096000119, 50.615516256000134],
      [6.159447876000115, 50.622389222000024],
      [6.147769002000047, 50.636755270000108],
      [6.161101521000092, 50.642181296000089],
      [6.100226685000052, 50.701299134000138],
      [6.081003052000114, 50.713494772000061],
      [6.064363240000091, 50.714476624000113],
      [6.02477909300012, 50.70755198200014],
      [6.011549926000043, 50.708533835000097],
      [6.00762251800009, 50.716905417000177],
      [6.011343221000089, 50.727447408000145],
      [6.010826457000064, 50.737059225000152],
      [5.994186645000127, 50.742588603000158],
      [5.992739705000133, 50.744448955000067],
      [5.992636353000108, 50.746257630000159],
      [5.993256469000073, 50.748066305000137],
      [5.994910116000114, 50.749926656000142],
      [5.997080525000115, 50.762949117000105],
      [5.990569295000057, 50.769046936000152],
      [5.980647420000139, 50.773439433000036],
      [5.972999308000084, 50.781811015000116],
      [5.968761841000116, 50.794626770000022],
      [5.983954712000127, 50.792197978000146],
      [5.998320760000041, 50.800724589000154],
      [6.003591756000077, 50.812610169],
      [6.001421346000086, 50.821808574000087],
      [6.000491170000146, 50.830438538000024],
      [6.009276164000113, 50.840463765000052],
      [6.023022095000073, 50.845424704000024],
      [6.045553019000096, 50.844184469000155],
      [6.056715129000111, 50.852659404000164],
      [6.062812948000072, 50.862943014000066],
      [6.063123006000069, 50.870901184000118],
      [6.054648071000145, 50.891468404000122],
      [6.056508423000139, 50.898082988000013],
      [6.061779419000089, 50.903509013000175],
      [6.063743124000041, 50.907539774999989],
      [6.031083618000139, 50.914722799000131],
      [6.007519165000133, 50.924076233000065],
      [5.999871053000078, 50.929398906000117],
      [5.997287232000076, 50.937357076000083],
      [5.995840291000064, 50.951774801000099],
      [5.999664348000124, 50.960094706000163],
      [6.005762167000086, 50.968517965000146],
      [6.00369510900012, 50.97373728400008],
      [5.967831665000063, 50.970998434000123],
      [5.941580037000051, 50.976476135000112],
      [5.927730754000066, 50.977664694000126],
      [5.914191529000078, 50.975235901000175],
      [5.888249959000035, 50.967122701000065],
      [5.874607381000118, 50.965365702999989],
      [5.87357385200005, 50.990583801000142],
      [5.858174275000067, 51.019341736000158],
      [5.852489868000106, 51.042751160000122],
      [5.88049849500004, 51.052052918000143],
      [5.894347778000082, 51.047143657],
      [5.906233358000122, 51.037402649000157],
      [5.919565877000139, 51.029702860000086],
      [5.937962687000066, 51.030943095000012],
      [5.950261678000089, 51.039469707000123],
      [5.969485311000113, 51.064920350000037],
      [5.982921183000144, 51.074687195000095],
      [6.075421997000092, 51.120369161000028],
      [6.132369426000082, 51.139825337999987],
      [6.147252238000107, 51.152253520000059],
      [6.12627160700012, 51.164578349000024],
      [6.149112589000112, 51.176334737000033],
      [6.157070760000096, 51.179254456000095],
      [6.134126424000044, 51.184215394000049],
      [6.084310344000102, 51.162692159000116],
      [6.060849243000121, 51.170908712000042],
      [6.053717895000091, 51.189383037000155],
      [6.056611776000096, 51.211707255000121],
      [6.065190063000102, 51.231628520000029],
      [6.075421997000092, 51.242661439000031],
      [6.096505981000121, 51.258810324000123],
      [6.147769002000047, 51.318160706000143],
      [6.158207641000104, 51.324594422000089],
      [6.183632446000104, 51.335704854000099],
      [6.193554321000107, 51.344515686000094],
      [6.198928670000072, 51.356969706000186],
      [6.202029256000088, 51.378880513000141],
      [6.207817016000149, 51.387665508000126],
      [6.192727499000085, 51.398956808000023],
      [6.194071086000122, 51.417379456],
      [6.201719197000102, 51.438644308000093],
      [6.205336548000105, 51.458436382000158],
      [6.20047896300008, 51.476574809000098],
      [6.19334761500005, 51.509337667000082],
      [6.153039998000082, 51.538431499000083],
      [6.086480754000093, 51.595533956000011],
      [6.082139933000121, 51.607807109000063],
      [6.087514282000086, 51.622095642000133],
      [6.099193156000069, 51.644471538000019],
      [6.031497030000054, 51.662997539000131],
      [6.008552694000116, 51.681187643000086],
      [6.02198856600009, 51.709506327999989],
      [6.012066691000086, 51.715862528000017],
      [5.989742472000103, 51.726378682000146],
      [5.978270304000091, 51.729789327000091],
      [5.949228150000096, 51.72937591600008],
      [5.939306274000103, 51.731882223000142],
      [5.940133097000057, 51.742630921000071],
      [5.946056400000146, 51.746288216],
      [5.962353963000084, 51.756351013000099],
      [5.964110962000063, 51.776556498999987],
      [5.950778442000114, 51.795909322000156],
      [5.928040812000063, 51.806735535000186],
      [5.931451457000094, 51.815649720000025],
      [5.948091268000098, 51.822961935000095],
      [5.988915650000081, 51.827457784000117],
      [6.008242635000045, 51.832573751000112],
      [6.028913208000063, 51.842108053000018],
      [6.043279256000062, 51.846862285],
      [6.084000284000126, 51.853657735000141],
      [6.116453084000057, 51.848283387],
      [6.140017537000063, 51.844536845000064],
      [6.156140584000127, 51.842056376000087],
      [6.142704712000068, 51.855182190000065],
      [6.105394327000056, 51.879470113000153],
      [6.09299198400015, 51.885309550000144],
      [6.1269950760001, 51.89673004100014],
      [6.159241170000087, 51.887299093000181],
      [6.191383911000116, 51.871150208000088],
      [6.225180298000083, 51.862546082000065],
      [6.25639286200007, 51.866809387000117],
      [6.264661092000011, 51.866189271000152],
      [6.271792439000137, 51.861900127000084],
      [6.282851196000138, 51.849601135000128],
      [6.287398722000091, 51.846164653000116],
      [6.325742635000097, 51.842547303000046],
      [6.341969034000016, 51.836682028000141],
      [6.344966268000121, 51.8211015830001],
      [6.382483358000116, 51.827586975000159],
      [6.386617472000069, 51.837560527000065],
      [6.378452596000045, 51.860117289000087],
      [6.442531372000133, 51.848360901000106],
      [6.462891887000069, 51.848360901000106],
      [6.47942834400007, 51.853011780000159],
      [6.528624308000133, 51.876421204000124],
      [6.628153117000068, 51.897505189000142],
      [6.716674846000046, 51.899262187000133],
      [6.743753296000079, 51.908331401000012],
      [6.767007690000099, 51.925513815000059],
      [6.789848673000108, 51.951145325000041],
      [6.798013550000121, 51.956700542000036],
      [6.805661661000102, 51.957527365000075],
      [6.810725952000098, 51.960576274000104],
      [6.811656127000049, 51.972797751000101],
      [6.809175659000061, 51.97954152500013],
      [6.804008016000068, 51.984373271000052],
      [6.778169800000057, 52.001271465000158],
      [6.7128507890001, 52.02385406500018],
      [6.690939982000145, 52.027858988000091],
      [6.675023641000109, 52.034809469000081],
      [6.672956583000143, 52.050054016],
      [6.679881225000116, 52.060466818000137],
      [6.688872925000084, 52.063102316000069],
      [6.699414917000127, 52.063567404000182],
      [6.711403849000106, 52.067288107000181],
      [6.719465373000077, 52.073566793000097],
      [6.7276302490001, 52.086305033000073],
      [6.732901245000051, 52.091989441000109],
      [6.749851115000126, 52.102867330000052],
      [6.765870808000102, 52.108396709000075],
      [6.77157568400014, 52.108792502000128],
      [6.830673055000091, 52.11289255800007],
      [6.843282105000128, 52.119998067000111],
      [6.865399617000037, 52.147799988000159],
      [6.884829956000118, 52.160331523000153],
      [6.927721395000077, 52.171907044000122],
      [6.94766849700008, 52.182810771000092],
      [6.971129598000061, 52.206271871000141],
      [6.981878296000076, 52.213997498000069],
      [7.026320027000054, 52.230637309000159],
      [7.029110555000017, 52.235779114000152],
      [7.026600152000071, 52.238622687000074],
      [7.02073897300005, 52.245261739000128],
      [7.011850626000125, 52.266629944000059],
      [7.012470744000098, 52.284949239000028],
      [7.019705444000067, 52.300038758000127],
      [7.039755900000102, 52.328615825],
      [7.048230835000112, 52.365073547000108],
      [7.034174845000109, 52.39078257300018],
      [6.973300008000138, 52.451373190000041],
      [6.966995483000062, 52.449564515000148],
      [6.960794311000143, 52.442846578000129],
      [6.951492553000037, 52.437472229000079],
      [6.92648116100014, 52.432976380000142],
      [6.9002295330001, 52.431917013000074],
      [6.872014200000137, 52.434733378000132],
      [6.820337769000105, 52.446825663000098],
      [6.741169474000088, 52.453672791000159],
      [6.714814494000109, 52.461553447000099],
      [6.695074097000145, 52.475609437000131],
      [6.688976278000098, 52.490518087000098],
      [6.687529337000115, 52.508139751000087],
      [6.684015340000144, 52.525993958000029],
      [6.671716349000121, 52.54167775500018],
      [6.688252807000112, 52.542582093000036],
      [6.743753296000079, 52.55971283000018],
      [6.710990438000096, 52.576636862000086],
      [6.70396244300008, 52.583070577000129],
      [6.703652384000094, 52.591390483000097],
      [6.708199911000122, 52.599684550000134],
      [6.710163615000056, 52.608650411999989],
      [6.7017920320001, 52.619114889000045],
      [6.719083149000085, 52.626758982000027],
      [6.737035360000078, 52.634695333000153],
      [6.77052168700007, 52.641025696000085],
      [6.865399617000037, 52.641955872000139],
      [6.918419637000056, 52.632189026000091],
      [6.967718953000144, 52.636659038000104],
      [6.982808471000055, 52.632783305000046],
      [7.018465210000045, 52.625987854000087],
      [7.036655314000086, 52.647381897000017],
      [7.04399336700007, 52.682625224000091],
      [7.053295125000091, 52.790577291000105],
      [7.061976766000043, 52.824011942000098],
      [7.072150759000124, 52.841316552000066],
      [7.079856812000116, 52.854423523000051],
      [7.161918986000103, 52.9326358040001],
      [7.183623088000104, 52.96612213100012],
      [7.19282149300011, 52.998006490000151],
      [7.194371786000119, 53.03384409600001],
      [7.185070027000108, 53.105441793000168],
      [7.181349324000081, 53.114175111000137],
      [7.176491740000074, 53.119394430000156],
      [7.172667684000118, 53.125750631000173],
      [7.171944214000121, 53.13771372500004],
      [7.174424682000108, 53.145775249000124],
      [7.188584025000068, 53.167789408000104],
      [7.195405314000112, 53.184997661000082],
      [7.198505900000043, 53.200578105000105],
      [7.19747237100006, 53.216623637000069],
      [7.194590691000116, 53.24502187700007],
      [7.204437696000127, 53.248277085000112],
      [7.215830925000091, 53.255601304000137],
      [7.224619988000057, 53.262925523000135],
      [7.233571811000104, 53.273627020000063],
      [7.236989780000101, 53.285549221000011],
      [7.22950280000012, 53.296576239000146],
      [7.254730665000096, 53.319525458000143],
      [7.268321160000141, 53.323675848000065],
      [7.291514519000089, 53.323919989000117],
      [7.313161655000073, 53.32050202],
      [7.347666863000086, 53.307359117000047],
      [7.366547071000127, 53.302801825000117],
      [7.342539910000085, 53.324042059000092],
      [7.302907748000052, 53.33274974199999],
      [7.075205925000148, 53.337591864000061],
      [7.038259311000104, 53.348578192000033],
      [7.023610873000081, 53.376410223000065],
      [7.023285352000073, 53.450506903000033],
      [7.027679884000037, 53.467352606000034],
      [7.047048373000052, 53.497707424000012],
      [7.051442905000044, 53.512600002000156],
      [7.059743686000019, 53.52171458500014],
      [7.079356316000087, 53.521551825000174],
      [7.119639519000145, 53.516302802],
      [7.125010613000114, 53.524359442000119],
      [7.134532097000118, 53.527411200000031],
      [7.141774936000047, 53.537176825000145],
      [7.13998457100007, 53.549790757000054],
      [7.13111412900011, 53.556952216000084],
      [7.101898634000065, 53.565741278000147],
      [7.088877800000091, 53.573716539000102],
      [7.086761915000068, 53.586859442000062],
      [7.100922071000099, 53.597886460000112],
      [7.133148634000122, 53.611273505000113],
      [7.16944420700014, 53.635484117000104],
      [7.188975457000112, 53.643500067000062],
      [7.226084832000112, 53.666489976000136],
      [7.305023634000065, 53.684800523000106],
      [7.458832227000073, 53.695135809000121],
      [7.469574415000068, 53.690741278000033],
      [7.480235222000118, 53.682033596000124],
      [7.503916863000114, 53.678656317000119],
      [7.651377800000091, 53.696966864000089],
      [7.951426629000082, 53.721909898000106],
      [8.031423373000052, 53.708075262000122],
      [8.031423373000052, 53.700628973000121],
      [8.02491295700014, 53.672552802000141],
      [8.052907748000081, 53.636297919000029],
      [8.094086134000065, 53.604722398000135],
      [8.127614780000101, 53.590765692000062],
      [8.127614780000101, 53.584621486000074],
      [8.119151238000143, 53.571926174000126],
      [8.134613477000073, 53.56745026200015],
      [8.156748894000145, 53.563666083000143],
      [8.167979363000143, 53.553208726000051],
      [8.164886915000068, 53.535223700000117],
      [8.155446811000047, 53.526027736000131],
      [8.133799675000148, 53.516302802],
      [8.115733269000145, 53.510728257],
      [8.076345248000052, 53.508612372000172],
      [8.058767123000109, 53.502020575000088],
      [8.076996290000096, 53.468695380000113],
      [8.086192254000082, 53.456122137000122],
      [8.099782748000052, 53.447414455000128],
      [8.112478061000104, 53.451076565000093],
      [8.123871290000068, 53.454006252000013],
      [8.140635613000086, 53.453599351000108],
      [8.152354363000057, 53.449774481000091],
      [8.17139733200014, 53.436590887000037],
      [8.178558790000096, 53.433742580000015],
      [8.186696811000104, 53.429510809000178],
      [8.19483483200014, 53.420111395000063],
      [8.20533287900011, 53.410711981000034],
      [8.219737175000063, 53.40643952000012],
      [8.236501498000081, 53.406724351000051],
      [8.248708530000073, 53.408392645000063],
      [8.259450717000078, 53.412298895000063],
      [8.271739129000139, 53.419501044000086],
      [8.292816602000073, 53.440497137000037],
      [8.315114780000101, 53.47492096600017],
      [8.320485873000052, 53.509222723000121],
      [8.291514519000145, 53.529364325000174],
      [8.252207879000139, 53.523098049000126],
      [8.231211785000113, 53.525213934000035],
      [8.233164910000056, 53.539862372000144],
      [8.241709832000083, 53.556301174000126],
      [8.248871290000125, 53.590277411000088],
      [8.257334832000083, 53.604437567],
      [8.270681186000076, 53.612860419000143],
      [8.287608269000145, 53.616888739000117],
      [8.329356316000116, 53.618109442000119],
      [8.348480665000068, 53.612860419000143],
      [8.363291863000143, 53.600572007],
      [8.375498894000117, 53.586900132000054],
      [8.387705925000091, 53.57713450700011],
      [8.428070509000065, 53.564764716000084],
      [8.51343834700009, 53.554754950000088],
      [8.55209394600007, 53.543605861000074],
      [8.55209394600007, 53.536200262000094],
      [8.528168165000096, 53.523911851000136],
      [8.506195509000094, 53.507961330000128],
      [8.490000847000118, 53.486395575000117],
      [8.48389733200014, 53.457342841000141],
      [8.485036655000073, 53.40119049700003],
      [8.490896030000016, 53.376450914000074],
      [8.504405144000089, 53.358058986000017],
      [8.507334832000112, 53.38279857],
      [8.496755405000073, 53.445257880000142],
      [8.497569207000083, 53.474758205000015],
      [8.512868686000047, 53.496486721000096],
      [8.535817905000044, 53.506170966000141],
      [8.556651238000114, 53.51821523600016],
      [8.565765821000099, 53.546698309000149],
      [8.560801629000139, 53.551703192],
      [8.535166863000086, 53.590765692000062],
      [8.527517123000052, 53.596625067],
      [8.51710045700014, 53.610174872000172],
      [8.508311394000089, 53.625148830000015],
      [8.501231316000116, 53.644191799000012],
      [8.487071160000113, 53.666937567000119],
      [8.48389733200014, 53.68357982],
      [8.486094597000118, 53.70042552299999],
      [8.492198113000114, 53.71515534100017],
      [8.529633009000065, 53.770697333000115],
      [8.556162957000112, 53.83095937700007],
      [8.573252800000148, 53.857652085],
      [8.58757571700005, 53.870428778000061],
      [8.607106967000021, 53.881537177000084],
      [8.629242384000094, 53.889390367000047],
      [8.651866082000083, 53.892482815000122],
      [8.672699415000039, 53.891587632000054],
      [8.684580925000091, 53.888495184000149],
      [8.709157748000109, 53.871975002000099],
      [8.744639519000089, 53.854152736000017],
      [8.782481316000144, 53.842474677000112],
      [8.860687696000127, 53.831000067000062],
      [8.903575066000116, 53.831732489],
      [8.976247592000107, 53.842433986000017],
      [9.016286655000044, 53.840521552000055],
      [9.035004102000102, 53.85358307500006],
      [9.114512566000087, 53.865139065000065],
      [9.193614129000082, 53.864691473000065],
      [9.210703972000147, 53.871975002000099],
      [9.224294467000107, 53.865912177],
      [9.240733269000089, 53.864162502000099],
      [9.274668816000144, 53.865139065000065],
      [9.283376498000109, 53.86163971600017],
      [9.305349155000044, 53.84454987200003],
      [9.402679884000122, 53.742417710000083],
      [9.442881707000112, 53.714300848000065],
      [9.47095787900011, 53.700628973000121],
      [9.484711134000122, 53.690090236000074],
      [9.498383009000065, 53.659857489000089],
      [9.567067905000073, 53.599595445000105],
      [9.582367384000122, 53.591294664000046],
      [9.600922071000127, 53.586330471000096],
      [9.624766472000118, 53.584621486000074],
      [9.637217644000117, 53.581000067000119],
      [9.696625196000099, 53.556626695000077],
      [9.764008009000094, 53.54767487200003],
      [9.819021030000073, 53.540350653000033],
      [9.832041863000143, 53.543605861000074],
      [9.819590691000116, 53.556626695000077],
      [9.782074415000096, 53.56972890800013],
      [9.692393425000148, 53.575913804000024],
      [9.65577233200014, 53.584621486000074],
      [9.583669467000021, 53.612453518000152],
      [9.550791863000143, 53.634588934000121],
      [9.539073113000143, 53.666489976000136],
      [9.53614342500012, 53.693752346000124],
      [9.53223717500012, 53.71116771],
      [9.522227410000141, 53.716620184000035],
      [9.475271030000101, 53.732163804000137],
      [9.460215691000087, 53.735419012000179],
      [9.435801629000139, 53.748683986000017],
      [9.412445509000065, 53.778753973000065],
      [9.396657748000109, 53.811021225999987],
      [9.395192905000044, 53.831000067000062],
      [9.35873457100007, 53.839544989],
      [9.295583530000101, 53.871893622000115],
      [9.257823113000143, 53.885565497000059],
      [9.22046959700009, 53.890773830000128],
      [9.093435092000078, 53.892482815000122],
      [9.055430535000085, 53.902899481000034],
      [9.029958530000073, 53.907131252000156],
      [9.018321160000141, 53.902736721000068],
      [9.007823113000086, 53.892482815000122],
      [8.984629754000139, 53.897040106000176],
      [8.949473504000082, 53.912909247000115],
      [8.916026238000143, 53.93740469000015],
      [8.83334394600007, 54.036444403000033],
      [8.871836785000141, 54.046942450000088],
      [8.890635613000114, 54.047796942],
      [8.914561394000089, 54.043890692],
      [8.953868035000085, 54.02830638200011],
      [8.975352410000113, 54.024074611000074],
      [8.997243686000047, 54.030218817000062],
      [9.004893425000148, 54.041164455000128],
      [9.013031446000099, 54.060980536000145],
      [9.018565300000091, 54.082424221000096],
      [9.018321160000141, 54.097886460000026],
      [9.006683790000068, 54.108140367000132],
      [8.98812910200013, 54.117865302000084],
      [8.974457227000102, 54.128078518000038],
      [8.977305535000141, 54.139471747000172],
      [8.977305535000141, 54.145697333000143],
      [8.95289147200009, 54.150091864000117],
      [8.917979363000086, 54.146307684000092],
      [8.885427280000101, 54.137030341000141],
      [8.867442254000082, 54.125189520000092],
      [8.860687696000127, 54.125189520000092],
      [8.83334394600007, 54.153143622000115],
      [8.815684441000144, 54.175604559000121],
      [8.812836134000122, 54.180487372000087],
      [8.820974155000044, 54.200506903000147],
      [8.836273634000094, 54.217759507000054],
      [8.846690300000091, 54.233547268],
      [8.839610222000118, 54.248724677],
      [8.839610222000118, 54.255560614000117],
      [8.851898634000065, 54.261053778000061],
      [8.894216342000078, 54.268215236000074],
      [8.911468946000099, 54.269191799000154],
      [8.922048373000052, 54.274237372000087],
      [8.937510613000143, 54.286688544000114],
      [8.953135613000143, 54.302557684000035],
      [8.963145379000139, 54.31761302300005],
      [8.940277540000125, 54.315008856000148],
      [8.902598504000139, 54.295843817],
      [8.88111412900011, 54.29026927299999],
      [8.883067254000053, 54.293605861000017],
      [8.885915561000104, 54.300726630000057],
      [8.887950066000116, 54.303941148000106],
      [8.857595248000052, 54.301825262000094],
      [8.805430535000113, 54.292425848000093],
      [8.774668816000144, 54.29026927299999],
      [8.738942905000101, 54.293890692000147],
      [8.723480665000096, 54.29026927299999],
      [8.690196160000085, 54.271470445000134],
      [8.678477410000113, 54.269191799000154],
      [8.651866082000083, 54.27484772300015],
      [8.623383009000065, 54.290106512000122],
      [8.602712436000047, 54.312079169000029],
      [8.59986412900011, 54.33808014500012],
      [8.614024285000113, 54.348374742000132],
      [8.641368035000085, 54.355658270000148],
      [8.672048373000109, 54.35927969],
      [8.695567254000139, 54.358587958000086],
      [8.695567254000139, 54.365423895000092],
      [8.677582227000102, 54.367905992000104],
      [8.66382897200009, 54.375555731000063],
      [8.650645379000139, 54.377997137000179],
      [8.634776238000114, 54.365423895000092],
      [8.626231316000087, 54.37641022300015],
      [8.62916100400011, 54.387681382000139],
      [8.63843834700009, 54.398138739000117],
      [8.648448113000143, 54.406398830000015],
      [8.66976972700013, 54.400824286],
      [8.750824415000068, 54.413804429],
      [8.860687696000127, 54.413804429],
      [8.88607832100007, 54.417792059000149],
      [8.907237175000148, 54.425523179],
      [8.949473504000082, 54.447333075000031],
      [8.988047722000147, 54.458441473000065],
      [9.004730665000096, 54.465887762000037],
      [9.011485222000147, 54.482123114000089],
      [9.01156660200013, 54.506333725999987],
      [8.986094597000118, 54.529282945000077],
      [8.904470248000109, 54.580633856000176],
      [8.89283287900011, 54.591253973000121],
      [8.887950066000116, 54.60187409100017],
      [8.878754102000073, 54.606512762000094],
      [8.858164910000113, 54.606146552000084],
      [8.837087436000104, 54.602687893000095],
      [8.825856967000107, 54.598130601000136],
      [8.819021030000073, 54.605617580000015],
      [8.853282097000147, 54.619289455000128],
      [8.818369988000143, 54.673651434000149],
      [8.805430535000113, 54.687567450000031],
      [8.77670332100007, 54.697984117000132],
      [8.722666863000086, 54.725531317000147],
      [8.688731316000144, 54.735296942],
      [8.688731316000144, 54.742173570000105],
      [8.69434655000012, 54.760239976000108],
      [8.68132571700005, 54.780585028000118],
      [8.661875847000147, 54.800604559000178],
      [8.648448113000143, 54.817857163999989],
      [8.642751498000081, 54.844183661],
      [8.647227410000141, 54.86737702000012],
      [8.660816132543687, 54.896303911092375],
      [8.695882202000092, 54.889984029000132],
      [8.732779175000104, 54.889053854000096],
      [8.80088871200013, 54.903833314000011],
      [8.824246459000079, 54.905900370000055],
      [8.904138224000093, 54.8979422],
    ],
  ],
};
