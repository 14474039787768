import React, { memo, useState, useEffect } from 'react';

import Polygon from '@arcgis/core/geometry/Polygon';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';

import {
  WebMapProperties,
  LayerWithIndex,
  ClickResult,
  CustomWidget,
} from 'src/components/ArcGisMap';
import Map from 'src/components/Map';
import { useConfig } from 'src/config/ConfigContext';
import { Area } from 'src/pages/Dashboard/context';

const WORLD_BBOX: GeoJSON.BBox = [
  -162.3896559252, -6.3808821149, 177.9228440748, 54.878284683,
];

const DashboardMap = memo(
  ({
    webMapOptions,
    customWidgets,
    layers,
    mapKey,
    onSiteSelection,
    onWebMap,
    activeAreaOfOperation,
    setActiveAreaOfOperation,
    areasOfOperation,
  }: {
    webMapOptions: WebMapProperties;
    customWidgets: CustomWidget[];
    layers: LayerWithIndex[];
    mapKey: string | null;
    onSiteSelection: (siteSelection: ClickResult) => void;
    onWebMap: React.Dispatch<React.SetStateAction<__esri.WebMap | undefined>>;
    activeAreaOfOperation: Area | null;
    setActiveAreaOfOperation: React.Dispatch<React.SetStateAction<Area | null>>;
    areasOfOperation?: Area[];
  }) => {
    const [areaOfOperationHighlight, setAreaOfOperationHighlight] =
      useState<LayerWithIndex>();
    const config = useConfig().config!;
    const [mapGoTo, setMapGoTo] = useState<GeoJSON.BBox | undefined>();

    useEffect(() => {
      if (!activeAreaOfOperation) {
        if (areasOfOperation && areasOfOperation.length) {
          setActiveAreaOfOperation(areasOfOperation[0]);
        } else {
          setAreaOfOperationHighlight(undefined);
          setMapGoTo(WORLD_BBOX);
        }
        return;
      }

      setMapGoTo(activeAreaOfOperation.boundingBox);
      setAreaOfOperationHighlight({
        layer: getAreaOfOperationLayer(activeAreaOfOperation),
      });
    }, [activeAreaOfOperation, areasOfOperation]);

    const getAreaOfOperationLayer = (
      areaOfOperation: Area,
    ): __esri.GraphicsLayer => {
      const earthPolygonLongitudeLatitude = [
        [-180, 90],
        [180, 90],
        [180, -90],
        [-180, -90],
        [-180, 90],
      ];
      let rings = [earthPolygonLongitudeLatitude];
      const completeFootprintCoordinates = [
        ...areaOfOperation.polygon.coordinates[0],
      ].reverse();
      rings = [...rings, completeFootprintCoordinates];

      const polygon = new Polygon({
        rings,
      });

      const graphic = new Graphic({
        geometry: polygon,
        symbol: new SimpleFillSymbol({
          color: [0, 0, 0, 0.2],
          outline: {
            width: 0,
          },
        }),
      });

      return new GraphicsLayer({
        title: 'area_of_operation_highlight',
        id: `area_of_operation_highlight aoId=${areaOfOperation.id}`,
        graphics: [graphic],
      });
    };

    return (
      <Map
        webMapOptions={webMapOptions}
        customWidgets={customWidgets}
        layers={[
          ...layers,
          ...(areaOfOperationHighlight ? [areaOfOperationHighlight] : []),
        ]}
        mapKey={mapKey}
        layerClickListeners={[
          {
            layerTitle: config.arcgis_webmap_pp_title,
            hitTest: (hitResults) => {
              const results = hitResults.filter((result) => {
                return result.graphic.getEffectivePopupTemplate();
              });
              return results[0];
            },
            onHit: onSiteSelection,
          },
        ]}
        onWebMap={onWebMap}
        boundingBox={mapGoTo}
      />
    );
  },
);
DashboardMap.displayName = 'DashboardMap';

export default DashboardMap;
