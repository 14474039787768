import { useEffect, useContext, PropsWithChildren } from 'react';

import * as Sentry from '@sentry/browser';

import { useAuth } from 'src/auth/AuthContext';
import { FileAnalysisContext } from 'src/fileAnalysis/fileAnalysisContext';
import { DashboardContext } from 'src/pages/Dashboard/context';

function identifyUserOnSentry(username: string | undefined) {
  Sentry.setUser({ username });
}

function setSentryScope(tagName: string, value: string | null) {
  Sentry.configureScope(function (scope) {
    scope.setTag(tagName, value);
  });
}

const LogProvider = ({ children }: PropsWithChildren) => {
  const auth = useAuth();
  const { fileAnalysisHandler } = useContext(FileAnalysisContext);
  const { state } = fileAnalysisHandler!;
  const { selectedSiteUUID } = useContext(DashboardContext);

  identifyUserOnSentry(auth.user?.username);

  useEffect(() => {
    setSentryScope('file-analysis-state', state.status);
  }, [state.status]);

  useEffect(() => {
    setSentryScope('selected-site-uuid', selectedSiteUUID);
  }, [selectedSiteUUID]);

  return <>{children}</>;
};

export default LogProvider;
