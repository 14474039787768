import { PropsWithChildren, createContext, useContext, useEffect } from 'react';

import { ReactComponent as ArrowRepeat } from 'bootstrap-icons/icons/arrow-repeat.svg';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { dashboardPath } from 'src/auth/routes';
import ContentErrorAndDetail from 'src/components/Notification/ContentErrorAndDetail';
import useFileAnalysisHandler, {
  ANALYSIS_DONE,
  ANALYSIS_ERROR,
  EXPORT_DONE,
  EXPORT_ERROR,
  UPLOAD_DONE,
  UPLOAD_ERROR,
  UPLOAD_PROGRESS,
  FileAnalysisHandler,
  ANALYSIS_PROGRESS,
} from 'src/fileAnalysis/useFileAnalysisHandler';
import {
  NotificationType,
  useNotification,
} from 'src/notification/NotificationProvider';
import { DashboardContext } from 'src/pages/Dashboard/context';

interface FileAnalysisContextType {
  fileAnalysisHandler: FileAnalysisHandler | null;
}

const FileAnalysisContext = createContext<FileAnalysisContextType>({
  fileAnalysisHandler: null,
});

const FileAnalysisProvider = (props: PropsWithChildren) => {
  const { showNotification, removeNotificationByID, updateNotificationByID } =
    useNotification();
  const { t } = useTranslation();
  const { setRunUUIDToValidate, setMapKey } = useContext(DashboardContext);
  const fileAnalysisHandler = useFileAnalysisHandler();
  const { state, dispatch } = fileAnalysisHandler;
  const history = useHistory();

  useEffect(() => {
    switch (state.status) {
      case ANALYSIS_ERROR:
        state.analysisProgressNotificationUUID &&
          removeNotificationByID(state.analysisProgressNotificationUUID);
        // eslint-disable-next-line no-case-declarations
        const errors = state.analysisError || [];
        errors.forEach((error) => {
          showNotification({
            content: ContentErrorAndDetail(
              t('uploadModal.analysisNotifications.error.content', {
                siteName: state.siteName,
              }),
              error,
            ),
            title: t('uploadModal.analysisNotifications.error.title'),
            type: NotificationType.Error,
            dataTestID: 'toast-analysis-error',
          });
        });
        break;

      case UPLOAD_DONE:
        state.uploadProgressNotificationUUID &&
          removeNotificationByID(state.uploadProgressNotificationUUID);
        showNotification({
          title: t('uploadModal.uploadNotifications.success.title'),
          content: t('uploadModal.uploadNotifications.success.content', {
            siteName: state.siteName,
          }),
          type: NotificationType.Success,
          duration: 30000,
          dataTestID: 'toast-upload-success',
        });
        // eslint-disable-next-line no-case-declarations
        const analysisProgressNotificationUUID = showNotification({
          title: t('uploadModal.analysisNotifications.progress.title'),
          content: (
            <>
              <span>
                {t('uploadModal.analysisNotifications.progress.content', {
                  siteName: state.siteName,
                })}
              </span>
              <br />
              <span className="font-italic">
                {t('uploadModal.analysisNotifications.progress.warning')}
              </span>
            </>
          ),
          hideClose: true,
          type: NotificationType.InProgress,
          dataTestID: 'toast-analysis-progress',
        });
        dispatch({
          type: 'analysis-progress',
          analysisProgressNotificationUUID,
        });
        break;

      case UPLOAD_ERROR:
        state.uploadProgressNotificationUUID &&
          removeNotificationByID(state.uploadProgressNotificationUUID);
        showNotification({
          content: ContentErrorAndDetail(
            t('uploadModal.uploadNotifications.error.content', {
              siteName: state.siteName,
            }),
          ),
          title: t('uploadModal.uploadNotifications.error.title'),
          type: NotificationType.Error,
          dataTestID: 'toast-upload-error',
        });
        break;

      case ANALYSIS_PROGRESS:
        state.runUUID && fileAnalysisHandler.monitorAnalysis(state.runUUID);
        break;

      case ANALYSIS_DONE:
        state.analysisProgressNotificationUUID &&
          removeNotificationByID(state.analysisProgressNotificationUUID);
        // eslint-disable-next-line no-case-declarations
        const notificationUUID = showNotification({
          title: t('uploadModal.analysisNotifications.success.title'),
          content: (
            <>
              <span>
                {t('uploadModal.analysisNotifications.success.content', {
                  siteName: state.siteName,
                })}
              </span>
              <Button
                variant="link"
                size="sm"
                className="p-0 border-0"
                onClick={() => {
                  setRunUUIDToValidate(state.runUUID);
                  removeNotificationByID(notificationUUID);
                  history.push(dashboardPath);
                }}
              >
                {t('uploadModal.analysisNotifications.success.contentLink')}
              </Button>
            </>
          ),
          hideClose: true,
          type: NotificationType.Success,
          dataTestID: 'toast-analysis-success',
        });
        break;

      case EXPORT_DONE:
        state.exportProgressNotificationUUID &&
          removeNotificationByID(state.exportProgressNotificationUUID);
        // eslint-disable-next-line no-case-declarations
        const notificationID = showNotification({
          title: t('analysisValidation.exportNotifications.success.title'),
          content: (
            <>
              {`${t('analysisValidation.exportNotifications.success.content', {
                siteName: state.siteName,
              })}`}
              <br />
              <Button
                size="sm"
                className="mt-2"
                variant="primary"
                onClick={() => {
                  setMapKey(notificationID);
                  removeNotificationByID(notificationID);
                }}
              >
                <ArrowRepeat className="mr-1" />
                {t('dashboard.refreshMap')}
              </Button>
            </>
          ),
          type: NotificationType.Success,
          dataTestID: 'toast-export-success',
        });
        break;

      case EXPORT_ERROR:
        state.exportProgressNotificationUUID &&
          removeNotificationByID(state.exportProgressNotificationUUID);
        // eslint-disable-next-line no-case-declarations
        const exportErrors = state.exportError || [];
        if (!exportErrors.length)
          exportErrors.push(t('analysisValidation.defaultStatusErrorMessage'));
        exportErrors.forEach((error) => {
          showNotification({
            title: t('analysisValidation.exportNotifications.error.title'),
            content: ContentErrorAndDetail(
              t('analysisValidation.exportNotifications.error.content', {
                siteName: state.siteName,
              }),
              error,
            ),
            type: NotificationType.Error,
            dataTestID: 'toast-export-error',
          });
        });
        break;
    }
  }, [state.status]);

  useEffect(() => {
    if (state.status === UPLOAD_PROGRESS) {
      if (state.uploadProgressNotificationUUID) {
        updateNotificationByID(state.uploadProgressNotificationUUID, {
          content: t('uploadModal.uploadNotifications.progress.content', {
            percentage: state.uploadProgress,
            siteName: state.siteName,
          }),
        });
      } else {
        const uploadProgressNotificationUUID = showNotification({
          title: t('uploadModal.uploadNotifications.progress.title'),
          content: t('uploadModal.uploadNotifications.progress.content', {
            percentage: 0,
            siteName: state.siteName,
          }),
          hideClose: true,
          type: NotificationType.InProgress,
          dataTestID: 'toast-upload-progress',
        });
        dispatch({
          type: 'upload-progress',
          uploadProgressNotificationUUID,
          uploadProgress: state.uploadProgress,
        });
      }
    }
  }, [state.uploadProgress]);

  return (
    <FileAnalysisContext.Provider
      value={{
        fileAnalysisHandler,
      }}
    >
      {props.children}
    </FileAnalysisContext.Provider>
  );
};

export { FileAnalysisContext, FileAnalysisProvider };
