import { CaptureConsole } from '@sentry/integrations';
import { init } from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import environment from 'src/environments/environment';

if (environment.mode === ('staging' || 'prodint')) {
  init({
    dsn: 'https://91451190b11142d79668bb0649cbbc68@o913108.ingest.sentry.io/5875210',

    environment: environment.mode,

    // console.error logging
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsole({
        levels: ['error'],
      }),
    ],

    tracesSampleRate: 1.0,
  });
}
