import React, { useEffect, useState, useRef } from 'react';

import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

import { Config } from 'src/api/internalApi';
import { useConfig } from 'src/config/ConfigContext';
import environment from 'src/environments/environment';

import './index.scss';

const arcgisAuthBaseUrl = `${environment.oauth2PortalUrl}/sharing/oauth2/authorize`;
const arcgisAuthUrlParams: { [key: string]: string | number } = {
  client_id: 'arcgisInsights',
  response_type: 'token',
  state: JSON.stringify({ portalUrl: 'https://www.arcgis.com' }),
  expiration: 20160,
  redirect_uri: 'https://insights.arcgis.com/',
};
const widgetBaseURL = 'https://insights.arcgis.com/#/embed/';

const Widget = (): React.ReactElement => {
  const currentKey = window.location.hash.substring(1);
  const [showModal, setShowModal] = useState(false);
  const [userAuthProcessing, setUserAuthProcessing] = useState(false);
  const [loadedIframes, setLoadedIframes] = useState<string[]>([]);
  const userAuthProcessingStateRef = useRef(userAuthProcessing);
  const iframesLoadingState: {
    [key: string]: boolean;
  } = {};
  const { t } = useTranslation();
  const config = useConfig().config!;

  const setUserAuthProcessingState = (data: boolean) => {
    userAuthProcessingStateRef.current = data;
    setUserAuthProcessing(data);
  };

  useEffect(() => {
    // User has switched back to the tab
    const onFocus = () => {
      if (userAuthProcessingStateRef.current) {
        removeOnFocusListener();
        window.location.reload();
      }
    };
    const removeOnFocusListener = () =>
      window.removeEventListener('focus', onFocus);
    window.addEventListener('focus', onFocus);
    return () => {
      removeOnFocusListener();
    };
  }, []);

  useEffect(() => {
    setUserAuthProcessingState(false);
    setLoadedIframes([...Array.from(new Set([...loadedIframes, currentKey]))]);
  }, [currentKey]);

  function handleLoad(key: string) {
    if (!iframesLoadingState[key]) {
      iframesLoadingState[key] = currentKey === key;
      return;
    }
    handleShowModal();
  }

  const handleExternalLogin = () => {
    setUserAuthProcessingState(true);
    let urlParams: string[] = [];
    for (const key in arcgisAuthUrlParams) {
      urlParams = [
        ...urlParams,
        `${key}=${encodeURIComponent(arcgisAuthUrlParams[key])}`,
      ];
    }
    const authUrl = `${arcgisAuthBaseUrl}?${urlParams.join('&')}`;
    window.open(authUrl);
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <>
      {(
        Object.keys(config.arcgis_insights) as Array<
          keyof Config['arcgis_insights']
        >
      ).map((key) => (
        <iframe
          onLoad={function () {
            handleLoad(key);
          }}
          key={key}
          src={
            loadedIframes.includes(key)
              ? `${widgetBaseURL}${config.arcgis_insights[key]}`
              : undefined
          }
          className={classNames('iframe', { 'd-none': currentKey !== key })}
          frameBorder="0"
          data-testid="widget-iframe"
        />
      ))}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        data-testid="widget-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('widget.modalArcgisLogin.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('widget.modalArcgisLogin.content.loginNeeded')}
          <br />
          {t('widget.modalArcgisLogin.content.youWillBeRedirected')}
          <br />
          {t('widget.modalArcgisLogin.content.pleaseComeBack')}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseModal}
            data-testid="widget-modal-cancel"
          >
            {t('widget.modalArcgisLogin.cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={handleExternalLogin}
            data-testid="widget-modal-login"
          >
            {t('widget.modalArcgisLogin.understood')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Widget;
