import React from 'react';

function ContentErrorAndDetail(
  errorMessage: string,
  detail?: string,
): React.ReactElement {
  return (
    <>
      <span className="font-weight-bolder">{errorMessage}</span>
      {detail && (
        <>
          <br />
          <span>{detail}</span>
        </>
      )}
    </>
  );
}

export default ContentErrorAndDetail;
