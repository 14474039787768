import { PropsWithChildren } from 'react';

import { AuthProvider } from 'src/auth/AuthContext';
import { UserProvider } from 'src/auth/UserContext';

function SecurityProvider({ children }: PropsWithChildren) {
  return (
    <AuthProvider>
      <UserProvider>{children}</UserProvider>
    </AuthProvider>
  );
}

export default SecurityProvider;
