export * from './results';

export const run = {
  run_uuid: 'run_uuid_value',
};

export const analysisRunning = {
  status: 'detector_running',
};

export const analysisSuccess = {
  status: 'fusion_success',
};

export const validationRunning = {
  status: 'export_running',
};

export const validationSuccess = {
  status: 'export_success',
};

export const surveyOutdated = {
  status: 'survey-oudated',
};
