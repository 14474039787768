import React from 'react';

import { BarCustomLayerProps, ResponsiveBar } from '@nivo/bar';
import { BoxLegendSvg } from '@nivo/legends';
import Alert from 'react-bootstrap/Alert';
import { useTranslation } from 'react-i18next';

import LoadingSpinner from 'src/components/LoadingSpinner';
import { WeeklySwirActivity } from 'src/models/swir.interface';
import { filterLabels } from 'src/utils/chartUtil';

import './index.scss';

interface ChartProps {
  isLoading: boolean;
  isQueryFailed: boolean;
  data: WeeklySwirActivity[] | null;
  xLabelMaxCount?: number;
  totalActiveItems: number | null;
  fullKeys: string[];
  graphKeys: string[];
  legendItemWidth: number;
  colors: string[];
  showLabels: boolean;
  testId: string;
}

// This is required to have custom legends ('active chimney count' instead of 'activeChimneyCount' etc...)
function BarLegend({
  width,
  height,
  legendData,
}: BarCustomLayerProps<WeeklySwirActivity>) {
  return (
    <BoxLegendSvg
      key="activity-legend"
      {...legendData[0][0]}
      data={legendData[0][0].data || []}
      containerHeight={height}
      containerWidth={width}
    />
  );
}

function Chart({
  isLoading,
  isQueryFailed,
  data,
  xLabelMaxCount,
  totalActiveItems,
  fullKeys,
  graphKeys,
  legendItemWidth,
  testId,
  colors,
  showLabels,
}: ChartProps): React.ReactElement {
  const { t } = useTranslation();

  const ActivityChart = ({
    data: weeklySwirActivityData,
  }: {
    data: WeeklySwirActivity[];
  }) => {
    let xTickValues: string[] = weeklySwirActivityData.map(
      (item) => item.firstWeekDay,
    );
    if (xLabelMaxCount) {
      xTickValues = filterLabels(xTickValues, xLabelMaxCount);
    }
    return (
      <ResponsiveBar
        data={weeklySwirActivityData}
        keys={graphKeys}
        indexBy="firstWeekDay"
        margin={{ top: 30, right: 0, bottom: 20, left: 20 }}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: false }}
        colors={colors}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          tickValues: xTickValues,
        }}
        axisLeft={{
          tickValues: 3,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        enableGridY={false}
        enableLabel={showLabels}
        labelSkipWidth={16}
        labelSkipHeight={12}
        valueFormat={'.1f'}
        labelFormat={(entry) => (entry as number).toFixed(1)}
        labelTextColor={(val) => {
          return val.color === '#024D58' ? 'white' : 'dark-gray';
        }}
        layers={['grid', 'axes', 'bars', 'markers', BarLegend]}
        legends={[
          {
            dataFrom: 'keys',
            data: graphKeys.map((_id, index) => ({
              color: colors[index],
              id: index,
              label: fullKeys[index],
            })),
            anchor: 'top-right',
            direction: 'row',
            justify: false,
            translateX: -10,
            translateY: -30,
            itemsSpacing: 2,
            itemWidth: legendItemWidth,
            itemHeight: 15,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 15,
          },
        ]}
        isInteractive={true}
        animate={false}
        tooltip={
          totalActiveItems
            ? (point) => (
                <div className="ChartTooltip">
                  <div className="font-weight-bold">
                    {point.data.firstWeekDay}
                  </div>
                  <div>
                    {Math.round((point.value / totalActiveItems) * 100)}%
                  </div>
                </div>
              )
            : undefined
        }
      />
    );
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}

      {isQueryFailed && (
        <Alert variant="danger">{t('powerPlantsActivity.errorRequest')}</Alert>
      )}

      {!isLoading && !isQueryFailed && (
        <div className="chart" data-testid={testId}>
          {data && <ActivityChart data={data} />}
        </div>
      )}
    </>
  );
}

export default Chart;
