import React, { useState, useEffect } from 'react';

import QueryTask from '@arcgis/core/tasks/QueryTask';
import Query from '@arcgis/core/tasks/support/Query';
import { useTranslation } from 'react-i18next';

import Chart from 'src/components/Chart';
import { useConfig } from 'src/config/ConfigContext';
import { isEsriError } from 'src/models/guards/esriError.guard';
import {
  ActivityAverages,
  SiteActivity,
  WeeklySwirActivity,
} from 'src/models/swir.interface';
import { dateMonthsAgo } from 'src/utils/chartUtil';
import { flattenAndCamelCase, processData } from 'src/utils/dataProcessing';

const OUT_FIELDS = [
  'active_site_count',
  'inactive_site_count',
  'cloudy_site_count',
  'stream_date',
];
const COLORS = ['#05BF92', '#EBEBEB', '#024D58'];
const GRAPH_KEYS = ['activeSiteCount', 'cloudySiteCount', 'inactiveSiteCount'];

const AVG_FIELDS: (keyof ActivityAverages<SiteActivity>)[] =
  GRAPH_KEYS as (keyof ActivityAverages<SiteActivity>)[];

const X_TICK_MAX_COUNT = 27;

interface Props {
  numberOfMonth: number;
  onArcgisError: (error: __esri.Error) => void;
  areaOfOperationId?: string;
  swirActivityMaxDate?: Date;
}

const SiteActivityChart = ({
  numberOfMonth,
  onArcgisError,
  areaOfOperationId,
  swirActivityMaxDate,
}: Props): React.ReactElement | null => {
  const { t } = useTranslation();
  const config = useConfig().config!;
  const fullKeys: string[] = t('powerPlantsActivity.sitesActivity.labels', {
    returnObjects: true,
  });
  const testId = 'chart-power-plants-activity';
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isQueryFailed, setQueryFail] = useState<boolean>(false);
  const queryTask = new QueryTask({
    url: config.arcgis_fs_url_swir_activity,
  });
  const [numberOfActiveSites, setNumberOfActiveSites] = useState<number | null>(
    null,
  );
  const [weeklySwirData, setWeeklySwirData] = useState<
    WeeklySwirActivity[] | null
  >(null);

  const query = (options?: { offset: number }) => {
    let where = `stream_date > '${dateMonthsAgo(
      numberOfMonth,
      swirActivityMaxDate,
    )}'`;
    if (areaOfOperationId) {
      where += ` AND area_of_operation_id = ${areaOfOperationId}`;
    }
    return new Query({
      outFields: OUT_FIELDS,
      where: where,
      orderByFields: ['stream_date DESC'],
      start: options?.offset,
      num: 1000,
    });
  };

  function getResults(response: __esri.FeatureSet) {
    setLoading(false);
    const fetchedWeeklySwirData = processData<SiteActivity>(
      flattenAndCamelCase<SiteActivity>(response),
      AVG_FIELDS,
    );
    const maxActiveSites = Math.max(
      ...fetchedWeeklySwirData.map(
        (weeklySwirActivity) =>
          weeklySwirActivity.activeSiteCount +
          weeklySwirActivity.cloudySiteCount +
          weeklySwirActivity.inactiveSiteCount,
      ),
    );
    setNumberOfActiveSites(maxActiveSites);
    setWeeklySwirData(fetchedWeeklySwirData);
  }

  function requestFailed(error: unknown) {
    setLoading(false);
    setQueryFail(true);
    if (isEsriError(error)) {
      onArcgisError(error);
      console.error('Power plants SiteActivity request error: ', error.name);
    }
  }

  useEffect(() => {
    const runQuery = async () => {
      try {
        const queryResponse = await queryTask.execute(query());
        while (queryResponse.exceededTransferLimit) {
          const pageResponse = await queryTask.execute(
            query({ offset: queryResponse.features.length }),
          );
          queryResponse.exceededTransferLimit =
            pageResponse.exceededTransferLimit;
          queryResponse.features = [
            ...queryResponse.features,
            ...pageResponse.features,
          ];
        }
        getResults(queryResponse);
      } catch (e) {
        requestFailed(e);
      }
    };
    setLoading(true);
    setQueryFail(false);
    runQuery();
  }, [numberOfMonth, areaOfOperationId]);

  return (
    <Chart
      isLoading={isLoading}
      isQueryFailed={isQueryFailed}
      data={weeklySwirData}
      xLabelMaxCount={X_TICK_MAX_COUNT}
      totalActiveItems={numberOfActiveSites}
      fullKeys={fullKeys}
      graphKeys={GRAPH_KEYS}
      legendItemWidth={110}
      testId={testId}
      colors={COLORS}
      showLabels={true}
    />
  );
};

export default SiteActivityChart;
