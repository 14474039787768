import React, { memo, useState, useContext, useEffect, useMemo } from 'react';

import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as InfoCircle } from 'bootstrap-icons/icons/info-circle.svg';
import moment from 'moment';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';

import { useApi } from 'src/api/APIContext';
import { VHRSurveyStatus } from 'src/api/internalApi';
import {
  AreasOfOperation,
  areasOfOperationAdapter,
} from 'src/api/internalApi/areas-of-operation';
import { getSourceImageBounds } from 'src/api/internalApi/tiles';
import { useAuth } from 'src/auth/AuthContext';
import AnalysisResultsPanel from 'src/components/AnalysisResultsPanel';
import {
  WebMapProperties,
  LayerWithIndex,
  ClickResult,
  CustomWidget,
} from 'src/components/ArcGisMap';
import DashboardMap from 'src/components/DashboardMap';
import DashboardMapAreaSwitcher from 'src/components/DashboardMapAreaSwitcher';
import DashboardMapLegend from 'src/components/DashboardMapLegend';
import { createSourceImageLayer } from 'src/components/Map/SourceImageLayer';
import ContentErrorAndDetail from 'src/components/Notification/ContentErrorAndDetail';
import SiteActivityChart from 'src/components/SiteActivityChart';
import SitePanel from 'src/components/SitePanel';
import TimeframeSelector from 'src/components/TimeframeSelector';
import UploadModal from 'src/components/UploadModal';
import { useConfig } from 'src/config/ConfigContext';
import environment from 'src/environments/environment';
import { FileAnalysisContext } from 'src/fileAnalysis/fileAnalysisContext';
import { isEsriError } from 'src/models/guards/esriError.guard';
import {
  NotificationType,
  useNotification,
} from 'src/notification/NotificationProvider';
import { DashboardContext, Area } from 'src/pages/Dashboard/context';

import './index.scss';

const imageryURL = (uuid: string) =>
  `${environment.apiBaseUrl}/tiles/vhr/${uuid}/xyz/{z}/{x}/{y}.png`;

const SiteActivityChartCard = memo(
  ({
    activeAreaOfOperation,
    handleArcgisError,
  }: {
    activeAreaOfOperation: Area | null;
    handleArcgisError: (error: __esri.Error) => void;
  }) => {
    const { t } = useTranslation();
    const [numberOfMonth, setNumberOfMonth] = useState(6);

    const swirActivityMaxDate = environment.swirActivityMaxDate
      ? new Date(environment.swirActivityMaxDate)
      : undefined;

    return (
      <Card>
        <Card.Body className="d-flex flex flex-column pb-2">
          {!activeAreaOfOperation?.isSwirMonitored && (
            <div className="text-muted d-flex justify-content-center align-items-center h-100">
              {t('powerPlantsActivity.sitesActivity.notSwirMonitored')}
            </div>
          )}
          {activeAreaOfOperation?.isSwirMonitored && (
            <>
              <div className="d-flex justify-content-between ">
                <Card.Title className="mb-1 chart__title">
                  <div className="d-flex align-items-center mb-1">
                    <span
                      className="mr-2"
                      data-testid="power-plants-activity-title"
                    >
                      {t('powerPlantsActivity.sitesActivity.title', {
                        area: activeAreaOfOperation.name,
                      })}
                    </span>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="activity-chart-tooltip">
                          {t('powerPlantsActivity.sitesActivity.tooltip')}
                        </Tooltip>
                      }
                    >
                      <InfoCircle />
                    </OverlayTrigger>
                  </div>
                  <TimeframeSelector
                    timeframeOptions={[6, 12, 18]}
                    defaultValue={6}
                    onSelect={setNumberOfMonth}
                  />
                </Card.Title>
                {swirActivityMaxDate && (
                  <div className="text-right font-italic text-muted align-items-start">
                    {t('powerPlantsActivity.sitesActivity.dataMaxDate', {
                      date: moment(swirActivityMaxDate).format('MM/DD/YY'),
                    })}
                  </div>
                )}
              </div>

              <SiteActivityChart
                numberOfMonth={numberOfMonth}
                swirActivityMaxDate={swirActivityMaxDate}
                onArcgisError={handleArcgisError}
                areaOfOperationId={activeAreaOfOperation?.id}
              />
            </>
          )}
        </Card.Body>
      </Card>
    );
  },
  // PropsAreEqual ?
  // Re-render component only on activeAreaOfOperation prop change
  (prevProps, nextProps) =>
    prevProps.activeAreaOfOperation === nextProps.activeAreaOfOperation,
);
SiteActivityChartCard.displayName = 'SiteActivityChartCard';

const Dashboard: React.FC = (): React.ReactElement => {
  const { showNotification } = useNotification();
  const { t } = useTranslation();
  const httpClient = useApi();
  const { getUserToken } = useAuth();
  const config = useConfig().config!;
  const {
    selectedSiteUUID,
    setSelectedSiteUUID,
    runUUIDToValidate,
    setRunUUIDToValidate,
    setMapLegendVisibility,
    mapKey,
    setAuthorizationError,
  } = useContext(DashboardContext);
  const { fileAnalysisHandler } = useContext(FileAnalysisContext);
  const {
    state,
    dispatch,
    monitorExport,
    submitHandler,
    reset,
    setSelectedFiles,
  } = fileAnalysisHandler!;
  const [mapLayers, setMapLayers] = useState<LayerWithIndex[]>([]);
  const [imageryMapLayer, setImageryMapLayer] = useState<__esri.Layer | null>(
    null,
  );
  const [siteSelection, setSiteSelection] = useState<ClickResult>();
  const [webMap, setWebMap] = useState<__esri.WebMap>();
  const [seletectedAreaOfOperation, setSeletectedAreaOfOperation] =
    useState<Area | null>(null);

  const footprintsLayerUrl = useMemo(() => {
    if (!webMap) return;
    const footprintsLayer = webMap.layers.find(
      (layer) => layer.title === config.arcgis_webmap_footprints_title,
    );
    return footprintsLayer && footprintsLayer instanceof FeatureLayer
      ? `${footprintsLayer.url}/${footprintsLayer.layerId}`
      : undefined;
  }, [webMap]);

  // For testing purpose
  if (window.Cypress) {
    window.setSiteSelection = setSiteSelection;
    window.setRunUUIDToValidate = setRunUUIDToValidate;
    window.fileAnalysisDispatch = dispatch;
  }

  const areasOfOperationQueryResult = useQuery<Area[], Error>({
    queryKey: ['areasOfOperation'],
    queryFn: async () => {
      const { data } = await httpClient.get<AreasOfOperation>(
        '/areas-of-operation/',
      );
      return areasOfOperationAdapter(data);
    },
  });

  const closeSitePanel = () => {
    siteSelection && siteSelection.onDeselection();
    setSiteSelection(undefined);
    setSelectedSiteUUID(null);
  };

  useEffect(() => {
    if (state.status === 'EXPORT_PROGRESS') {
      closeSitePanel();
    }
  }, [state.status]);

  useEffect(() => {
    closeSitePanel();
  }, [mapKey]);

  const webMapOptions: WebMapProperties = {
    portalItem: { id: config.arcgis_webmap_id },
  };

  const handleArcgisError = (error: unknown) => {
    if (isEsriError(error)) {
      setAuthorizationError(error.message);
    }
  };

  function changeLegendDisplay(isLegendVisible: boolean) {
    setMapLegendVisibility(isLegendVisible);
  }

  function changeAreaOfOperation(area: Area | null) {
    setSeletectedAreaOfOperation(area);
  }

  const customWidgets: CustomWidget[] = useMemo(
    () => [
      {
        widget: (
          <DashboardMapLegend changeLegendDisplay={changeLegendDisplay} />
        ),
        position: 'top-right',
        id: 'DashboardMapLegend',
      },
      {
        widget: (
          <DashboardMapAreaSwitcher
            areasOfOperation={areasOfOperationQueryResult.data || []}
            changeAreaOfOperation={changeAreaOfOperation}
          />
        ),
        position: 'bottom-left',
        id: 'DashboardMapAreaSwitcher',
      },
    ],
    [areasOfOperationQueryResult.data],
  );

  function onAnalysisResultValidate() {
    setRunUUIDToValidate(null);
    const inProgressNotificationID = showNotification({
      title: t('analysisValidation.exportNotifications.progress.title'),
      type: NotificationType.InProgress,
      hideClose: true,
      content: (
        <>
          <span>
            {t('analysisValidation.exportNotifications.progress.content', {
              siteName: state.siteName,
            })}
          </span>
          <br />
          <span className="font-italic">
            {t('analysisValidation.exportNotifications.progress.warning')}
          </span>
        </>
      ),
      dataTestID: 'toast-export-progress',
    });
    dispatch({
      type: 'export-progress',
      exportProgressNotificationUUID: inProgressNotificationID,
    });
    monitorExport(state.runUUID || '');
  }

  function onAnalysisResultDiscard() {
    setRunUUIDToValidate(null);
    reset();
  }

  async function querySurveyStatus(siteUUID: string): Promise<VHRSurveyStatus> {
    return httpClient
      .get<VHRSurveyStatus>(`/site/${siteUUID}/vhr-survey-status`)
      .then((res) => res.data)
      .catch((err) => {
        console.error('!', err);
        throw err;
      });
  }

  function addImageryMapLayer(siteUUID: string) {
    querySurveyStatus(siteUUID)
      .then(async (res) => {
        if (!res.vhr_run_uuid) return;
        const sourceImageBounds = await getSourceImageBounds(
          res.vhr_run_uuid,
          httpClient,
        );
        const imageryLayer = createSourceImageLayer(
          imageryURL(res.vhr_run_uuid),
          getUserToken() ?? '',
          sourceImageBounds,
        );

        setMapLayers([{ layer: imageryLayer, index: 0 }]);
        setImageryMapLayer(imageryLayer);
      })
      .catch(() => {
        showNotification({
          title: t('map.imagery.errorRequestLatestRun.title'),
          content: ContentErrorAndDetail(
            t('map.imagery.errorRequestLatestRun.content'),
          ),
          type: NotificationType.Error,
        });
      });
  }

  function removeImageryMapLayer(imageryMapLayerToRemove: __esri.Layer) {
    if (imageryMapLayerToRemove) {
      const updatedLayersList = mapLayers.filter(
        (mapLayer) => mapLayer.layer.id !== imageryMapLayerToRemove.id,
      );
      setMapLayers(updatedLayersList);
    }
  }

  useEffect(() => {
    if (imageryMapLayer) {
      removeImageryMapLayer(imageryMapLayer);
    }
    if (!selectedSiteUUID) return;
    addImageryMapLayer(selectedSiteUUID);
  }, [selectedSiteUUID]);

  return (
    <div className="dashboard">
      <UploadModal
        submitHandler={submitHandler}
        setSelectedFiles={setSelectedFiles}
        reset={reset}
      />
      <div className="dashboard_mapContainer">
        {siteSelection && (
          <SitePanel
            featureId={siteSelection.clickedGraphic.attributes.FID}
            mapView={siteSelection.mapView}
            layerUrl={siteSelection.layerUrl}
            siteGeometry={siteSelection.clickedGraphic.geometry}
            handlePanelClose={closeSitePanel}
          />
        )}

        <DashboardMap
          webMapOptions={webMapOptions}
          customWidgets={customWidgets}
          layers={mapLayers}
          mapKey={mapKey}
          onSiteSelection={setSiteSelection}
          onWebMap={setWebMap}
          activeAreaOfOperation={seletectedAreaOfOperation}
          setActiveAreaOfOperation={setSeletectedAreaOfOperation}
          areasOfOperation={areasOfOperationQueryResult.data}
        />
      </div>
      <Container fluid className="dashboard_chartContainer">
        <SiteActivityChartCard
          activeAreaOfOperation={seletectedAreaOfOperation}
          handleArcgisError={handleArcgisError}
        />
      </Container>
      {runUUIDToValidate && (
        <AnalysisResultsPanel
          onDiscard={onAnalysisResultDiscard}
          runUUID={runUUIDToValidate}
          footprintsLayerUrl={footprintsLayerUrl}
          onValidate={onAnalysisResultValidate}
        />
      )}
    </div>
  );
};

export default Dashboard;
