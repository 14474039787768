import React, { ReactElement } from 'react';

import { Route, Redirect } from 'react-router-dom';

import { useAuth } from 'src/auth/AuthContext';
import Navigation from 'src/components/Navigation';
import NotificationAnchorPlacement from 'src/components/Notification/NotificationAnchorPlacement';

interface Props {
  path: string;
  component: React.FC;
}

function ProtectedRoute({ component: Page, ...rest }: Props): ReactElement {
  const auth = useAuth();

  if (!auth.isAuthenticated()) {
    return <Redirect to="/" />;
  }

  return (
    <Route
      {...rest}
      render={() => (
        <>
          <Navigation />
          <NotificationAnchorPlacement />
          <Page />
        </>
      )}
    />
  );
}

export default ProtectedRoute;
