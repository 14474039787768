import React, { useState, useEffect, useContext, useMemo } from 'react';

import QueryTask from '@arcgis/core/tasks/QueryTask';
import Query from '@arcgis/core/tasks/support/Query';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CloseButton from 'react-bootstrap/CloseButton';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';

import ChimneyActivityChart from 'src/components/ChimneyActivityChart';
import LoadingSpinner from 'src/components/LoadingSpinner';
import {
  PAIConfidence,
  PowerPlant,
} from 'src/components/SitePanel/powerPlants';
import SiteInfo from 'src/components/SitePanel/SiteInfo';
import TimeframeSelector from 'src/components/TimeframeSelector';
import { isEsriError } from 'src/models/guards/esriError.guard';
import { DashboardContext } from 'src/pages/Dashboard/context';

import './index.scss';

interface Props {
  featureId: number | null;
  mapView: __esri.MapView;
  layerUrl: string;
  siteGeometry?: __esri.Geometry;
  handlePanelClose: () => void;
}

function SitePanel({
  featureId,
  mapView,
  layerUrl,
  siteGeometry,
  handlePanelClose,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const [siteAttributes, setSiteAttributes] = useState<PowerPlant | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [isQueryFailed, setQueryFail] = useState(false);
  const [isMouseEntered, setMouseEntered] = useState(false);
  const {
    setShow,
    setSiteData,
    setSelectedSiteUUID,
    isInBusyState,
    setAuthorizationError,
  } = useContext(DashboardContext);
  const [chartNumberOfMonth, setChartNumberOfMonth] = useState(6);

  function getResults(response: __esri.FeatureSet) {
    const attributes = response.features[0].attributes;
    setLoading(false);
    setSiteAttributes(attributes);
    setSelectedSiteUUID(attributes.uuid);
  }

  function requestFailed(error: unknown) {
    setLoading(false);
    setQueryFail(true);
    if (isEsriError(error)) {
      setAuthorizationError(error.message);
      console.error('Power plants attributes request error: ', error.message);
    }
  }

  function getPowerPlantAttributes(_featureId: number) {
    setLoading(true);
    setQueryFail(false);
    const queryTask = new QueryTask({
      url: layerUrl,
    });

    const query = new Query({
      outFields: ['*'],
      where: `FID=${_featureId}`,
    });

    const runQuery = async () => {
      try {
        const queryResponse = await queryTask.execute(query);
        getResults(queryResponse);
      } catch (error) {
        requestFailed(error);
      }
    };

    runQuery();
  }

  const goToSiteLocation = () => {
    siteGeometry &&
      mapView?.goTo({
        center: siteGeometry,
        zoom: mapView.zoom > 14 ? mapView.zoom : 14, // zoom 14 is ~0.6 miles on scale
      });
  };

  useEffect(() => {
    if (featureId) {
      setChartNumberOfMonth(6);
      setSiteAttributes(null);
      getPowerPlantAttributes(featureId);
    }
  }, [featureId]);

  const handleUpload = () => {
    setShow(true);
    if (siteAttributes) {
      setSiteData({
        siteName: siteAttributes.name,
        siteUUID: siteAttributes.uuid,
      });
    }
  };
  const isInsufficientlyCertain = useMemo(
    () => siteAttributes && siteAttributes.pai_confidence !== 'high',
    [siteAttributes],
  );

  const showTooltip = useMemo(
    () => isMouseEntered && (isInBusyState || !!isInsufficientlyCertain),
    [isMouseEntered, isInBusyState, isInsufficientlyCertain],
  );
  const tooltipMessage = useMemo(
    () =>
      isInsufficientlyCertain
        ? t('powerPlantsAttributes.insufficientCertainty')
        : t('powerPlantsAttributes.onGoingAnalysis'),
    [isInsufficientlyCertain],
  );

  return (
    <Card
      className="sitePanel border-0 rounded-0 shadow"
      data-testid="site-panel"
    >
      <Card.Header>
        <div className="d-flex justify-content-between">
          <div>
            {!isLoading && !isQueryFailed && siteAttributes?.name && (
              <p className="text-capitalize h5 mb-1">{siteAttributes?.name}</p>
            )}
            {!isLoading && siteAttributes?.pai_confidence && (
              <PAIConfidenceSubtitle
                paiConfidence={siteAttributes.pai_confidence}
              />
            )}
          </div>
          <CloseButton
            onClick={() => handlePanelClose()}
            data-testid="site-panel-close-button"
          />
        </div>
      </Card.Header>
      <Card.Body className="sitePanel__content">
        {isLoading && <LoadingSpinner />}
        {isQueryFailed && (
          <Alert variant="danger">
            {t('powerPlantsAttributes.errorRequest')}
          </Alert>
        )}

        {!isLoading && !isQueryFailed && siteAttributes && (
          <>
            <div className="mb-2">
              <OverlayTrigger
                onToggle={(nextShow: boolean) => setMouseEntered(nextShow)}
                show={showTooltip}
                overlay={
                  <Tooltip id="tooltip-disabled">{tooltipMessage}</Tooltip>
                }
              >
                <span className="d-inline-block mr-2">
                  <Button
                    size="sm"
                    className="btn btn-primary"
                    data-testid="upload-button"
                    onClick={() => handleUpload()}
                    style={{
                      pointerEvents:
                        isInBusyState || !!isInsufficientlyCertain
                          ? 'none'
                          : 'auto',
                    }}
                    disabled={isInBusyState || !!isInsufficientlyCertain}
                  >
                    {t('uploadModal.open')}
                  </Button>
                </span>
              </OverlayTrigger>
              <Button
                size="sm"
                variant="primary"
                onClick={() => goToSiteLocation()}
              >
                {t('powerPlantsAttributes.centerOnMap')}
              </Button>
            </div>
            <SiteInfo attributes={siteAttributes} />
            <Card>
              <Card.Body>
                <Card.Title className="mb-1">
                  <p className="mb-1">
                    {t('powerPlantsActivity.chimneysActivity.title')}
                  </p>
                  <TimeframeSelector
                    timeframeOptions={[6, 12, 18]}
                    defaultValue={6}
                    onSelect={setChartNumberOfMonth}
                  />
                </Card.Title>
                <ChimneyActivityChart
                  siteUUID={siteAttributes.uuid}
                  numberOfMonth={chartNumberOfMonth}
                />
              </Card.Body>
            </Card>
          </>
        )}
      </Card.Body>
    </Card>
  );
}

export default SitePanel;

function PAIConfidenceSubtitle(props: {
  paiConfidence: PAIConfidence;
}): React.ReactElement {
  const { t } = useTranslation();
  const paiConfidence = props.paiConfidence;
  return (
    <span className="text-uppercase">
      {t('powerPlantsAttributes.levelOfCertainty')}
      <PAIConfidenceBadge paiConfidence={paiConfidence} />
      {` (${t('analysisResultsPanel.sources.pai')})`}
    </span>
  );
}
function PAIConfidenceBadge(props: {
  paiConfidence: PAIConfidence;
}): React.ReactElement {
  const paiConfidence = props.paiConfidence;
  let badgeCSSClass = '';
  switch (paiConfidence) {
    case 'low':
      badgeCSSClass = 'badge_low-confidence';
      break;
    case 'medium':
      badgeCSSClass = 'badge_medium-confidence';
      break;
    case 'high':
      badgeCSSClass = 'badge_high-confidence';
      break;
  }
  return (
    <span className={`text-uppercase badge ${badgeCSSClass}`}>
      {paiConfidence}
    </span>
  );
}
