export const francePolygon: GeoJSON.Polygon = {
  type: 'Polygon',
  coordinates: [
    [
      [7.130345969302944, 47.50302658239207],
      [7.111058458608464, 47.49490275577542],
      [7.066595656975458, 47.49348103198955],
      [7.024394386855231, 47.50421334191186],
      [6.985924997204884, 47.49152131286686],
      [6.998962818675516, 47.45229903121829],
      [6.970004495274878, 47.44688262940426],
      [6.940536510867674, 47.43337372140521],
      [6.9383780521299885, 47.40601986473863],
      [6.917565696040302, 47.40554258429283],
      [6.911280984371045, 47.3856550932385],
      [6.8853970779398175, 47.37459946804773],
      [6.87936818281023, 47.35839729641757],
      [6.919496443267278, 47.35536414476774],
      [6.994215518452339, 47.363057159308],
      [7.012120561775966, 47.37292288887178],
      [7.049718402997699, 47.360719992295074],
      [7.062207100094967, 47.34415745980239],
      [7.046203037401215, 47.326976713950984],
      [7.016140643996923, 47.31359873592356],
      [6.993126569921015, 47.29554511879603],
      [6.942895673773597, 47.287754384904254],
      [6.955208843014741, 47.244149772352195],
      [6.940725555973242, 47.231843645454894],
      [6.882433031143578, 47.201615584703326],
      [6.858349567867174, 47.164383477038406],
      [6.800236684357302, 47.128793351463806],
      [6.763777402656871, 47.11979205072034],
      [6.739920812240863, 47.1080561130741],
      [6.74109066784422, 47.091064413033465],
      [6.717835845806807, 47.08893991246636],
      [6.691621066187363, 47.06668923642061],
      [6.7140493259380465, 47.04912442357012],
      [6.699656601550015, 47.039041188281],
      [6.661624427517901, 47.02822165295931],
      [6.633742279087916, 46.99841408318843],
      [6.59318642597294, 46.991771506770114],
      [6.566568468914689, 46.98063803499442],
      [6.5188133537312165, 46.970857851315834],
      [6.49668554758685, 46.97418077120232],
      [6.446572766755816, 46.9341439731704],
      [6.432669440426171, 46.92860208537252],
      [6.4645840104267736, 46.89020943846434],
      [6.460055924791717, 46.85168246859297],
      [6.44262902075631, 46.83235109259498],
      [6.434826378361494, 46.801464616461466],
      [6.458567776224122, 46.788495437539005],
      [6.425917841439646, 46.75480272370325],
      [6.39507223431701, 46.74824769305675],
      [6.391779221321134, 46.73835933517175],
      [6.344803166577885, 46.711872179216],
      [6.285329368806656, 46.691241021954745],
      [6.227994158729884, 46.64830608201645],
      [6.175538722376977, 46.61413990223054],
      [6.127338291895881, 46.59030639234697],
      [6.110748015678899, 46.576313203446446],
      [6.1381085470292716, 46.55766039903405],
      [6.1564485471743415, 46.54546781327305],
      [6.112697563396416, 46.50963869187334],
      [6.096828928594163, 46.481206078469945],
      [6.073927085793577, 46.46394600628466],
      [6.085838952286885, 46.440997039522614],
      [6.06400848181829, 46.41622698893153],
      [6.098192550862728, 46.4087886309623],
      [6.169741660312734, 46.36793686221589],
      [6.121274356078805, 46.31370764747048],
      [6.119369631480403, 46.29490719406557],
      [6.102638404712805, 46.28506396607944],
      [6.124247271884754, 46.25101201647218],
      [6.101761179926224, 46.23748787561317],
      [6.088183203547555, 46.24681497717057],
      [6.033127717153324, 46.23799614420531],
      [6.0025553835169445, 46.220910810001136],
      [5.978439780731028, 46.216958336715976],
      [5.963675623254598, 46.19696085882454],
      [5.992165784426486, 46.18661733755954],
      [5.956063159932633, 46.13208943594269],
      [5.985318105052286, 46.14330892189844],
      [6.045529778815728, 46.139907519306156],
      [6.052041801049772, 46.151402672290395],
      [6.092465663697645, 46.15175991441013],
      [6.126624627901115, 46.14046081078615],
      [6.175072377878081, 46.15814989960168],
      [6.185975362523726, 46.178176058241256],
      [6.235977428314406, 46.20654881404464],
      [6.277369983407964, 46.21561016581553],
      [6.310110099309942, 46.24370059763195],
      [6.307272725570715, 46.255436556730494],
      [6.267633620497312, 46.247839839653885],
      [6.237797813525851, 46.2773342585167],
      [6.246790968617458, 46.30206938671207],
      [6.277022020836744, 46.34891543898281],
      [6.303735764913311, 46.36625941824085],
      [6.3446765942166445, 46.370013560347886],
      [6.356956142429217, 46.35266345709143],
      [6.3900361566872315, 46.34016563406088],
      [6.411592280204707, 46.35839568319258],
      [6.468499886776638, 46.37242442527263],
      [6.482856165126304, 46.39192443764463],
      [6.513071711513262, 46.40478840307281],
      [6.545175304790926, 46.394727359364545],
      [6.588092394777118, 46.402317999643515],
      [6.635060415534961, 46.405770576746406],
      [6.722868985320351, 46.40755389780494],
      [6.789802754759155, 46.39291791721357],
      [6.806217638297463, 46.38037206259825],
      [6.770617080369467, 46.35489783286797],
      [6.784482072903341, 46.33270290819052],
      [6.820345178565179, 46.31437668901683],
      [6.829983705845801, 46.300043050711345],
      [6.864509851829647, 46.282990769883504],
      [6.853894399067287, 46.253761843433864],
      [6.839925738114553, 46.248239017516546],
      [6.80366083421475, 46.20434524954354],
      [6.81194009872822, 46.18337761562343],
      [6.792040848027208, 46.16290288539592],
      [6.796535034034799, 46.13861879772074],
      [6.814732162900611, 46.129696461365214],
      [6.898381393099358, 46.122562070394075],
      [6.883845122673671, 46.09579681990079],
      [6.8914542235903005, 46.08449157208838],
      [6.872633110865425, 46.05204817296112],
      [6.888329552883717, 46.043116914092494],
      [6.924513948824021, 46.065193804450395],
      [6.951511564566567, 46.04995609942393],
      [6.9846850080843375, 46.00654353554806],
      [7.005830810542592, 46.00054972582734],
      [7.022720712338816, 45.98029457159881],
      [7.017978490745711, 45.96034701964026],
      [7.03720423287911, 45.95472134951176],
      [7.043890107046439, 45.92361818207322],
      [7.020983385199974, 45.916194082670756],
      [7.0031519124087716, 45.89782921384603],
      [7.00536167353659, 45.88435235390808],
      [6.991719912014907, 45.86819987209143],
      [6.951037365261489, 45.85954131063136],
      [6.939604939153821, 45.8467321064224],
      [6.895100845805131, 45.84259632779018],
      [6.8736072885923845, 45.84549967424515],
      [6.818885778420616, 45.83620281158613],
      [6.803991136344079, 45.815853187486255],
      [6.812719976307191, 45.808021694582315],
      [6.802516663522652, 45.77837197402193],
      [6.808418511070923, 45.72515343860933],
      [6.847748586989986, 45.68937749599125],
      [6.906506784191486, 45.67456031820136],
      [6.902142336705646, 45.663739451343055],
      [6.934608837460737, 45.64709230917702],
      [6.966679928899032, 45.65406247996058],
      [7.000690534148291, 45.63990159437462],
      [6.98785713700158, 45.62484030507284],
      [6.977853982998437, 45.58988711838748],
      [6.995382953276822, 45.57564160818661],
      [6.9915063165642675, 45.531275223900984],
      [7.005311787943923, 45.51709673862955],
      [7.000331708478394, 45.504417196582274],
      [7.04452030151899, 45.497242723763186],
      [7.045847872850837, 45.478350392863504],
      [7.0997891354009095, 45.46943936219543],
      [7.113516455211971, 45.43419468733109],
      [7.152242832761182, 45.42319920873864],
      [7.1842747748936695, 45.407480631519924],
      [7.165149366142358, 45.383396585908265],
      [7.161662218042757, 45.36245350123881],
      [7.137734008933981, 45.35080736839848],
      [7.134719719291083, 45.33124412917526],
      [7.113617028522963, 45.32868608867201],
      [7.121796569398171, 45.296222432254126],
      [7.136428084679232, 45.28079531076303],
      [7.137753498643515, 45.25686099638962],
      [7.106506545205386, 45.239259844510855],
      [7.067342157688226, 45.21008321740584],
      [7.051179482821824, 45.225331224056454],
      [7.019992318063418, 45.21587387777123],
      [6.965752237821794, 45.20795099681965],
      [6.954190330678925, 45.17961083467822],
      [6.930266678221192, 45.17096314110703],
      [6.895349577041668, 45.16846478638608],
      [6.884108906250159, 45.15674060434921],
      [6.89437780212641, 45.13736971507668],
      [6.847887829330167, 45.127201835901765],
      [6.812274904701698, 45.14835683817089],
      [6.767947891773147, 45.15974332272096],
      [6.738956139264719, 45.13683144319859],
      [6.711286195179388, 45.14475992733128],
      [6.680067352474279, 45.14012497136189],
      [6.672462233861758, 45.124412299122746],
      [6.6299873381374725, 45.1093269281999],
      [6.627487761756953, 45.10121505030452],
      [6.662113977091666, 45.07163521564911],
      [6.658833037991113, 45.0522106577603],
      [6.672439472064712, 45.02142288028361],
      [6.7256836969428715, 45.02150040053337],
      [6.743421512935462, 45.015920279449546],
      [6.751188313816809, 44.99755867124376],
      [6.7376342538806036, 44.99148903315492],
      [6.763468605707418, 44.971219657081626],
      [6.753079854453953, 44.94296106206407],
      [6.750709573838057, 44.90565221939063],
      [6.771184461028274, 44.90344432994035],
      [6.805918328309796, 44.87664147794964],
      [6.863241048677118, 44.850610814016996],
      [6.913770297405167, 44.84542283675211],
      [6.933687243037528, 44.862026072008646],
      [6.972609983994274, 44.84623344849374],
      [7.0067737788907705, 44.839315967633134],
      [7.018283625506064, 44.81230540654565],
      [6.9995712397492555, 44.78944471324736],
      [7.024748601836657, 44.76238093071902],
      [7.024189433792721, 44.74131576508321],
      [7.043683920015221, 44.71814050323442],
      [7.06578600052752, 44.71360743394211],
      [7.077108800206922, 44.68091750917342],
      [7.059729404484276, 44.67996132818681],
      [7.023734517020338, 44.6913011223226],
      [6.98622673933706, 44.68808002657876],
      [6.957471911814601, 44.66982246847716],
      [6.948335091404861, 44.65481629914676],
      [6.962336081450481, 44.62657915489668],
      [6.948379982167533, 44.61745199059853],
      [6.932733186254122, 44.59270863771759],
      [6.9319953299582595, 44.572724638567955],
      [6.913213082531437, 44.558826349687266],
      [6.8760332305264455, 44.550160873365996],
      [6.854013487274841, 44.529129653944196],
      [6.865674424523799, 44.4950039431701],
      [6.881970580210891, 44.477261501575526],
      [6.906339114831614, 44.46684649800382],
      [6.911101127447187, 44.45236482780562],
      [6.937139400716047, 44.43887286300481],
      [6.937079169120725, 44.42969968048132],
      [6.892869620487909, 44.42078067903776],
      [6.896507825430464, 44.374299547914674],
      [6.88742990090296, 44.36104783803612],
      [6.922569034715827, 44.35073334653868],
      [6.92647211225925, 44.334749781019966],
      [6.960525832743343, 44.31085198397747],
      [6.955859859005885, 44.2970594542236],
      [6.9934468208164935, 44.280891427852495],
      [6.99712995413101, 44.251997804252646],
      [7.008054894297459, 44.236431060527],
      [7.038841660238861, 44.22372981024312],
      [7.070224944864906, 44.23297997575481],
      [7.112226619139014, 44.21733524158766],
      [7.141050183667786, 44.2010003780328],
      [7.1597495198014025, 44.20620545858123],
      [7.2033866897276635, 44.184792768493224],
      [7.218868901675846, 44.1689469810721],
      [7.237041234869469, 44.174339231954],
      [7.24856917472652, 44.158515503110245],
      [7.280317813440188, 44.141181126207165],
      [7.340417586180365, 44.145649028908274],
      [7.358475305086881, 44.11815061891558],
      [7.388931942608855, 44.12479464638471],
      [7.4268012934594045, 44.117564036132904],
      [7.429927081340899, 44.127804524896916],
      [7.459974202378207, 44.125692456404565],
      [7.505741191835109, 44.143652395337554],
      [7.5567970945239376, 44.14692262748108],
      [7.577148887227901, 44.15289005329537],
      [7.614555274657836, 44.14967082474187],
      [7.645064338759674, 44.17790074590103],
      [7.68458536705161, 44.17401845696774],
      [7.670852285754872, 44.15374050304116],
      [7.667154197554274, 44.13343096570642],
      [7.67452067122969, 44.117978351966265],
      [7.715741685167259, 44.082768394074186],
      [7.714078792245614, 44.06542948835954],
      [7.700025563701426, 44.04078903341254],
      [7.664986572845552, 44.03066821354587],
      [7.670204633842334, 43.998475578585406],
      [7.652699330334664, 43.97484454446134],
      [7.610194650987026, 43.95513695471592],
      [7.596854299346449, 43.95652880599456],
      [7.572900090571807, 43.9377923733289],
      [7.559929417388958, 43.914446448952816],
      [7.559218845189717, 43.897294632318086],
      [7.511282181839372, 43.88226017750882],
      [7.495202515535623, 43.862291505445505],
      [7.518300877134688, 43.80329700642123],
      [7.530159376168468, 43.78804906400435],
      [7.490212035782315, 43.767197668733566],
      [7.475918505385493, 43.750796761738144],
      [7.4597300454166495, 43.76004177050826],
      [7.412274588246639, 43.7343884782676],
      [7.413235194461564, 43.72476923386886],
      [7.375064708715003, 43.71753336634211],
      [7.360727399718111, 43.72228177889476],
      [7.337596357798404, 43.711716462798286],
      [7.326468236003214, 43.69615480753041],
      [7.297211876953826, 43.68614078433695],
      [7.285170356707785, 43.694320925627316],
      [7.24382000023942, 43.68938268481802],
      [7.225884891654834, 43.66225535365977],
      [7.206455679516468, 43.64563646874049],
      [7.194537177820155, 43.657919305629925],
      [7.157853271963104, 43.65385083741795],
      [7.139271239473155, 43.6360982414893],
      [7.1230039862754415, 43.58829721341579],
      [7.121751847241626, 43.55949556145099],
      [7.101266887315347, 43.57067799664823],
      [7.0675662048264405, 43.561639844055605],
      [7.055944916816456, 43.54796646787975],
      [7.014827677611477, 43.550856545562084],
      [6.974303482945652, 43.546078781561825],
      [6.952169379017137, 43.53471699106892],
      [6.938368675282269, 43.51639156043654],
      [6.954746280767227, 43.50472407547113],
      [6.947072439425319, 43.48436595161653],
      [6.933726236453417, 43.480068010885034],
      [6.922885490828145, 43.45174387541975],
      [6.905141154857869, 43.44535017666573],
      [6.891803829527237, 43.428580913728375],
      [6.857004426913538, 43.430474860535256],
      [6.858844544234777, 43.414799720326116],
      [6.826767053167243, 43.41737073152947],
      [6.782764730332782, 43.409442320887486],
      [6.766270052682748, 43.42375280812916],
      [6.733704630154437, 43.40559793735444],
      [6.732003206721381, 43.38946313855496],
      [6.714335229285594, 43.36790695222178],
      [6.7099565741085065, 43.344596635575606],
      [6.6800297271705, 43.340297041676884],
      [6.665952517510404, 43.3182163655059],
      [6.583869079366629, 43.27730467434808],
      [6.58599194489557, 43.264474487305584],
      [6.6206975135955615, 43.26419875103567],
      [6.639850874222042, 43.27423471176637],
      [6.662119995811744, 43.26490893551231],
      [6.677311623765608, 43.278791964313584],
      [6.697137390126857, 43.26639912327035],
      [6.690285120903589, 43.25444947454634],
      [6.663579906038083, 43.23804248633585],
      [6.6697773834987, 43.20593681234112],
      [6.6424608658713495, 43.185044777871894],
      [6.620225523392656, 43.16070781744534],
      [6.591926740934735, 43.18547932186572],
      [6.556403062532188, 43.188079368186806],
      [6.495595304956187, 43.15114513174647],
      [6.459406427232955, 43.155878063276724],
      [6.4051625531535405, 43.14899847503375],
      [6.368717778683196, 43.13697275788714],
      [6.359759637416901, 43.119904470727896],
      [6.365822239355368, 43.08894493070752],
      [6.332512727498749, 43.0910972881653],
      [6.316672182510005, 43.106169850809124],
      [6.274938809272962, 43.12074293872334],
      [6.24125248602693, 43.11390300424902],
      [6.20808289628789, 43.11644424038581],
      [6.1802726418863445, 43.10699799522913],
      [6.1604467571254595, 43.08929067476381],
      [6.15001613157217, 43.061260557512185],
      [6.157072005051721, 43.0283622233928],
      [6.116717634954243, 43.03924175451086],
      [6.131609970477635, 43.04800821003079],
      [6.125902358634685, 43.07753538311637],
      [6.076465121556624, 43.08694321993878],
      [6.031440047836645, 43.0774733246304],
      [6.0234687473310835, 43.09480782262938],
      [6.006259832558937, 43.103984285718425],
      [5.940943551094233, 43.10775854379546],
      [5.9206305576319345, 43.12390905489088],
      [5.899891552467621, 43.11317366571534],
      [5.916526116114346, 43.08566056570662],
      [5.871977255950896, 43.06810192056182],
      [5.8574626666009255, 43.0466186792423],
      [5.828809918694244, 43.04936457422897],
      [5.804564531287972, 43.078284936751075],
      [5.813898362790477, 43.107129005014066],
      [5.773153880321081, 43.117608445363594],
      [5.781006228280267, 43.13093301699203],
      [5.725850946491633, 43.13652712230804],
      [5.719019910025584, 43.14727450998757],
      [5.694880043308294, 43.14358778681909],
      [5.671875005699277, 43.179268786904935],
      [5.6525556384849125, 43.187314568389844],
      [5.624165815055896, 43.18698295242608],
      [5.600902180940571, 43.16254533545738],
      [5.569123854119204, 43.17529448351615],
      [5.531324017717664, 43.21204241023737],
      [5.510066083294413, 43.19768970206163],
      [5.454401745873978, 43.21064244401662],
      [5.391051995321294, 43.21212758682465],
      [5.363644360087066, 43.20712491164297],
      [5.340870058926049, 43.21464053757704],
      [5.348353034439003, 43.22982142946681],
      [5.372523588800765, 43.24507767770401],
      [5.366241273279859, 43.269297926805216],
      [5.345688435376646, 43.282363417695436],
      [5.364413487886784, 43.312140196877614],
      [5.323859848409952, 43.356938601132626],
      [5.296471721324536, 43.35955741603999],
      [5.2562520205440935, 43.33727449020553],
      [5.221336413370384, 43.32835884872525],
      [5.17747685717399, 43.333615576923606],
      [5.168325223906977, 43.32794092993499],
      [5.037907932778403, 43.32902848885288],
      [5.019042287817556, 43.34294363712429],
      [5.0242941885301615, 43.355561006629124],
      [4.975887748495165, 43.40225192419132],
      [4.967705599857734, 43.42610491918481],
      [4.931381887869524, 43.43315714427828],
      [4.906351069342989, 43.41974182683961],
      [4.864258578677964, 43.40622019800946],
      [4.848994405645509, 43.374446367565824],
      [4.874828065516188, 43.36049493579003],
      [4.855048529182453, 43.3326166544088],
      [4.833031594688433, 43.32986964122808],
      [4.783363102701897, 43.347193829339595],
      [4.717738680923241, 43.3503073199876],
      [4.661783095147221, 43.34641234548359],
      [4.585804835779633, 43.36006877534866],
      [4.562800334318894, 43.37213670596142],
      [4.557428450695051, 43.38832049746897],
      [4.587701900856666, 43.40071206710059],
      [4.582043368583085, 43.43056112720855],
      [4.562101794245088, 43.4432502407157],
      [4.51635241260241, 43.45471611364518],
      [4.465265329292432, 43.45715551018595],
      [4.402200576777784, 43.4472639920423],
      [4.382042207083344, 43.452273928749435],
      [4.230283393392478, 43.46018543272639],
      [4.165622110206285, 43.47191333107422],
      [4.12719685937579, 43.48945145875348],
      [4.116910879157792, 43.508975353807415],
      [4.13888058730906, 43.53212392513865],
      [4.1010416754063925, 43.55437094640862],
      [4.056139135742867, 43.55754349700192],
      [4.011185334395634, 43.552446652511314],
      [3.9685779965496257, 43.54002202677775],
      [3.9071342421308484, 43.51684377775197],
      [3.85119536986901, 43.486462477159925],
      [3.796663802013001, 43.44103590991042],
      [3.724916367109535, 43.41579045126172],
      [3.725633295848447, 43.40119286766748],
      [3.692847761038597, 43.39286075111627],
      [3.66215922818193, 43.392279064377114],
      [3.6026190412473764, 43.35542655651462],
      [3.5582388767895456, 43.31887037271225],
      [3.509760183543765, 43.27194467481957],
      [3.467228588445924, 43.276787686264214],
      [3.4303316940306616, 43.29020953643601],
      [3.3869064784452894, 43.28413968534662],
      [3.343863387032852, 43.27039600610152],
      [3.263447246832585, 43.228865527136406],
      [3.24056113717041, 43.21280863308409],
      [3.177252971331801, 43.1653931155685],
      [3.140803804618427, 43.12876695257695],
      [3.092876973759121, 43.06914228496683],
      [3.074043532794371, 43.03811903535086],
      [3.0428431893335772, 42.96015378799919],
      [3.0404710136346944, 42.929704326912585],
      [3.060589297455898, 42.9170599049451],
      [3.043510805290506, 42.8381501142638],
      [3.039269430432411, 42.799791836114],
      [3.03866210585288, 42.73115943015552],
      [3.0354222951214984, 42.678248196502366],
      [3.037082831998312, 42.62608415053007],
      [3.0451908300933015, 42.59457922768011],
      [3.0496064216977565, 42.55014015956973],
      [3.0571198441096596, 42.5372092844744],
      [3.0868940108835354, 42.524055488048134],
      [3.1056245890262795, 42.525593780023925],
      [3.138121628181151, 42.51489518727693],
      [3.1229727483854868, 42.50504339919354],
      [3.1331116175574936, 42.48125477575951],
      [3.1530067352162088, 42.477776284883916],
      [3.15732898680088, 42.459432454750015],
      [3.1741805499682005, 42.435585934319825],
      [3.120829508201149, 42.43800580748714],
      [3.085437328583658, 42.42550988123317],
      [3.0490406236305536, 42.45554617467689],
      [3.0407621096853417, 42.47314100863664],
      [3.0125781244014846, 42.46652317375274],
      [2.988889553374083, 42.4738313841575],
      [2.9689921541918216, 42.46579130273507],
      [2.9470269070718653, 42.481801093577864],
      [2.924481044394249, 42.458389170964374],
      [2.863516909035993, 42.463685965835424],
      [2.8414109625242325, 42.45852270899326],
      [2.814839563839402, 42.43879437507126],
      [2.799242281304166, 42.41856867596309],
      [2.771995685896969, 42.412356726628104],
      [2.7532002138160787, 42.42535725427018],
      [2.7163026018456495, 42.420861005709845],
      [2.6737509151182475, 42.40452391803864],
      [2.6556410417562404, 42.38843162789706],
      [2.6717654689105332, 42.34117820769783],
      [2.611297522207988, 42.346807721180475],
      [2.5778245531567956, 42.357933234601866],
      [2.5545994607392077, 42.353892840228475],
      [2.540046438348588, 42.33376082281662],
      [2.5003079444422434, 42.342911142856316],
      [2.4829628754848416, 42.33964655377135],
      [2.4675348643249904, 42.35892180847505],
      [2.4334621567522774, 42.37709101786132],
      [2.435482130866189, 42.38887656698712],
      [2.3491816579437708, 42.40674798977397],
      [2.3069539494770965, 42.42879201328318],
      [2.2923910299124817, 42.422979357750634],
      [2.2567190982902723, 42.43844036451443],
      [2.2466766500833426, 42.429497661909714],
      [2.201063478390105, 42.41629324758647],
      [2.1563417567377465, 42.42342744508062],
      [2.128866046268498, 42.4124914459525],
      [2.1151215213189642, 42.38204970639233],
      [2.08954757389964, 42.37371732752677],
      [2.0835981014554434, 42.36270013981574],
      [2.02349649688789, 42.35522663210627],
      [1.9859066164117019, 42.36204816679774],
      [1.9605413830047367, 42.39193435465887],
      [1.9582842085598802, 42.42404869009333],
      [1.9417298315540916, 42.42966577271863],
      [1.9353121382830816, 42.45358596021788],
      [1.9166429808948604, 42.44630507104221],
      [1.8854520225275206, 42.44929175065031],
      [1.8814289746097121, 42.459713683305495],
      [1.8498223891207548, 42.46731551034911],
      [1.8433623972460949, 42.477143601221265],
      [1.8030674804478923, 42.48955493039855],
      [1.7632574952590807, 42.48684429750053],
      [1.7312197831734901, 42.49274331055303],
      [1.7262968840513657, 42.51739946334924],
      [1.735934655538207, 42.55051896327486],
      [1.7548048574820891, 42.56487435248866],
      [1.7861250110638969, 42.57362343207788],
      [1.773155041578549, 42.58067708426063],
      [1.7259206571767567, 42.59036520824838],
      [1.737964425021432, 42.61131401180095],
      [1.6835991033030946, 42.624864963586695],
      [1.6620254880346446, 42.61948091899569],
      [1.637155477178948, 42.63029849282105],
      [1.6063547204789832, 42.62745569822105],
      [1.58567689224097, 42.633568385987964],
      [1.5623889852861503, 42.653480869601616],
      [1.5492483438086209, 42.655776285849846],
      [1.5014121994747076, 42.64516242342437],
      [1.4801044915371044, 42.6513900879463],
      [1.4694745196425487, 42.62847928225725],
      [1.4772675936643833, 42.614967659090894],
      [1.4557081666267313, 42.60205166220692],
      [1.4401504415315978, 42.60350721030018],
      [1.4201858337826103, 42.62607181448239],
      [1.4139966708290406, 42.65484551119558],
      [1.38773471434658, 42.67099021370737],
      [1.389599626449476, 42.68508287715744],
      [1.354404547925789, 42.699493649410854],
      [1.3579397808759273, 42.71551055541734],
      [1.32502612790918, 42.72390397060219],
      [1.2778256065536044, 42.71387414262699],
      [1.2525274855971043, 42.71564959267591],
      [1.2283255116895055, 42.727437974142234],
      [1.2171573639899749, 42.7204805063676],
      [1.1618481533807585, 42.711046329845956],
      [1.1333079622063968, 42.729016535435846],
      [1.1280806814053455, 42.75537633903151],
      [1.1069774050999934, 42.772292244775535],
      [1.0706247661216861, 42.78247308343543],
      [0.9831091862639382, 42.787068192050434],
      [0.9598896789755389, 42.805643801609044],
      [0.9235668371314283, 42.7903836690362],
      [0.8583056667230173, 42.82571908908446],
      [0.8014829737147933, 42.840482516254525],
      [0.786497752721584, 42.835955365811344],
      [0.7377189409040268, 42.84872835147501],
      [0.708377779630982, 42.86140184526933],
      [0.6590147498491514, 42.838529465460994],
      [0.6698627393614355, 42.82438101654399],
      [0.6705882589690579, 42.80475875717359],
      [0.6445405045620645, 42.78307646581688],
      [0.6503239309882353, 42.76416869986073],
      [0.6822635276176596, 42.70896825363519],
      [0.6705736143224804, 42.68989711626274],
      [0.607646803436206, 42.69905996655662],
      [0.5868270391623184, 42.69500344995768],
      [0.5296137950834886, 42.702684358976896],
      [0.5185245887513461, 42.691876177213395],
      [0.47774954032737066, 42.69999034279792],
      [0.4356455710506602, 42.69037183158463],
      [0.40211897973801236, 42.69648986021392],
      [0.39263011986752094, 42.71311750189731],
      [0.3596287954835796, 42.72339183232152],
      [0.3262254490865479, 42.70523591329384],
      [0.320802303741389, 42.683131711219026],
      [0.29282224858132516, 42.67492458180092],
      [0.2657494410548165, 42.69585404878972],
      [0.2601414748532607, 42.715502851551555],
      [0.22668497017769476, 42.71735823262267],
      [0.2059924149934227, 42.729298724675765],
      [0.17574306986977128, 42.73677329861877],
      [0.1604176644184873, 42.72442882623834],
      [0.1365670029696411, 42.72232698158292],
      [0.11125032280716181, 42.71021137331376],
      [0.09042040053997723, 42.717132811761196],
      [0.0459533905361909, 42.696777408747096],
      [0.024585610304963493, 42.70245653014717],
      [-0.010637584646291413, 42.684385085093155],
      [-0.05980922099813103, 42.69342720546847],
      [-0.06884483739406957, 42.7179035165553],
      [-0.10244023343942496, 42.72158700465701],
      [-0.15972871920981477, 42.79739905213244],
      [-0.1784879363541327, 42.785361494949754],
      [-0.23844023717848478, 42.808170502420666],
      [-0.27668498267988956, 42.835474417476526],
      [-0.3134428634980887, 42.84937505970757],
      [-0.32147747742734595, 42.837156313582035],
      [-0.34754008403707837, 42.8359138333211],
      [-0.3679794649305534, 42.81384420795455],
      [-0.3925972910093655, 42.79955853388815],
      [-0.40923534884558577, 42.80775176126848],
      [-0.4443073733042952, 42.79644188968569],
      [-0.509645724461578, 42.825392555785875],
      [-0.5248214033720447, 42.81169922814938],
      [-0.5228809859764464, 42.799053820578806],
      [-0.5438135992737796, 42.79316107597114],
      [-0.5512049085825593, 42.77751593074885],
      [-0.570596979524969, 42.782910869511355],
      [-0.56886355590546, 42.806821287959146],
      [-0.5927365436827333, 42.80323323591071],
      [-0.6036291574211501, 42.83261701820438],
      [-0.6488576242634109, 42.85520307403128],
      [-0.6786504294307736, 42.88435162671404],
      [-0.6989906898468694, 42.87965527922473],
      [-0.7252987547541817, 42.891321607770166],
      [-0.7349914574877744, 42.91217714898651],
      [-0.7250694205419334, 42.92302821420027],
      [-0.751639105301494, 42.966938899771456],
      [-0.7878117200655661, 42.964220133379236],
      [-0.8100187374291915, 42.95143307665448],
      [-0.8650339469969248, 42.950782024634954],
      [-0.8997253347034583, 42.961944671110295],
      [-0.9464504061324416, 42.95406127408886],
      [-1.0005051395543532, 42.97795014220868],
      [-1.0064443208237912, 42.988993997889715],
      [-1.0830603615120118, 43.00168071076038],
      [-1.1130329544941873, 43.02237691659177],
      [-1.1334470820212093, 43.01037270050248],
      [-1.1668982697369847, 43.03557738093639],
      [-1.1807234359906535, 43.03252804425024],
      [-1.2286741205591398, 43.055160334686654],
      [-1.2471934082929712, 43.042415611440845],
      [-1.2642856059829322, 43.044567445791834],
      [-1.2835261465024137, 43.06128566378849],
      [-1.308546707643754, 43.06868136866409],
      [-1.2986667955038385, 43.093279481535944],
      [-1.2802211653973743, 43.1182176727211],
      [-1.3220188073653838, 43.112341489609655],
      [-1.3461709053432147, 43.091146616577],
      [-1.3409221159462987, 43.08017084901461],
      [-1.3547480564678596, 43.02846729741783],
      [-1.441215102138327, 43.04630623466782],
      [-1.471735353863028, 43.08109307328759],
      [-1.4708729299201966, 43.09171395485682],
      [-1.415487839708494, 43.12745746864605],
      [-1.416022603215192, 43.1497408035206],
      [-1.4034369386574947, 43.15976006228705],
      [-1.4020241459530187, 43.17787126578672],
      [-1.3844898703699202, 43.19125222875264],
      [-1.3826552191243804, 43.25302936103839],
      [-1.4132056044596777, 43.273416064289286],
      [-1.4388514488237716, 43.2665172949958],
      [-1.4954242196638288, 43.28272765553131],
      [-1.5053245319871684, 43.29278486875202],
      [-1.5647156489015603, 43.28791168353549],
      [-1.5581480152327398, 43.276925958606746],
      [-1.577703455461285, 43.25064368535375],
      [-1.6086713312434922, 43.251911493906945],
      [-1.6303674039621392, 43.28444921654921],
      [-1.6225991787656453, 43.30062353105162],
      [-1.6671377641602614, 43.31445979762466],
      [-1.694148056872775, 43.31228000342086],
      [-1.7297463174854624, 43.295677171644435],
      [-1.7577102787128112, 43.344006413921115],
      [-1.7871316386117875, 43.35136807471246],
      [-1.7889705724558977, 43.3740616062586],
      [-1.7620152282748711, 43.37589213130254],
      [-1.6391097857903005, 43.40836090393235],
      [-1.6014509805064314, 43.433291711283985],
      [-1.5629953964271497, 43.484727946611734],
      [-1.5480683309573409, 43.49597169694441],
      [-1.5248665484221002, 43.52970134669009],
      [-1.491923614747195, 43.57231885753228],
      [-1.4600195931351083, 43.6202992244984],
      [-1.4424122520261093, 43.664405589349386],
      [-1.4365038283323686, 43.710565130021784],
      [-1.4102258105768295, 43.80324747446864],
      [-1.375345240406389, 43.91228735051265],
      [-1.3376743348128222, 44.05415734628098],
      [-1.3159842928578236, 44.130037493905746],
      [-1.2775403203119533, 44.31923983571922],
      [-1.2538901028077145, 44.4676042123461],
      [-1.2517315717900113, 44.514864026059136],
      [-1.2586140715490404, 44.547134908509065],
      [-1.227400657614334, 44.57575101363341],
      [-1.2035061581038056, 44.62271377486185],
      [-1.2041641060667378, 44.63998931395565],
      [-1.1915395677551643, 44.660726555771724],
      [-1.1617602687791189, 44.66337536905334],
      [-1.1407652758832603, 44.64722441419704],
      [-1.080794255404563, 44.64060270635637],
      [-1.0660081141503954, 44.646890088823476],
      [-1.0167859243981427, 44.64936614924531],
      [-1.024772192870524, 44.67594768817969],
      [-1.056012096331499, 44.707980341287296],
      [-1.1066690697160937, 44.74264984128815],
      [-1.1172415691037478, 44.74399705140438],
      [-1.165808013375802, 44.77527626694913],
      [-1.1800101830102927, 44.758098157761154],
      [-1.1738129355689804, 44.745358451222856],
      [-1.2205928890641693, 44.709423690526556],
      [-1.2378317329560085, 44.680472950808145],
      [-1.2462033009619466, 44.641861781385515],
      [-1.262052894231634, 44.63219970212472],
      [-1.251726794177239, 44.71201817211294],
      [-1.2245842811118994, 44.853825371133354],
      [-1.2041211009419968, 44.989784597043446],
      [-1.1902342986818488, 45.1076052722213],
      [-1.1623411294779713, 45.297866853836986],
      [-1.1588809392970065, 45.35758854145279],
      [-1.1605511012170588, 45.41069085511738],
      [-1.1510569561631872, 45.44160185108563],
      [-1.157040252187612, 45.47037006217617],
      [-1.13642305844502, 45.510996841105246],
      [-1.100291537957874, 45.54164579436281],
      [-1.0911993078778204, 45.56240962499771],
      [-1.0608731648946181, 45.559687159252995],
      [-1.0399629327796032, 45.53624996681034],
      [-1.0394312457678239, 45.51187047075673],
      [-1.0052560088445266, 45.49288102324259],
      [-0.9741453673216026, 45.46337798565173],
      [-0.9339187159877002, 45.44354638799416],
      [-0.8975412931164795, 45.413920961435664],
      [-0.8380858049594333, 45.37799277602822],
      [-0.803863792011378, 45.3483837452359],
      [-0.7677088201843572, 45.289668398897916],
      [-0.7511045662834447, 45.248111104699966],
      [-0.7415941186185122, 45.18951228689129],
      [-0.6827151048736609, 45.07868957712152],
      [-0.6531808034769909, 45.04997165854475],
      [-0.6085452595854771, 45.01540165572232],
      [-0.5906665446401641, 45.041946989301174],
      [-0.635943850069436, 45.074304489771464],
      [-0.6522025754037655, 45.10762754422008],
      [-0.6677719424227322, 45.12666694807424],
      [-0.6699486553314091, 45.16929377695903],
      [-0.6882053149295403, 45.21937742756459],
      [-0.7037353439558368, 45.283639400976455],
      [-0.7188015186962935, 45.327417794908314],
      [-0.7343347321102192, 45.36884494436805],
      [-0.7562352245467074, 45.40604611670738],
      [-0.80169109465095, 45.45953126528613],
      [-0.8475465516286703, 45.498824518200884],
      [-0.932316123312143, 45.54789787336011],
      [-0.9628798253628926, 45.556849245805736],
      [-0.9930029744215878, 45.577177846519746],
      [-0.9936529509869637, 45.59759467612667],
      [-1.0096306936697144, 45.61143397047428],
      [-1.048858991717686, 45.62029782357388],
      [-1.0793992219196582, 45.63891363152395],
      [-1.115849451052298, 45.64698132790749],
      [-1.1572864520673638, 45.67180600119212],
      [-1.2093191311554552, 45.69669910148998],
      [-1.2372284214926077, 45.705896325440406],
      [-1.242573859956351, 45.78157155553533],
      [-1.2089596448071276, 45.79575377598853],
      [-1.1919992992402317, 45.789369778069705],
      [-1.1320926009958303, 45.80492633100206],
      [-1.1567411951530595, 45.843048006693174],
      [-1.1531897443954855, 45.862358720066815],
      [-1.12508329313857, 45.857239254856815],
      [-1.1008903749589125, 45.8722142424053],
      [-1.073790649268033, 45.91423100527249],
      [-1.076862108697286, 45.93669868392043],
      [-1.0643196475545222, 45.94990908084704],
      [-1.0989490769387045, 45.9905075513321],
      [-1.063082715866709, 45.994433695794925],
      [-1.0526867952056995, 46.01118088641005],
      [-1.0659492083794007, 46.04982057557596],
      [-1.0888412421853944, 46.05394710352535],
      [-1.1007267291374074, 46.0937967969596],
      [-1.115052359847052, 46.102322009862235],
      [-1.1291048559783758, 46.12696926808778],
      [-1.172930125682221, 46.139288837035025],
      [-1.17838828047269, 46.15320939498865],
      [-1.2069336546191891, 46.14576152143465],
      [-1.2238754783330186, 46.16594526939377],
      [-1.1993266738882897, 46.19432347775986],
      [-1.1989980101944606, 46.21324548926301],
      [-1.1713483210745752, 46.224056686747986],
      [-1.142844564296712, 46.24398123047849],
      [-1.1403935362784305, 46.25401646783765],
      [-1.1111638836886615, 46.26134367831711],
      [-1.129404177492153, 46.31027691915601],
      [-1.1671810169437413, 46.31969405036838],
      [-1.2021666067108026, 46.31599153007532],
      [-1.1955103805422935, 46.30048349541652],
      [-1.2063765625294878, 46.288846571626785],
      [-1.2073944924206363, 46.266571614816904],
      [-1.281772839130423, 46.309968266424384],
      [-1.3444380346892142, 46.341953842181326],
      [-1.3667425638808843, 46.34861328663727],
      [-1.4009792622186368, 46.34050457904003],
      [-1.4284190944836321, 46.34712502912173],
      [-1.4659415635018052, 46.34235901019938],
      [-1.4877969769705215, 46.38139204154906],
      [-1.5021454225847812, 46.39734952406836],
      [-1.5340123722474628, 46.40887626089284],
      [-1.5490765402226871, 46.40548020561594],
      [-1.6113040094680402, 46.41341659286108],
      [-1.7082937557327667, 46.450884792059824],
      [-1.7558962800631415, 46.474872816955255],
      [-1.777141798763278, 46.49275846362317],
      [-1.8136695999589263, 46.49534718792214],
      [-1.8213149950439371, 46.523721393572785],
      [-1.8560176099926675, 46.608577928185206],
      [-1.8906746733506474, 46.634689983740806],
      [-1.9408440446802884, 46.69106965939676],
      [-1.96540793995167, 46.69200185704432],
      [-1.9826664543508177, 46.72031292149719],
      [-2.0719487980113698, 46.782813819976845],
      [-2.1410311606229655, 46.81774558883561],
      [-2.1550297319780927, 46.88965414468634],
      [-2.122239576466256, 46.891263453405536],
      [-2.103448154684842, 46.92098874969161],
      [-2.0582281140235676, 46.950944454989695],
      [-2.0293326201395745, 47.0086320208736],
      [-1.980414486742876, 47.02890649407564],
      [-2.0047172984420274, 47.06145903008165],
      [-2.032603631969014, 47.07351687202541],
      [-2.0534725301465024, 47.09412159709027],
      [-2.114087677200522, 47.10989368059357],
      [-2.1555864883203406, 47.11283045616817],
      [-2.1776732744717013, 47.1220648516062],
      [-2.214466888230872, 47.123901610790035],
      [-2.226823218085358, 47.13094036238744],
      [-2.226132030050598, 47.15227424383228],
      [-2.167064477781809, 47.16618049901811],
      [-2.1583713304619327, 47.20905347867946],
      [-2.1805520828108604, 47.23299057764472],
      [-2.1705404081241095, 47.23975355445233],
      [-2.169993937357407, 47.26847661211524],
      [-2.1873645299124624, 47.28061948952369],
      [-2.245373274313404, 47.25599310446833],
      [-2.2698943084243393, 47.23956836515538],
      [-2.301643363345213, 47.23640440229174],
      [-2.339599042924339, 47.25518767687217],
      [-2.3697489457624465, 47.27743476237253],
      [-2.398380900159916, 47.28144600126307],
      [-2.441973190559724, 47.26058258477878],
      [-2.4555196699772877, 47.26813905362376],
      [-2.513904074004327, 47.284623375273924],
      [-2.502914104344962, 47.328751209072664],
      [-2.5211551419585674, 47.35881029297989],
      [-2.5589409930083042, 47.374567573551786],
      [-2.5341025675524826, 47.38296506334218],
      [-2.483893499163002, 47.412419341696214],
      [-2.4508581636576396, 47.425325950967114],
      [-2.45848916517333, 47.44812172071826],
      [-2.4794720164550967, 47.44310980823914],
      [-2.498735357450224, 47.45724235463569],
      [-2.489565024970293, 47.47431520811904],
      [-2.5015179310929967, 47.489586634131676],
      [-2.4412239148217303, 47.49623958282475],
      [-2.466145152823908, 47.51170094995204],
      [-2.5177254508541034, 47.52638405379225],
      [-2.543943614544709, 47.52304464159969],
      [-2.5528199043026465, 47.51257795028038],
      [-2.6035175490392004, 47.51629291634741],
      [-2.603686239678167, 47.53097620577883],
      [-2.652315813187561, 47.53948128320896],
      [-2.6518523877085074, 47.52918871697427],
      [-2.681267629896647, 47.49582645624765],
      [-2.7155074792326994, 47.50555679146207],
      [-2.783910628164775, 47.49416982507097],
      [-2.7953631847353706, 47.48606302718516],
      [-2.8494662625405485, 47.498716770371836],
      [-2.8475718355405935, 47.512071687742385],
      [-2.8752313484549616, 47.534363529261185],
      [-2.9132300200024543, 47.543009316861514],
      [-2.9093071370148396, 47.55995295111369],
      [-2.882650360709671, 47.56273792316479],
      [-2.8621207995589244, 47.54108127622838],
      [-2.8188916250932614, 47.547311960718446],
      [-2.8053190952421017, 47.55465057729475],
      [-2.762369364633524, 47.53644978740993],
      [-2.7342064067723255, 47.55206430843042],
      [-2.7154316604959736, 47.57654253087959],
      [-2.714432950008992, 47.59298967346035],
      [-2.757592183654573, 47.61287735101191],
      [-2.7545302536260285, 47.635943017790716],
      [-2.7951916631184384, 47.619515983940175],
      [-2.8312692184373036, 47.613980283771895],
      [-2.851150732135118, 47.619022264840936],
      [-2.8883074806182694, 47.60372031432131],
      [-2.8941178097199294, 47.58191685891699],
      [-2.9370470028114317, 47.59651736500671],
      [-2.950659153900295, 47.579303860754656],
      [-3.0046859134608477, 47.56646553748797],
      [-3.026468461710979, 47.58394599905037],
      [-3.094879923920414, 47.564686311749014],
      [-3.1232589863704034, 47.5695081568697],
      [-3.1332300779049587, 47.532435621621886],
      [-3.1169009752940933, 47.4961604922533],
      [-3.09449650710579, 47.482412765246636],
      [-3.129391708495516, 47.4738244656134],
      [-3.1517748821158404, 47.50387332474793],
      [-3.152063868227419, 47.52789301771813],
      [-3.1345805586210473, 47.54882366707632],
      [-3.139749279889078, 47.57960047643262],
      [-3.1583771335933584, 47.6069849085308],
      [-3.1937321982478797, 47.62194072617485],
      [-3.2097530809913435, 47.640690040350975],
      [-3.2081288735823428, 47.66342980157541],
      [-3.190504238501219, 47.68254092926842],
      [-3.166595512762787, 47.6804302446036],
      [-3.128059188762987, 47.70555367694903],
      [-3.1259616002794033, 47.72345749155855],
      [-3.159418178564092, 47.737388379799775],
      [-3.1763421131463927, 47.734689167234386],
      [-3.1737051439382005, 47.701956980943464],
      [-3.217549801386117, 47.66730699988139],
      [-3.214971438983497, 47.64522440607226],
      [-3.270895013000413, 47.6788711919624],
      [-3.28786392948088, 47.700955682089656],
      [-3.3409140108798394, 47.70870473596058],
      [-3.34887310198566, 47.728643297493896],
      [-3.387107819813834, 47.70188799129091],
      [-3.4304371204407182, 47.70321133479036],
      [-3.452760568692728, 47.69534163728435],
      [-3.4983535994006227, 47.72943310383713],
      [-3.520959375326517, 47.75766895361749],
      [-3.5299269866083183, 47.78246448849144],
      [-3.5173821429083914, 47.80503760359084],
      [-3.5359178691698356, 47.81643884429237],
      [-3.5382904863091595, 47.836020899790604],
      [-3.523004293499609, 47.84914685714251],
      [-3.5396513689234244, 47.83617965304551],
      [-3.5425770850250635, 47.82151227934617],
      [-3.5229226544939514, 47.80223614015396],
      [-3.5386344188426375, 47.76264176980493],
      [-3.5641121250447267, 47.76861005548025],
      [-3.6173293885938573, 47.76951026006133],
      [-3.666828405107558, 47.78066980771674],
      [-3.6808401232958055, 47.776789568451115],
      [-3.7359638663585395, 47.80568353533829],
      [-3.7620343226442494, 47.790565500089855],
      [-3.80058711359008, 47.787633168752826],
      [-3.8206966045621122, 47.79704005110039],
      [-3.8510582202094192, 47.79595113220543],
      [-3.9014575009234087, 47.83811402726429],
      [-3.9352297901853737, 47.88271768897734],
      [-3.979285765673716, 47.90506331053172],
      [-3.9892426691277407, 47.88124042404969],
      [-3.9779071580540446, 47.85370904171028],
      [-4.041402101062129, 47.84560407995417],
      [-4.071579359966193, 47.8639343495831],
      [-4.12605004973185, 47.864172382493564],
      [-4.163559448578027, 47.84918510804166],
      [-4.175106219653961, 47.87596847062619],
      [-4.1971340520002265, 47.86102385449842],
      [-4.179428919445678, 47.83927438037873],
      [-4.15940114971976, 47.8318173246385],
      [-4.183013010492385, 47.800536704160066],
      [-4.2165887057710965, 47.793144928603546],
      [-4.269232846114915, 47.790984442793075],
      [-4.298541674244525, 47.80045582335789],
      [-4.362405222202317, 47.79574452412905],
      [-4.379252075100091, 47.82205366025167],
      [-4.349806909070088, 47.830787344673475],
      [-4.35026147711619, 47.85720715426938],
      [-4.367485583327389, 47.895018967431604],
      [-4.388015035003269, 47.926654784945555],
      [-4.423497441952159, 47.9629190746406],
      [-4.45357212479016, 47.98178335103627],
      [-4.509946245392576, 48.00508930612951],
      [-4.540544158513848, 48.01277237320508],
      [-4.564748323475724, 47.99983217909559],
      [-4.617153960280601, 48.01668341623001],
      [-4.632961817787164, 48.029371518298575],
      [-4.65236486621254, 48.02197780046869],
      [-4.699486095336003, 48.0276385517676],
      [-4.716343505589336, 48.06246408637442],
      [-4.673766453815838, 48.060832956449374],
      [-4.6659348329354255, 48.07024114770002],
      [-4.621727492652852, 48.068543619046565],
      [-4.5801348626137575, 48.08191194086029],
      [-4.553615731275088, 48.07713834742257],
      [-4.532513782688574, 48.08825622911054],
      [-4.487667360513949, 48.086548703734806],
      [-4.466691486895225, 48.09914603365788],
      [-4.42937785197245, 48.09921479885298],
      [-4.374400199477134, 48.110160305908614],
      [-4.306988535338467, 48.089182615717235],
      [-4.2855866218113725, 48.104744565148835],
      [-4.2837220130952955, 48.126900982468996],
      [-4.271721077823729, 48.133056537289555],
      [-4.273083579380047, 48.15418112435729],
      [-4.295843919274587, 48.16142932399722],
      [-4.292060558661123, 48.17634830008409],
      [-4.303105949344183, 48.19481221480856],
      [-4.332028821902894, 48.20625396161038],
      [-4.367421587366783, 48.20518414807059],
      [-4.376408546426716, 48.217120176811314],
      [-4.462948817851071, 48.23822329999254],
      [-4.501654363959345, 48.230424062827204],
      [-4.520558957420493, 48.191481893648785],
      [-4.5541301011022846, 48.167773715825284],
      [-4.55239723973961, 48.20798329589854],
      [-4.564023813101924, 48.232230611567076],
      [-4.544514592316071, 48.241191573122464],
      [-4.556269907144756, 48.25839883522839],
      [-4.583648389279179, 48.25188609816883],
      [-4.606432460120089, 48.260804316606915],
      [-4.566613794720501, 48.286343567274294],
      [-4.5805180078903875, 48.31953640425027],
      [-4.553173678040408, 48.33877871593713],
      [-4.545322996139177, 48.32453014489154],
      [-4.552688342449066, 48.29433138900056],
      [-4.535044973995933, 48.28408016653001],
      [-4.51791127193264, 48.29532344340755],
      [-4.502598847108407, 48.28086108393083],
      [-4.455054516900592, 48.292768680960236],
      [-4.423778354693354, 48.291677200748616],
      [-4.4121268947877414, 48.27743520886878],
      [-4.384499553201252, 48.27515572709832],
      [-4.338007897909741, 48.28559363845158],
      [-4.308030328849242, 48.29716811390846],
      [-4.271673566367982, 48.29591729189257],
      [-4.261871578053853, 48.27947084714217],
      [-4.223087545336637, 48.296299071102354],
      [-4.281228861951339, 48.314447470573086],
      [-4.328634688910267, 48.31488418033181],
      [-4.316134692309666, 48.335818592679146],
      [-4.367224196551887, 48.34371171314183],
      [-4.375256083838373, 48.32630324311795],
      [-4.442251665475733, 48.32677107157494],
      [-4.443889468046649, 48.34883595893029],
      [-4.402940735996712, 48.390022498012094],
      [-4.424948261340765, 48.397597431903],
      [-4.435282416373567, 48.38339288656854],
      [-4.458114014938408, 48.385867352822785],
      [-4.5070286895447, 48.375178986581524],
      [-4.538022709122905, 48.357127206507],
      [-4.54966862065911, 48.362005158047474],
      [-4.608723907528041, 48.33788748522352],
      [-4.628097678951578, 48.33751415715651],
      [-4.678429988988318, 48.35543131935038],
      [-4.69483398347226, 48.35279072707112],
      [-4.707560306796703, 48.33232585661272],
      [-4.7722963997844206, 48.329183978768505],
      [-4.769925202904266, 48.361796246678736],
      [-4.760909970272357, 48.37274934621913],
      [-4.774449224429342, 48.389388669092874],
      [-4.773685539184036, 48.40492512169256],
      [-4.79490980371592, 48.41306522400434],
      [-4.778308286782586, 48.44933411185572],
      [-4.759049827922969, 48.47040485137466],
      [-4.777001937365527, 48.50311695180772],
      [-4.757988885564184, 48.52093310825663],
      [-4.762396097201912, 48.530913179201654],
      [-4.730582004767845, 48.556349210517865],
      [-4.704749488588449, 48.56897618289027],
      [-4.681095549020893, 48.56731997011509],
      [-4.627815146836776, 48.57891940354545],
      [-4.6098896150813555, 48.575823083209116],
      [-4.590772268866488, 48.59402847039412],
      [-4.562764141371104, 48.59817521227606],
      [-4.557624352657544, 48.623470387580625],
      [-4.519579706212667, 48.63461429836846],
      [-4.501808596546954, 48.621190515057386],
      [-4.431795383056437, 48.635233047605645],
      [-4.417756994812844, 48.65471295900724],
      [-4.398044453426728, 48.65561657189681],
      [-4.35001885330614, 48.67651959770488],
      [-4.3167824361752904, 48.67199333723616],
      [-4.271733741368674, 48.64950099695784],
      [-4.223767566780155, 48.648361716153644],
      [-4.209602839214799, 48.67044798555623],
      [-4.186935690423405, 48.68646122790089],
      [-4.1089633691562515, 48.69443456873133],
      [-4.057584711672935, 48.689257976253764],
      [-4.0534274992711286, 48.7032941013924],
      [-4.032990823785386, 48.71270325427199],
      [-4.0005346564303155, 48.71162801931488],
      [-3.9834396203178497, 48.72630319219002],
      [-3.9668110875090608, 48.7196296260678],
      [-3.973629366481163, 48.70388914743309],
      [-3.9694817850407995, 48.67541780327085],
      [-3.9494614719873633, 48.65286849015005],
      [-3.9035069777805154, 48.66440380162745],
      [-3.8895722831453385, 48.64166403341278],
      [-3.845544973771357, 48.62696293321332],
      [-3.8562366572284197, 48.64905420905967],
      [-3.8458155271587935, 48.66164492704556],
      [-3.8544747780529227, 48.68637205322022],
      [-3.820777313163899, 48.701354575208065],
      [-3.7874874253592217, 48.70179551925303],
      [-3.765699137667055, 48.70888608345991],
      [-3.7332511605024314, 48.70742218765922],
      [-3.702432092334233, 48.69038564489073],
      [-3.6591595380103863, 48.69489143579908],
      [-3.642801218211254, 48.672120238447434],
      [-3.6591438196705743, 48.659209780669244],
      [-3.636485708364634, 48.67068553367222],
      [-3.5812485466634136, 48.67001788504223],
      [-3.5848109666117063, 48.71714328760577],
      [-3.5553712374292132, 48.727513465979534],
      [-3.550313063112974, 48.748161651547555],
      [-3.583914958484379, 48.770957492800314],
      [-3.57794290588313, 48.788317654113186],
      [-3.535033949747009, 48.80271604156535],
      [-3.5408959300937988, 48.823160071945985],
      [-3.5231064424378755, 48.82984632644791],
      [-3.467838623077333, 48.823298521249114],
      [-3.4301953417140556, 48.79725199605727],
      [-3.3975446062757473, 48.80074944363836],
      [-3.382428393678025, 48.81593302680193],
      [-3.3570114279950727, 48.81969907013649],
      [-3.323351109794353, 48.8372857508137],
      [-3.2620728843625333, 48.83540082059945],
      [-3.2457616227016315, 48.85707703727301],
      [-3.2197192609615692, 48.86646505727847],
      [-3.2028204869337964, 48.83450712281955],
      [-3.1679438416382495, 48.85314717376183],
      [-3.1247589566102234, 48.864723936263395],
      [-3.094421852953979, 48.86764668461141],
      [-3.0834891305254475, 48.827262011215225],
      [-3.0537161489037152, 48.8152457771198],
      [-3.013633951697306, 48.82213396052265],
      [-3.006141529447617, 48.8002958368664],
      [-3.0454089632329833, 48.78782195169868],
      [-3.0109390309556923, 48.76663541659801],
      [-2.950301280923285, 48.76363410458018],
      [-2.9289747965586783, 48.753783159339335],
      [-2.9451008416298974, 48.72080660138098],
      [-2.925263771726924, 48.707706235287056],
      [-2.8902558845725252, 48.69783483539522],
      [-2.8807700984538154, 48.67423957383046],
      [-2.8565017877713177, 48.67400220577111],
      [-2.82693502135218, 48.65056933154873],
      [-2.815798289739638, 48.60972420668523],
      [-2.819598830141578, 48.59352827457419],
      [-2.7797389357262325, 48.5851014526199],
      [-2.772680617047176, 48.57054449754173],
      [-2.7202164699056217, 48.55545942233219],
      [-2.720977837428319, 48.53284752618768],
      [-2.698438421518125, 48.50568354123005],
      [-2.6756732508454437, 48.510662191084755],
      [-2.681558937117988, 48.53113342751236],
      [-2.6302852526774863, 48.52609134516814],
      [-2.6312676669697814, 48.53812967265482],
      [-2.60556004275335, 48.55182776019479],
      [-2.559733538085472, 48.58639210176802],
      [-2.4952155554349047, 48.608924120470554],
      [-2.4728755698685654, 48.62308300588095],
      [-2.468004297270983, 48.64958127336475],
      [-2.4356309681917874, 48.65218264157844],
      [-2.4121662285976435, 48.64149535614902],
      [-2.3609466388725773, 48.65556175834698],
      [-2.3145391685823196, 48.67409586438523],
      [-2.294173242748991, 48.659975373377236],
      [-2.3134177483953855, 48.63324397584119],
      [-2.3374720527788573, 48.61972552862038],
      [-2.311900696016676, 48.61149441654407],
      [-2.299891144928281, 48.624056828499725],
      [-2.263502079970802, 48.64364865321494],
      [-2.205399315491741, 48.57889213970631],
      [-2.1755905479570017, 48.576407987143455],
      [-2.1572330600099674, 48.58835712746883],
      [-2.165799240930372, 48.60423827586908],
      [-2.1237080247752895, 48.6044100285603],
      [-2.141418514151318, 48.63150826238565],
      [-2.1110187885946092, 48.639371270835035],
      [-2.0731195987308073, 48.639848527788644],
      [-2.0550968709795594, 48.62731181103318],
      [-2.030265589793804, 48.623037585858],
      [-2.0335527005150444, 48.60945056178775],
      [-2.0135087146521657, 48.58752054847777],
      [-2.006894935706843, 48.56611290198358],
      [-1.972343842323779, 48.534637865266006],
      [-1.9480369010443914, 48.53880790488251],
      [-1.9611498133853817, 48.55028465234394],
      [-1.9919510225165333, 48.59410622069773],
      [-2.0087091801575645, 48.60763335351246],
      [-2.025977992976761, 48.634440306350065],
      [-2.0259623321650078, 48.651529854005666],
      [-1.9952407599257496, 48.66014163675909],
      [-1.9698282134951834, 48.686323125327455],
      [-1.938080197743341, 48.69445682698749],
      [-1.9058385211153706, 48.69048099448555],
      [-1.8905012399508623, 48.69894290465139],
      [-1.8485728474073226, 48.69470909677442],
      [-1.8364331812622583, 48.68121682888067],
      [-1.862720260590434, 48.66727765633872],
      [-1.8716564802790996, 48.64741056211108],
      [-1.8450734275301934, 48.61636825056999],
      [-1.7739813304009915, 48.60347255264488],
      [-1.6611080846074195, 48.61074343781696],
      [-1.571087121326364, 48.62644574305228],
      [-1.5435435350637288, 48.63121279257955],
      [-1.5156463864153509, 48.618246538328954],
      [-1.4771911456334617, 48.618735940477464],
      [-1.443640368193959, 48.62752557033798],
      [-1.3931950000571642, 48.650524383670096],
      [-1.4858341115383116, 48.68638581019091],
      [-1.5081675203931897, 48.69117348999072],
      [-1.5319288351465319, 48.73102057677593],
      [-1.5601811084952157, 48.73546601099355],
      [-1.5744067767391592, 48.75184944838184],
      [-1.5689116621961623, 48.80265414493922],
      [-1.5744419562050678, 48.821684938652076],
      [-1.60492034674064, 48.83789166867906],
      [-1.5871695543001123, 48.84645691414395],
      [-1.5763778734243568, 48.86622956887365],
      [-1.5696724678452931, 48.899023227002154],
      [-1.5515508912728522, 48.90833449236361],
      [-1.5434217971126396, 48.93102731412556],
      [-1.5621121920567587, 48.94101750482779],
      [-1.5607059456223331, 49.00148291031971],
      [-1.5937932678762967, 49.022503267429315],
      [-1.6094115242692357, 49.07927811770436],
      [-1.606935957044734, 49.11003133842661],
      [-1.5947491074806148, 49.131932809532245],
      [-1.6073172609984514, 49.19664548258766],
      [-1.5892834706047838, 49.23342782862976],
      [-1.61699549946473, 49.23161226126529],
      [-1.6316904105955683, 49.21422567935813],
      [-1.6541578497297265, 49.241447844599655],
      [-1.6650285987196094, 49.265558278699984],
      [-1.711168264458309, 49.32509928438261],
      [-1.7242753116935, 49.32710827112396],
      [-1.7647207703926648, 49.36355636410871],
      [-1.8070415201028562, 49.371883988182944],
      [-1.8236585668079848, 49.4014702879999],
      [-1.8267724913131993, 49.452917249373044],
      [-1.844605855792456, 49.47096122199953],
      [-1.8525981237513987, 49.51038685631441],
      [-1.8819329337834734, 49.519310940029676],
      [-1.8856955118557936, 49.54039147246037],
      [-1.8579284299662393, 49.5515291506756],
      [-1.8410273136069895, 49.57173433702396],
      [-1.8451411614330706, 49.61908992529084],
      [-1.8603000418191267, 49.65019279204103],
      [-1.8956952778084324, 49.664931836942685],
      [-1.9461788272965446, 49.67434830154208],
      [-1.936439146470204, 49.69375892397446],
      [-1.9472764301188112, 49.704932281313916],
      [-1.942245186733018, 49.72555529334811],
      [-1.916516630754937, 49.724878374537525],
      [-1.882912866788183, 49.705706386310766],
      [-1.8587301287976281, 49.715623305593],
      [-1.839532798595857, 49.71155454173644],
      [-1.8228561882429963, 49.69031481833081],
      [-1.7745875090438277, 49.68086868404481],
      [-1.717807811993846, 49.67984522346912],
      [-1.6867515873282075, 49.67307140423291],
      [-1.6724172017020642, 49.65765473955586],
      [-1.63285141798923, 49.66064611404181],
      [-1.6246789365290826, 49.6464653967003],
      [-1.520239237616472, 49.65741709322276],
      [-1.4858029063728724, 49.669313639920425],
      [-1.4710805783093919, 49.69667602847381],
      [-1.4557412827864928, 49.691326221246335],
      [-1.4211576721789985, 49.70370298113619],
      [-1.3685551471066846, 49.706629617440186],
      [-1.2998499192449107, 49.6935189965057],
      [-1.2679290471720517, 49.69532738213563],
      [-1.271008796143279, 49.6797055830237],
      [-1.2391814229357874, 49.65240173891874],
      [-1.2290598170197164, 49.60871341882292],
      [-1.254286601197548, 49.61265560522737],
      [-1.2662663845442874, 49.592850277700535],
      [-1.2962320370638967, 49.58317124235064],
      [-1.3091674328357494, 49.552607084023585],
      [-1.3063594927131816, 49.538927340535665],
      [-1.2527076587085064, 49.480600121491854],
      [-1.1925632637620527, 49.432177811410874],
      [-1.1680020729875382, 49.408031628446786],
      [-1.1617336960367475, 49.391748947534765],
      [-1.1785816005847864, 49.37845801413797],
      [-1.1639520107160866, 49.36665848650392],
      [-1.1196225402660513, 49.3555679898469],
      [-1.0782173743324184, 49.38849350541246],
      [-1.0302250793020893, 49.38958141035263],
      [-0.9887106844575865, 49.39741154140852],
      [-0.939384692333383, 49.39504220855989],
      [-0.8754490370644941, 49.36957076039473],
      [-0.8252067535670959, 49.35753795654393],
      [-0.7222488013847815, 49.347004165939666],
      [-0.647937711207899, 49.3459781072392],
      [-0.5987386883749173, 49.33981459321013],
      [-0.5532470677623611, 49.34610161856534],
      [-0.5153140242090218, 49.34501616988366],
      [-0.451962464076699, 49.33549752432251],
      [-0.41484859816372344, 49.33558104324266],
      [-0.36104180324658686, 49.3228840274954],
      [-0.3025757588502727, 49.298441925066236],
      [-0.22569330389903117, 49.28181693021672],
      [-0.1696632231153101, 49.28624405434959],
      [-0.09557631944627808, 49.2981805995376],
      [-0.0005074304791027337, 49.32556281264521],
      [0.07413515099973117, 49.3651917979644],
      [0.11039331683077662, 49.39427124874853],
      [0.1297510279522393, 49.40298907653438],
      [0.17578018698541883, 49.41235618373611],
      [0.21994001130961943, 49.42676180478785],
      [0.2972244202906153, 49.42986163728299],
      [0.33898282435946164, 49.440931306061266],
      [0.3393207094305681, 49.449868831628415],
      [0.27314284372297987, 49.453414579238974],
      [0.25680101789478893, 49.44632740649414],
      [0.21769675248147502, 49.45296251103499],
      [0.19246414594061223, 49.45146267347711],
      [0.12124538314126981, 49.46319090812617],
      [0.09027197399997772, 49.48238083770633],
      [0.0869152117239871, 49.50142956453898],
      [0.06955005489894274, 49.50639804169097],
      [0.07493125272583377, 49.53633203458849],
      [0.1067376725391497, 49.58349804620551],
      [0.1358703018889327, 49.61860240605246],
      [0.1545347165457159, 49.648642302677665],
      [0.16422682078283132, 49.6864622352224],
      [0.1916018630620729, 49.70633817691035],
      [0.25070045477776937, 49.72314244472515],
      [0.2832559477463974, 49.736576618509304],
      [0.3204248903804176, 49.741454635143654],
      [0.35491793407269084, 49.75501019348861],
      [0.3653124690043182, 49.7657533954537],
      [0.43342979790476643, 49.788116116429364],
      [0.5227583029442804, 49.82453411281469],
      [0.5729399803584807, 49.84972067059468],
      [0.642036660341264, 49.86418779498403],
      [0.7061281802579872, 49.87233770484798],
      [0.717770959544887, 49.86976975035244],
      [0.7909714215080401, 49.875752899331296],
      [0.8325742104597927, 49.889609850179305],
      [0.8746626207569893, 49.894680673456534],
      [0.9297893305630467, 49.90673400517858],
      [0.9631874837108031, 49.91985755151356],
      [1.0200465393294824, 49.91610987649026],
      [1.106143977580899, 49.936747669981074],
      [1.205890918797349, 49.97349908694593],
      [1.2464603482064156, 49.99528129594621],
      [1.3324241305696787, 50.047123771141564],
      [1.3815511365583815, 50.065766690242754],
      [1.4538812244610173, 50.11032909893485],
      [1.4827841768786612, 50.17236969475436],
      [1.5124381763701704, 50.2017063700377],
      [1.5484320471199113, 50.215221918674395],
      [1.5961991412319396, 50.185500707511935],
      [1.6274102918028155, 50.19000238393702],
      [1.6733457406347452, 50.17468159487083],
      [1.6837994282613178, 50.18302974967859],
      [1.6626698233214425, 50.21359776854474],
      [1.6225022082629057, 50.21514839669941],
      [1.5936030011622346, 50.24428749653274],
      [1.592228280418861, 50.25645840166426],
      [1.5493076277829827, 50.260405655281644],
      [1.537947734772934, 50.282668474589045],
      [1.5509358115042569, 50.350323301735024],
      [1.5713354866487355, 50.35845399700039],
      [1.612971621673763, 50.36029378076435],
      [1.6415439209955993, 50.35215320244089],
      [1.6252340548486761, 50.37183439351513],
      [1.5794678479116167, 50.37921781617639],
      [1.555658414115049, 50.39768062437081],
      [1.5699664459670746, 50.444481375243384],
      [1.5774237782704903, 50.51653279341479],
      [1.5854134517805314, 50.537352456779296],
      [1.6192903917994448, 50.53527496629917],
      [1.581273860269884, 50.56277234419219],
      [1.5766093094233622, 50.57222508465235],
      [1.5754702574635382, 50.64376921433218],
      [1.5607115203796038, 50.69967640419991],
      [1.5940631007212405, 50.73490631352115],
      [1.6044002891055364, 50.76283351023702],
      [1.6054977030902289, 50.79303342273639],
      [1.577946150441329, 50.85333565117015],
      [1.5835458071046016, 50.87182012003101],
      [1.6421019989787113, 50.87886833813072],
      [1.6673428270137456, 50.89226662456871],
      [1.692541394498637, 50.91537531133391],
      [1.7288531641107558, 50.93789161557745],
      [1.7807165493326218, 50.95487029863247],
      [1.8323650766395754, 50.963096204871015],
      [1.8711615477136776, 50.97493483409628],
      [1.8896911321764678, 50.97231089361649],
      [1.915776261224723, 50.9840846407249],
      [2.0454058015250167, 50.998317962768176],
      [2.067712027611001, 51.00650515466528],
      [2.1101030884192196, 51.00515436424168],
      [2.1387263723924743, 51.02047731288083],
      [2.1902457546664613, 51.01804063448275],
      [2.1918237851448867, 51.034715807218134],
      [2.218563107404832, 51.03222167243631],
      [2.2590195633030064, 51.043490583642196],
      [2.342194490550121, 51.057309558240824],
      [2.395233281299764, 51.05033008301106],
      [2.4247542826080695, 51.055626117413155],
      [2.5463252972819905, 51.088402305103116],
      [2.5628959666132043, 51.064605626153075],
      [2.575982419777286, 51.01375274755887],
      [2.5739940374755257, 51.00352108038122],
      [2.6080265106495104, 50.98718243218274],
      [2.6326872836805335, 50.94600519935866],
      [2.5900130400649437, 50.919096163778526],
      [2.607535290732441, 50.9124066086538],
      [2.610326300169626, 50.862061552621626],
      [2.634982733121708, 50.81275641876223],
      [2.7181914035396804, 50.81320560793264],
      [2.737511840411906, 50.782670872072046],
      [2.781947131330458, 50.751092598326686],
      [2.7867446611456996, 50.73381921547943],
      [2.813275390169948, 50.716947630173586],
      [2.8483804350303905, 50.72291764499729],
      [2.870172980563469, 50.70291406808004],
      [2.8983800599236966, 50.69423611297018],
      [2.922327234684258, 50.70280503897035],
      [2.9374114543505443, 50.74209770629575],
      [2.969662775707332, 50.74961097888233],
      [3.0220450489487614, 50.77210693218003],
      [3.0613770323899683, 50.78050085123832],
      [3.088705984283759, 50.77336931450737],
      [3.1109249331515394, 50.794122278216896],
      [3.1519976780893755, 50.78234329668403],
      [3.1991342816652897, 50.734730939175776],
      [3.211782829092417, 50.71325538898358],
      [3.2446834848058272, 50.71304246580907],
      [3.2583851392745764, 50.70064949194268],
      [3.2624232273169933, 50.6784852826563],
      [3.2410965359392057, 50.65778991575674],
      [3.25520436601742, 50.62258275479467],
      [3.270292523712289, 50.610812110449736],
      [3.2817266737308675, 50.57732844552126],
      [3.275957418697949, 50.55853840025285],
      [3.286531399925948, 50.52757580555707],
      [3.362673754976159, 50.50309643957861],
      [3.3762218373048696, 50.49108182460425],
      [3.432248715257614, 50.507118771607686],
      [3.4739635628750074, 50.533561751146436],
      [3.5194110029872943, 50.522868831996384],
      [3.4963210235985827, 50.498683290226374],
      [3.5223176214061334, 50.49496788323126],
      [3.5683447329862488, 50.50097669533296],
      [3.5853371177327213, 50.49053885448199],
      [3.607087548067213, 50.497283044825515],
      [3.6436495322531623, 50.463211807396284],
      [3.664218477773235, 50.45317465879256],
      [3.673733173288203, 50.38972521710218],
      [3.658270970690328, 50.37134117960119],
      [3.673675908769281, 50.3349233228203],
      [3.710430924740463, 50.30316614070561],
      [3.731606234421014, 50.31235332867399],
      [3.7362206016065613, 50.3432570480394],
      [3.7474038155627727, 50.35092914840201],
      [3.8527555820958135, 50.35159288086556],
      [3.889799951126813, 50.32998024472696],
      [3.9090033110400197, 50.328466474363545],
      [3.967898522087576, 50.35036640688851],
      [3.993908424048428, 50.3486153355103],
      [4.027387066832294, 50.357490365275616],
      [4.037614330208127, 50.34294913095139],
      [4.12197186382358, 50.298311640610294],
      [4.124352666164769, 50.27361398697191],
      [4.207538662540225, 50.27297040073831],
      [4.221850100147642, 50.25695260304661],
      [4.189455991301053, 50.23687571936165],
      [4.160120194753328, 50.202535967141905],
      [4.149388632981125, 50.174787171955124],
      [4.154073029606527, 50.161253710023075],
      [4.126926892460069, 50.13501086614893],
      [4.197038470830976, 50.13530868448463],
      [4.197497822706036, 50.109176465683625],
      [4.227538806961173, 50.079679063351016],
      [4.217832037790911, 50.06080510279823],
      [4.173905462387534, 50.045603929706495],
      [4.162223866832849, 50.04898484693673],
      [4.136379263762434, 50.0204647847058],
      [4.157702243163169, 49.988249823321674],
      [4.140895251736092, 49.97875959899442],
      [4.172395207394802, 49.977078597853904],
      [4.197110124342558, 49.967721269218174],
      [4.195936343936246, 49.955694802056996],
      [4.233164134987158, 49.95775092391093],
      [4.308104819422298, 49.96952311706087],
      [4.3497091536314265, 49.95218185745914],
      [4.378945338182918, 49.95306872889387],
      [4.446940783287939, 49.93750459397252],
      [4.481610454645573, 49.94785279402108],
      [4.510410432978069, 49.94668308724302],
      [4.540542634439033, 49.96796846829222],
      [4.573698227853897, 49.98030418970413],
      [4.674659776287784, 49.99662785172198],
      [4.699272082803148, 50.0537945750903],
      [4.682746158906797, 50.068791197105114],
      [4.702078764143763, 50.095528001382924],
      [4.751345563705796, 50.11174102047036],
      [4.762161208292365, 50.13639695682964],
      [4.824292330401957, 50.160731428897094],
      [4.8319896237206255, 50.153951790022184],
      [4.879565525415205, 50.15202955288437],
      [4.894894253598731, 50.13460065104415],
      [4.87076833368792, 50.124262635964875],
      [4.8685301585153855, 50.09762527596348],
      [4.8512375276421595, 50.1013639038007],
      [4.829260707691278, 50.04803422242763],
      [4.840392803845824, 50.037589901362395],
      [4.816997367063873, 50.01564028095157],
      [4.818941576641115, 49.99514497954768],
      [4.790113818832296, 49.97001760397983],
      [4.7909170614563354, 49.95839750551827],
      [4.8501895621955295, 49.94647303906409],
      [4.85813253464247, 49.93290175060757],
      [4.889248547086521, 49.90972760726109],
      [4.851762233586226, 49.861022896690535],
      [4.8675793624704715, 49.84007770188321],
      [4.867091434851301, 49.81419502771181],
      [4.855382632112906, 49.79236512744182],
      [4.930929513878152, 49.78685155554447],
      [4.9561320976529775, 49.801224669739426],
      [4.9988368069437765, 49.799310046914584],
      [5.008928105209694, 49.7819335599042],
      [5.063182643598133, 49.76192871159604],
      [5.090172312313782, 49.76506393233352],
      [5.115073254538092, 49.741436250370164],
      [5.125659977368626, 49.714502727337425],
      [5.165853990849716, 49.70714272719065],
      [5.166208402693295, 49.69291623120586],
      [5.208819376633823, 49.694568280877455],
      [5.24360037396294, 49.68569800167118],
      [5.267272773424226, 49.696522600045526],
      [5.332001785017169, 49.65478357737764],
      [5.308525437369952, 49.63590355578242],
      [5.31173915183996, 49.61354728351897],
      [5.342988167557187, 49.62673952946669],
      [5.393536658328508, 49.61708773850276],
      [5.430990436352139, 49.592779697501115],
      [5.441967127749439, 49.55082330374587],
      [5.465066186620626, 49.53886525112502],
      [5.447572873980034, 49.517370291127484],
      [5.470910130592771, 49.49720502129504],
      [5.541175427366362, 49.51526468551874],
      [5.556054418170868, 49.529249012712015],
      [5.593452750302351, 49.52190879652461],
      [5.615929213702141, 49.52712694494857],
      [5.636796547934622, 49.54504576700591],
      [5.663216639729165, 49.5527322793671],
      [5.701263034650679, 49.53951786025749],
      [5.7325201904510275, 49.544464906619325],
      [5.774412837219823, 49.56298255279729],
      [5.793930405166045, 49.551240203734956],
      [5.8369571912669995, 49.54247191409379],
      [5.836233336445646, 49.51985372493001],
      [5.869093663016168, 49.49878274863684],
      [5.893401083719673, 49.496909750110916],
      [5.939944946897957, 49.50096978911359],
      [5.971783320923806, 49.4913163055914],
      [5.982536594108972, 49.45105527746582],
      [6.02765177650771, 49.455538917030424],
      [6.055623323914133, 49.46535978168454],
      [6.076705098128764, 49.463656672860225],
      [6.123474712997803, 49.473441852078615],
      [6.155249287805073, 49.50381182382041],
      [6.256413026994873, 49.5100225584692],
      [6.279098229910086, 49.50335719786734],
      [6.296571041016304, 49.480106944913075],
      [6.312489363480976, 49.48032031550801],
      [6.364391072331403, 49.459485597794696],
      [6.407623710243866, 49.4675154675881],
      [6.419090928661235, 49.474937866427084],
      [6.470082245543733, 49.462763723134074],
      [6.554360572853942, 49.41838313208105],
      [6.5405283477242735, 49.401145041619664],
      [6.563269082557648, 49.38828526012536],
      [6.587074296882195, 49.3849358288203],
      [6.568452040389599, 49.3449154814342],
      [6.5955807025304996, 49.33027144782366],
      [6.588884741951244, 49.32209317831749],
      [6.6175524843963345, 49.302169283821854],
      [6.638579265784432, 49.29553478914614],
      [6.660330281989145, 49.26096781243674],
      [6.684616608971706, 49.24231882936001],
      [6.694810016062328, 49.21590718897018],
      [6.71965859480421, 49.22137943216265],
      [6.731498100833765, 49.2060909576377],
      [6.711364818806887, 49.188541683234874],
      [6.738512367681464, 49.16366113912631],
      [6.7845550558709675, 49.168090266711616],
      [6.834537718950126, 49.151466712468384],
      [6.8609420718719845, 49.17871523308854],
      [6.851537824818373, 49.200484084387014],
      [6.835378369933188, 49.21132385245782],
      [6.857449193294861, 49.222275027981475],
      [6.885215505575565, 49.21104948546778],
      [6.9354255357579655, 49.22215593921376],
      [6.959285196153518, 49.20300203966244],
      [6.974631643711455, 49.20981472133559],
      [6.998806412798309, 49.19427844167972],
      [7.034324960729169, 49.189681536322034],
      [7.0276480006522135, 49.17008240822472],
      [7.046147533263103, 49.13856517309118],
      [7.054297461807657, 49.1126326923314],
      [7.102777636286391, 49.140499445767595],
      [7.156729211133253, 49.121305964534294],
      [7.183938532639857, 49.130733985517935],
      [7.206632231887248, 49.123477362718766],
      [7.245302859127929, 49.129807084465035],
      [7.282600803455493, 49.11693169088899],
      [7.298652796709717, 49.117457118012105],
      [7.329841252153916, 49.144944493025186],
      [7.36284676727469, 49.14521917131234],
      [7.366092677702596, 49.172465701358625],
      [7.4450608117676325, 49.18430555508701],
      [7.4552291504089965, 49.16632509495078],
      [7.493995166397878, 49.16963770649743],
      [7.492159555313797, 49.14186210733454],
      [7.515445437889962, 49.12116845523475],
      [7.528977470244165, 49.097207912439856],
      [7.569010373147195, 49.07978656309812],
      [7.599982064041664, 49.08333244993025],
      [7.631070828249015, 49.07018534790654],
      [7.635285449703197, 49.0541613551771],
      [7.674493719844778, 49.045038223564895],
      [7.707321827434022, 49.05405939239169],
      [7.732229514556395, 49.044360193628584],
      [7.7694289796184846, 49.04774245099701],
      [7.794587260656014, 49.065791918907415],
      [7.868785609773862, 49.03415707326486],
      [7.8916198759125775, 49.04759725259064],
      [7.91670251003609, 49.04096826102967],
      [7.934626827531663, 49.05780915030637],
      [7.963045952864435, 49.04293379198175],
      [7.980055713019351, 49.02611958784408],
      [7.996711900374363, 49.02835488426032],
      [8.04922031277792, 49.01418078522583],
      [8.091376040143569, 48.98925821691501],
      [8.139803411862454, 48.9784496512654],
      [8.222736022927482, 48.97597079725059],
      [8.232836216038969, 48.96697753356419],
      [8.195800936945588, 48.956207796670824],
      [8.139135858085103, 48.892894767368645],
      [8.124578710186213, 48.87069214791491],
      [8.096320563319823, 48.810383710147846],
      [8.058939302273348, 48.78881229521909],
      [8.029130925797226, 48.78647637177024],
      [8.02282921871248, 48.7683571613944],
      [8.00494993955973, 48.75878082453401],
      [7.969723932850821, 48.75544146180311],
      [7.9631400450945655, 48.7213530972908],
      [7.890886576122132, 48.663095896501474],
      [7.83981724689541, 48.6413739851056],
      [7.828710432304629, 48.61770955816417],
      [7.804169862129346, 48.59233465434716],
      [7.800241352793423, 48.57868703631089],
      [7.807039561191584, 48.5211033498163],
      [7.794703889606317, 48.50209025324464],
      [7.768199002101854, 48.489657602973395],
      [7.764890504178269, 48.456687573980204],
      [7.7359757519993035, 48.40415792790444],
      [7.732004052005759, 48.375403413096564],
      [7.7450285445207925, 48.33566571677894],
      [7.733240771599047, 48.31779594408542],
      [7.703153723776976, 48.30881155728774],
      [7.667802806274936, 48.223910814462734],
      [7.645993836509834, 48.20894372463805],
      [7.628420489829124, 48.18234169524649],
      [7.601153626284707, 48.15837840879526],
      [7.597069643598031, 48.13314665478638],
      [7.577312855212316, 48.12036852180279],
      [7.579959302676986, 48.1060241628568],
      [7.569229918944941, 48.08140103227969],
      [7.571623278915894, 48.03037963253785],
      [7.605442849614272, 48.00375666733687],
      [7.622090012011825, 47.972273158282675],
      [7.583289971638735, 47.93111867339603],
      [7.583517659704031, 47.90164054665845],
      [7.556704620769357, 47.87994110574265],
      [7.563546061062802, 47.85213451656705],
      [7.531259755609871, 47.786758092739966],
      [7.5322376356790635, 47.768103948729824],
      [7.548078387714247, 47.73958664484275],
      [7.543471717716171, 47.7219511962019],
      [7.513752001020271, 47.70281805790175],
      [7.522345790100707, 47.662321489849276],
      [7.566467478798788, 47.63289794133456],
      [7.592166196122287, 47.595364230897054],
      [7.5847059048213925, 47.57726432341283],
      [7.506109699820528, 47.5443371916087],
      [7.5252484411100795, 47.5198644991559],
      [7.507326934833388, 47.5152130072166],
      [7.506753671499357, 47.49563386052678],
      [7.486855374527259, 47.48173332111362],
      [7.434560148747173, 47.497929056791456],
      [7.422690550181342, 47.484388198189606],
      [7.4501432999503265, 47.47529024914208],
      [7.420293972815237, 47.44542252005264],
      [7.380947797110134, 47.431864331193985],
      [7.337556330611329, 47.44091115313276],
      [7.281541911285926, 47.43421552862775],
      [7.2462970616937685, 47.4222020998741],
      [7.232812984772371, 47.43881243982562],
      [7.206682632158959, 47.43482071398383],
      [7.170823146393291, 47.444351795572196],
      [7.18658232857427, 47.491228297146144],
      [7.158949175727589, 47.49075866687029],
      [7.130345969302944, 47.50302658239207],
    ],
  ],
};
