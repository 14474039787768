import React, { ReactElement, ReactFragment, ReactText } from 'react';

import { FeatureCollection, Geometry } from 'geojson';
import _ from 'lodash';
import { TFunction } from 'react-i18next';

import { PowerPlantClass } from 'src/components/AnalysisResultsPowerPlantClassForm/powerPlantClass';

export interface PowerPlant {
  FID: number | null;
  uuid: string;
  area: string | null;
  longitude: string | null;
  latitude: string | null;
  mgrs: string | null;
  region: string | null;
  admn_level4: string | null;
  survey_name: string;
  label: string | null;
  collection_method: string | null;
  sources: string | null;
  name: string;
  name_sources: string | null;
  operator: string | null;
  operator_sources: string | null;
  generation_unit_count: number | null;
  generation_unit_count_sources: string | null;
  generator_geojson: string | null;
  production_capacity_sources: string | null;
  power_sources: string | null;
  power_sources_sources: string | null;
  buildings_count: number | null;
  storage_tanks_count: number | null;
  water_bodies_count: number | null;
  is_connected: string | null;
  is_connected_sources: string | null;
  connected_population: string | null;
  connected_population_sources: string | null;
  water_access: string | null;
  water_access_sources: string | null;
  power_type_validated: string;
  pai_confidence: PAIConfidence;
}

export type PAIConfidence = 'high' | 'medium' | 'low';

interface GenerationUnitProperties {
  name: string;
  name_sources: string[];
  operator: string;
  operator_sources: string[];
  power_sources: string[];
  power_sources_sources: string[];
  production_capacity: number;
  production_capacity_sources: string[];
  site_uuid: string;
  sources: string[];
  uuid: string;
}

interface GenerationUnitProperties {
  name: string;
  name_sources: string[];
  operator: string;
  operator_sources: string[];
  power_sources: string[];
  power_sources_sources: string[];
  production_capacity: number;
  production_capacity_sources: string[];
  site_uuid: string;
  sources: string[];
  uuid: string;
}

export const formatDecimals = (original: string | number): number =>
  Math.round((original as number) * 100) / 100;

export const capitalize = (original: string | number): JSX.Element => (
  <span className="text-capitalize">{original as string}</span>
);

export const formatSources = (original: string | number): string =>
  (original as string)
    .split(';')
    .map((source) => (source === 'preligens' ? 'Preligens' : source))
    .join(', ');

export const formatPowerSource = (original: string | number): string =>
  (original as string)
    .split(';')
    .map((source) => _.capitalize(source))
    .join(', ');

type Value = ReactElement | ReactText | ReactFragment;
const formatGenerationUnits =
  (t: TFunction) =>
  (original: string | number): { title: string; value: Value }[] => {
    const featureCollection: FeatureCollection<
      Geometry,
      GenerationUnitProperties
    > = JSON.parse(original as string);
    return featureCollection.features.map((feature, index: number) => ({
      title: t('powerPlantsAttributes.fields.generation_unit_detail', {
        value: index + 1,
      }),
      value: (
        <ul key={index} className="pl-3 m-0">
          <li>
            {t(
              'powerPlantsAttributes.fields.generation_unit_detail_production_capacity',
            )}
            :{' '}
            {t('misc.units.megaWatt', {
              value: feature.properties.production_capacity,
            })}
          </li>
          <li>
            {t('powerPlantsAttributes.fields.generation_unit_detail_operator')}:{' '}
            {feature.properties.operator}
          </li>
          <li>
            {t(
              'powerPlantsAttributes.fields.generation_unit_detail_power_source',
            )}
            :{' '}
            <span className="text-capitalize">
              {feature.properties.power_sources.join(', ')}
            </span>
          </li>
        </ul>
      ),
    }));
  };

export const powerPlantSectionsFieldsMap = (
  t: TFunction,
): PowerPlantSection[] => [
  {
    sectionLabel: t('powerPlantsAttributes.sections.location'),
    icon: 'geo',
    fields: [
      {
        name: 'area',
        label: t('powerPlantsAttributes.fields.area'),
        format: (original: string | number): string =>
          t('misc.units.squareMeter', {
            value: formatDecimals(original).toLocaleString('en-US'),
          }),
      },
      {
        name: 'longitude',
        label: t('powerPlantsAttributes.fields.longitude'),
        format: formatDecimals,
      },
      {
        name: 'latitude',
        label: t('powerPlantsAttributes.fields.latitude'),
        format: formatDecimals,
      },
      {
        name: 'mgrs',
        label: t('powerPlantsAttributes.fields.mgrs'),
      },
      {
        name: 'region',
        label: t('powerPlantsAttributes.fields.region'),
        format: capitalize,
      },
      {
        name: 'admn_level4',
        label: t('powerPlantsAttributes.fields.admn_level4'),
        format: capitalize,
      },
    ],
  },
  {
    sectionLabel: t('powerPlantsAttributes.sections.dataCollection'),
    icon: 'server',
    fields: [
      {
        name: 'survey_name',
        label: t('powerPlantsAttributes.fields.survey_name'),
        format: capitalize,
      },
      {
        name: 'collection_method',
        label: t('powerPlantsAttributes.fields.collection_method'),
        format: capitalize,
      },
      {
        name: 'sources',
        label: t('powerPlantsAttributes.fields.sources'),
        format: formatSources,
      },
    ],
  },
  {
    sectionLabel: t('powerPlantsAttributes.sections.operation'),
    icon: 'people',
    fields: [
      {
        name: 'operator',
        label: t('powerPlantsAttributes.fields.operator'),
        format: capitalize,
      },
      {
        name: 'connected_population',
        label: t('powerPlantsAttributes.fields.connected_population'),
        format: (original: string | number): string =>
          formatDecimals(original).toLocaleString('en-US'),
      },
    ],
  },
  {
    sectionLabel: t('powerPlantsAttributes.sections.generationUnit'),
    icon: 'gear',
    fields: [
      {
        name: 'generation_unit_count',
        label: t('powerPlantsAttributes.fields.generation_unit_count'),
        hasSource: true,
      },
      {
        name: 'generator_geojson',
        label: t('powerPlantsAttributes.fields.generation_unit_detail'),
        formatMany: formatGenerationUnits(t),
      },
    ],
  },
  {
    sectionLabel: t('powerPlantsAttributes.sections.electricity'),
    icon: 'lightning',
    fields: [
      {
        name: 'power_type_validated',
        label: t('powerPlantsAttributes.fields.power_type_validated'),
        format: function (original: string | number): string {
          switch (original) {
            case PowerPlantClass.fossilFuel:
              return t(`powerPlantsSources.power-fossilFuel`);
            case PowerPlantClass.geothermalPower:
              return t(`powerPlantsSources.power-geothermalPower`);
            case PowerPlantClass.hydroelectricity:
              return t(`powerPlantsSources.power-hydroelectricity`);
            case PowerPlantClass.solarPower:
              return t(`powerPlantsSources.power-solarPower`);
            case PowerPlantClass.substation:
              return t(`powerPlantsSources.power-substation`);
            case PowerPlantClass.uranium:
              return t(`powerPlantsSources.power-uranium`);
            case PowerPlantClass.windTurbine:
              return t(`powerPlantsSources.power-windTurbine`);
            case PowerPlantClass.unknown:
              return t(`powerPlantsSources.power-unknown`);
            default:
              return '';
          }
        },
      },
      {
        name: 'production_capacity',
        label: t('powerPlantsAttributes.fields.production_capacity'),
        hasSource: true,
        format: (original: string | number): string =>
          t('misc.units.megaWatt', {
            value: formatDecimals(original).toLocaleString('en-US'),
          }),
      },
      {
        name: 'power_sources',
        label: t('powerPlantsAttributes.fields.power_sources'),
        format: formatPowerSource,
      },
      {
        name: 'is_connected',
        label: t('powerPlantsAttributes.fields.is_connected'),
        hasSource: true,
        format: capitalize,
      },
    ],
  },
  {
    sectionLabel: t('powerPlantsAttributes.sections.buildingDamage'),
    icon: 'building',
    fields: [
      {
        name: 'buildings_count',
        label: t('powerPlantsAttributes.fields.buildings_count'),
      },
    ],
  },
  {
    sectionLabel: t('powerPlantsAttributes.sections.storageTanks'),
    icon: 'trash',
    fields: [
      {
        name: 'storage_tanks_count',
        label: t('powerPlantsAttributes.fields.storage_tanks_count'),
      },
    ],
  },
  {
    sectionLabel: t('powerPlantsAttributes.sections.waterBody'),
    icon: 'droplet',
    fields: [
      {
        name: 'water_bodies_count',
        label: t('powerPlantsAttributes.fields.water_bodies_count'),
        hasSource: true,
      },
      {
        name: 'water_access',
        label: t('powerPlantsAttributes.fields.water_access'),
        format: capitalize,
      },
    ],
  },
];

export interface PowerPlantSection {
  sectionLabel: string;
  icon?: string;
  fields: PowerPlantField[];
}

export interface PowerPlantFieldDisplay {
  title: string;
  value: ReactElement | ReactText | ReactFragment;
}

export interface PowerPlantField {
  name: string;
  label: string;
  hasSource?: boolean;
  format?: (
    original: string | number,
  ) => ReactElement | ReactText | ReactFragment;
  formatMany?: (original: string | number) => PowerPlantFieldDisplay[];
}
