import { CustomLayer, SourceImageBounds } from 'src/components/ArcGisMap';

import environment from 'src/environments/environment';

export function createSourceImageLayer(
  urlTemplate: string,
  arcgisAuthToken: string,
  bounds?: SourceImageBounds,
): CustomLayer {
  return new CustomLayer({
    buildTileUrl: buildTileUrl(urlTemplate),
    requestHeader: () => getRequestHeader(arcgisAuthToken),
    bounds,
  });
}

function buildTileUrl(urlTemplate: string) {
  return (level: number, row: number, col: number): string =>
    urlTemplate
      .replace('{z}', String(level))
      .replace('{x}', String(col))
      .replace('{y}', String(row));
}

function getRequestHeader(arcgisAuthToken: string) {
  const headers: Record<string, string> = { 'User-Token': arcgisAuthToken };
  if (environment.basicAuthUsername) {
    const basicAuthString = window.btoa(
      `${environment.basicAuthUsername}:${environment.basicAuthPassword}`,
    );
    headers.Authorization = `Basic ${basicAuthString}`;
  }
  return headers;
}
