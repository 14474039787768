import { Polygon as GeoJsonPolygon, Feature, FeatureCollection } from 'geojson';

export interface RunResult {
  site_name: string;
  site_uuid: string;
  power_plant_type: string;
  pai_vhr_fusioned: FeatureCollection<GeoJsonPolygon, ResultAreaProperty>;
}

export type ResultArea = Feature<GeoJsonPolygon, ResultAreaProperty>;

export interface ResultAreaProperty {
  id: number;
  level1?: string;
  level2?: string;
  level3?: string;
  level4?: string;
  run_uuid?: string;
  site_uuid?: string;
  source?: string[];
  source_geometry?: string;
  is_validated?: boolean;
}

export function isValidRunResult(value: RunResult): boolean {
  return (
    value &&
    value.pai_vhr_fusioned &&
    value.pai_vhr_fusioned.features &&
    value.pai_vhr_fusioned.features.every(isValidResultArea)
  );
}

function isValidResultArea(value: ResultArea): boolean {
  return !!(
    value &&
    value.properties &&
    (value.properties.id || value.properties.id === 0) &&
    value.geometry
  );
}
