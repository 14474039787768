import React from 'react';

import { useTranslation } from 'react-i18next';

import { legendTypes } from 'src/components/AnalysisResultsMap/polygons';
import MapLegend, {
  LegendCategory,
  LegendType,
} from 'src/components/MapLegend';

const DashboardMapLegend = ({
  changeLegendDisplay: changeLegendDisplay,
}: {
  changeLegendDisplay?: (isVisible: boolean) => void;
}): React.ReactElement => {
  const { t } = useTranslation();

  const legendCategories: LegendCategory[] = [
    {
      categoryTitle: t('map.legend.categories.powerStation'),
      items: [
        {
          title: t('map.legend.items.highProbabilityPlant'),
          colors: legendTypes.black,
          legendType: LegendType.PowerPlant,
        },
        {
          title: t('map.legend.items.mediumProbabilityPlant'),
          colors: legendTypes.darkGray,
          legendType: LegendType.PowerPlant,
        },
        {
          title: t('map.legend.items.lowProbabilityPlant'),
          colors: legendTypes.lightGray,
          legendType: LegendType.PowerPlant,
        },
      ],
    },
    {
      categoryTitle: t('map.legend.categories.monitoring'),
      items: [
        {
          title: t('map.legend.items.swirActivity'),
          colors: legendTypes.greenWithNoFill,
          legendType: LegendType.Polygon,
        },
      ],
    },
    {
      categoryTitle: t('map.legend.categories.infrastructure'),
      items: [
        {
          title: t('map.legend.items.siteFootPrint'),
          colors: legendTypes.white,
          legendType: LegendType.Polygon,
        },
        {
          title: t('map.legend.items.waterBody'),
          colors: legendTypes.darkBlue,
          legendType: LegendType.Polygon,
        },
        {
          title: t('map.legend.items.storageTank'),
          colors: legendTypes.yellow,
          legendType: LegendType.Polygon,
        },
        {
          title: t('map.legend.items.undamagedBuilding'),
          colors: legendTypes.purpleBlue,
          legendType: LegendType.Polygon,
        },
        {
          title: t('map.legend.items.damagedBuilding'),
          colors: legendTypes.purple,
          legendType: LegendType.Polygon,
        },
        {
          title: t('map.legend.items.destroyedBuilding'),
          colors: legendTypes.black,
          legendType: LegendType.Polygon,
        },
        {
          title: t('map.legend.items.unknownBuilding'),
          colors: legendTypes.brown,
          legendType: LegendType.Polygon,
        },
      ],
    },
  ];

  return (
    <MapLegend
      legendCategories={legendCategories}
      changeLegendDisplay={changeLegendDisplay}
    />
  );
};

export default DashboardMapLegend;
