import {
  createContext,
  useContext,
  useState,
  useEffect,
  PropsWithChildren,
} from 'react';

import axios from 'axios';

import { Config } from 'src/api/internalApi';
import { mockConfigAPI, isAPIMockEnabled } from 'src/api/mock/mockAPI';
import environment from 'src/environments/environment';

type ConfigContextType = {
  config?: Config;
  isError: boolean;
  isFetchingConfig: boolean;
};

const ConfigContext = createContext<ConfigContextType>({
  config: undefined,
  isError: false,
  isFetchingConfig: true,
});

const httpClient = axios.create({
  baseURL: environment.apiBaseUrl,
  auth: {
    username: environment.basicAuthUsername,
    password: environment.basicAuthPassword,
  },
});
httpClient.interceptors.response.use(
  (result) => result,
  (error) => {
    console.error(error);
    return Promise.reject(error);
  },
);

if (isAPIMockEnabled()) {
  mockConfigAPI(httpClient);
}

function ConfigProvider({ children }: PropsWithChildren) {
  const [config, setConfig] = useState<Config>();
  const [isError, setError] = useState(false);
  const [isFetchingConfig, setFetchingConfig] = useState(false);

  useEffect(() => {
    const getConfig = async () => {
      setFetchingConfig(true);
      try {
        const { data } = await httpClient.get<Config>('/config');
        setConfig(data);
      } catch (error) {
        setError(true);
        console.error('Error while retrieving config: ', JSON.stringify(error));
      } finally {
        setFetchingConfig(false);
      }
    };
    getConfig();
  }, []);

  return (
    <ConfigContext.Provider value={{ config, isError, isFetchingConfig }}>
      {children}
    </ConfigContext.Provider>
  );
}

const useConfig = (): ConfigContextType => useContext(ConfigContext);
export { ConfigProvider, useConfig };
