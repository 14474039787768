import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

import { DashboardContext } from 'src/pages/Dashboard/context';

const VALID_FILE_EXTENSIONS = ['.tiff', '.tif', '.TIF', '.TIFF'];

interface Props {
  submitHandler: (e: FormEvent<HTMLFormElement>) => void;
  setSelectedFiles: (files: FileList) => void;
  reset: () => void;
}

const UploadModal = ({
  submitHandler,
  setSelectedFiles,
  reset,
}: Props): React.ReactElement => {
  const { show, setShow } = useContext(DashboardContext);
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const [isFileValid, setFileValid] = useState<null | boolean>(null);
  const [fileName, setFileName] = useState<string | null>(null);

  useEffect(() => {
    if (show) {
      reset();
    }
  }, [show]);

  useEffect(() => {
    if (fileName && fileName !== t('uploadModal.fileInput')) {
      const isValid = checkFile(fileName);
      setFileValid(isValid);
    }
  }, [fileName]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    submitHandler(e);

    if (formRef.current !== null) {
      formRef.current.reset();
    }
    setFileName(null);
    setFileValid(null);
    setShow(false);
  };

  const checkFile = (name: string) => {
    const extension = '.' + name.split('.')[name.split('.').length - 1];
    return VALID_FILE_EXTENSIONS.includes(extension);
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0 && files[0].name && files[0].size > 0) {
      setFileName(files[0].name);
      setSelectedFiles(files);
    } else {
      setFileName(null);
      setFileValid(false);
    }
  };

  const showError = useMemo(
    () => !isFileValid && !!fileName,
    [isFileValid, fileName],
  );

  return (
    <span>
      <Modal
        show={show}
        centered
        data-testid="upload-modal"
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('uploadModal.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('uploadModal.description')}</p>
          <Form onSubmit={onSubmit} ref={formRef}>
            <Form.Group>
              <Form.File custom>
                <Form.File.Input
                  data-testid="file-input"
                  accept={VALID_FILE_EXTENSIONS.join(',')}
                  onChange={onFileChange}
                  isInvalid={showError}
                />
                <Form.File.Label>
                  {fileName ?? t('uploadModal.fileInput')}
                </Form.File.Label>
                <Form.Control.Feedback
                  data-testid="upload-error-alert"
                  type="invalid"
                >
                  {t('uploadModal.alert')}
                </Form.Control.Feedback>
              </Form.File>
            </Form.Group>
            <p className="text-right">
              <Button
                variant="primary"
                type="submit"
                className="my-2 mw-50 px-3"
                disabled={!isFileValid}
                data-testid="confirm-upload"
              >
                {t('uploadModal.submitButton')}
              </Button>
            </p>
          </Form>
        </Modal.Body>
      </Modal>
    </span>
  );
};

export default UploadModal;
