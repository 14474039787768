import { PropsWithChildren, createContext, useContext } from 'react';

import { useAuth } from 'src/auth/AuthContext';

type UserContextType = __esri.PortalUser | null;

const UserContext = createContext<UserContextType>(null);

function UserProvider({ children }: PropsWithChildren) {
  return (
    <UserContext.Provider value={useAuth().user}>
      {children}
    </UserContext.Provider>
  );
}

const useUser = (): UserContextType => useContext(UserContext);
export { UserProvider, useUser };
