import React from 'react';

import NotificationToast from 'src/components/Notification';
import { useNotification } from 'src/notification/NotificationProvider';

function NotificationAnchor(): React.ReactElement {
  const notifications = useNotification();

  return (
    <>
      {notifications.getAllNotifications().map((notification) => (
        <NotificationToast
          key={notification.id}
          notification={notification}
          onClose={() => {
            notifications.removeNotificationByID(notification.id);
          }}
        />
      ))}
    </>
  );
}

export default NotificationAnchor;
