import React, { useContext } from 'react';

import { ReactComponent as ArrowRepeat } from 'bootstrap-icons/icons/arrow-repeat.svg';
import { ReactComponent as ExclamationTriangle } from 'bootstrap-icons/icons/exclamation-triangle.svg';
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'src/auth/AuthContext';
import { DashboardContext } from 'src/pages/Dashboard/context';

const UnauthorizedModal: React.FC = (): React.ReactElement => {
  const dashboardContext = useContext(DashboardContext);
  const { t } = useTranslation();
  const auth = useAuth();

  const refreshPage = (e: React.MouseEvent) => {
    e.preventDefault();
    window.location.reload();
  };

  const handleClickLogout = (e: React.MouseEvent) => {
    e.preventDefault();
    dashboardContext.resetAuthorizationErrors();
    auth.signOut();
  };

  return (
    <Modal
      show={!!dashboardContext.authorizationErrors.length}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title className="d-flex align-items-center">
          <ExclamationTriangle className="mr-2" width="21" height="21" />
          {t('unauthorized.title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="warning">
          <Alert.Heading>{t('unauthorized.sorry')}</Alert.Heading>
          <p>{t('unauthorized.contact')}</p>
          <Accordion>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                {t('unauthorized.details')}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <ul>
                    {dashboardContext.authorizationErrors.map(
                      (error, index: number) => (
                        <li key={index}>{error}</li>
                      ),
                    )}
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClickLogout}>
          {t('unauthorized.logout')}
        </Button>
        <Button
          className="d-flex align-items-center justify-content-center"
          variant="brand-dark"
          onClick={refreshPage}
        >
          <ArrowRepeat className="mr-2" />
          {t('unauthorized.reload')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UnauthorizedModal;
