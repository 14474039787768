import React from 'react';

import { ReactComponent as PinMapFill } from 'bootstrap-icons/icons/pin-map-fill.svg';
import Dropdown from 'react-bootstrap/Dropdown';

import { Area } from 'src/pages/Dashboard/context';

const DashboardMapAreaSwitcher = ({
  areasOfOperation,
  changeAreaOfOperation,
}: {
  areasOfOperation: Area[];
  changeAreaOfOperation: (area: Area | null) => void;
}): React.ReactElement => {
  return (
    <Dropdown drop="right" data-testid="map-area-switcher">
      <Dropdown.Toggle
        className="btn mapButton"
        data-testid="map-area-switcher-button"
      >
        <PinMapFill />
      </Dropdown.Toggle>
      <Dropdown.Menu data-testid="map-area-switcher-dropdown">
        {areasOfOperation.map((area, index) => (
          <Dropdown.Item
            key={index}
            onSelect={() => changeAreaOfOperation(area)}
          >
            {area.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DashboardMapAreaSwitcher;
