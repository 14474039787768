import MapView from '@arcgis/core/views/MapView';
import BasemapToggle from '@arcgis/core/widgets/BasemapToggle';

export function addBaseMapToggle(
  mapView: MapView,
  position?: __esri.UIAddPosition,
): void {
  const basemapToggle = new BasemapToggle({
    view: mapView,
    nextBasemap: 'satellite',
  });
  mapView.ui.add(basemapToggle, position);
}
