import MapView from '@arcgis/core/views/MapView';
import CoordinateConversion from '@arcgis/core/widgets/CoordinateConversion';

export function addCoordinateConversion(
  mapView: MapView,
  position?: __esri.UIAddPosition,
): void {
  const ccWidget = new CoordinateConversion({
    view: mapView,
  });
  mapView.ui.add(ccWidget, position);
}
