export enum PowerPlantClass {
  fossilFuel = 'power-fossilFuel',
  geothermalPower = 'power-geothermalPower',
  hydroelectricity = 'power-hydroelectricity',
  solarPower = 'power-solarPower',
  substation = 'power-substation',
  uranium = 'power-uranium',
  windTurbine = 'power-windTurbine',
  unknown = 'power-unknown',
}

export const powerPlantClassList: PowerPlantClass[] = [
  PowerPlantClass.fossilFuel,
  PowerPlantClass.geothermalPower,
  PowerPlantClass.hydroelectricity,
  PowerPlantClass.solarPower,
  PowerPlantClass.substation,
  PowerPlantClass.uranium,
  PowerPlantClass.windTurbine,
  PowerPlantClass.unknown,
];

export function toPowerPlantClass(value: string): PowerPlantClass | undefined {
  const ppClass = value as PowerPlantClass;
  if (Object.values(PowerPlantClass).indexOf(ppClass) >= 0) {
    return ppClass;
  }
  return undefined;
}
