import React from 'react';

import { ReactComponent as Logo } from 'src/assets/images/preligens-logo.svg';

import './index.scss';

const FullScreenSpinner: React.FC = (): React.ReactElement => {
  return (
    <div className="vw-100 vh-100 d-flex justify-content-center align-items-center">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <Logo className="loader" width="80" height="80" />
      </div>
    </div>
  );
};

export default FullScreenSpinner;
