import React, { useState, useEffect } from 'react';

import QueryTask from '@arcgis/core/tasks/QueryTask';
import Query from '@arcgis/core/tasks/support/Query';
import { useTranslation } from 'react-i18next';

import Chart from 'src/components/Chart';
import { useConfig } from 'src/config/ConfigContext';
import {
  ActivityAverages,
  ChimneyActivity,
  WeeklySwirActivity,
} from 'src/models/swir.interface';
import { dateMonthsAgo } from 'src/utils/chartUtil';
import { flattenAndCamelCase, processData } from 'src/utils/dataProcessing';

import './index.scss';

const OUT_FIELDS = [
  'active_chimney_count',
  'inactive_chimney_count',
  'cloudy_chimney_count',
  'stream_date',
  'site_uuid',
];
const COLORS = ['#0DE3B0', '#D9D9D9', '#0792A5'];
const GRAPH_KEYS: (keyof ActivityAverages<ChimneyActivity>)[] = [
  'activeChimneyCount',
  'cloudyChimneyCount',
  'inactiveChimneyCount',
];

const X_TICK_MAX_COUNT = 7;

const ChimneyActivityChart = ({
  siteUUID,
  numberOfMonth,
}: {
  siteUUID: string;
  numberOfMonth: number;
}): React.ReactElement | null => {
  const { t } = useTranslation();
  const config = useConfig().config!;
  const fullKeys: string[] = t('powerPlantsActivity.chimneysActivity.labels', {
    returnObjects: true,
  });
  const testId = 'chart-chimney-activity';
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isQueryFailed, setQueryFail] = useState<boolean>(false);
  const chimneySwirActivityUrl = config.arcgis_fs_url_swir_chimney_activity;
  const queryTask = new QueryTask({
    url: chimneySwirActivityUrl,
  });
  const [numberOfActiveChimneys, setNumberOfActiveChimneys] = useState<
    number | null
  >(null);
  const [weeklySwirData, setWeeklySwirData] = useState<
    WeeklySwirActivity[] | null
  >(null);

  const query = new Query({
    outFields: OUT_FIELDS,
    where: `stream_date > '${dateMonthsAgo(
      numberOfMonth,
    )}' and site_uuid = '${siteUUID}'`,
    orderByFields: ['stream_date DESC'],
  });

  function getResults(response: __esri.FeatureSet) {
    setLoading(false);
    const fetchedWeeklySwirData = processData(
      flattenAndCamelCase<ChimneyActivity>(response),
      GRAPH_KEYS,
    );
    const maxActiveChimneys = Math.max(
      ...fetchedWeeklySwirData.map(
        (weeklySwirActivity) =>
          weeklySwirActivity.activeChimneyCount +
          weeklySwirActivity.cloudyChimneyCount +
          weeklySwirActivity.inactiveChimneyCount,
      ),
    );
    setNumberOfActiveChimneys(maxActiveChimneys);
    setWeeklySwirData(fetchedWeeklySwirData);
  }

  function requestFailed(error: unknown) {
    setLoading(false);
    setQueryFail(true);
    console.error('Power plants ChimneyActivity request error: ', error);
  }

  useEffect(() => {
    const runQuery = async () => {
      try {
        const queryResponse = await queryTask.execute(query);
        getResults(queryResponse);
      } catch (e) {
        requestFailed(e);
      }
    };
    setLoading(true);
    setQueryFail(false);
    runQuery();
  }, [siteUUID, numberOfMonth]);

  if (weeklySwirData && weeklySwirData.length === 0) {
    return (
      <p className="text-primary my-2">
        {t('powerPlantsAttributes.notMonitoredPlant')}
      </p>
    );
  }

  return (
    weeklySwirData && (
      <div className="chimneyChart__container">
        <Chart
          isLoading={isLoading}
          isQueryFailed={isQueryFailed}
          data={weeklySwirData}
          xLabelMaxCount={X_TICK_MAX_COUNT}
          totalActiveItems={numberOfActiveChimneys}
          fullKeys={fullKeys}
          graphKeys={GRAPH_KEYS}
          legendItemWidth={145}
          testId={testId}
          colors={COLORS}
          showLabels={false}
        />
      </div>
    )
  );
};

export default ChimneyActivityChart;
