import React from 'react';

import { ReactComponent as ArrowRepeat } from 'bootstrap-icons/icons/arrow-repeat.svg';
import { ReactComponent as Check2Circle } from 'bootstrap-icons/icons/check2-circle.svg';
import { ReactComponent as ExclamationCircle } from 'bootstrap-icons/icons/exclamation-circle.svg';
import { ReactComponent as InfoCircle } from 'bootstrap-icons/icons/info-circle.svg';
import classNames from 'classnames';
import Toast from 'react-bootstrap/Toast';

import {
  Notification,
  NotificationType,
} from 'src/notification/NotificationProvider';

import './index.scss';

function getDataTestId(
  isInfo: boolean,
  IsInProgress: boolean,
  isSuccess: boolean,
  IsError: boolean,
): string {
  if (isInfo) return 'toast-info';
  if (IsInProgress) return 'toast-progress';
  if (isSuccess) return 'toast-success';
  if (IsError) return 'toast-error';
  return 'toast';
}

function NotificationToast(props: {
  notification: Notification;
  onClose: () => void;
}): React.ReactElement {
  const { notification, onClose } = props;
  const isInfo = notification.type === NotificationType.Info;
  const isSuccess = notification.type === NotificationType.Success;
  const isError = notification.type === NotificationType.Error;
  const isInProgress = notification.type === NotificationType.InProgress;

  return (
    <Toast
      delay={notification.duration}
      autohide={!!notification.duration}
      onClose={onClose}
      className={classNames({
        'notification-toast': true,
        'border-info': isInfo,
        'border-success': isSuccess,
        'border-danger': isError,
        'border-primary': isInProgress,
      })}
      data-testid={
        notification.dataTestID ||
        getDataTestId(isInfo, isInProgress, isSuccess, isError)
      }
    >
      <Toast.Header closeButton={!notification.hideClose}>
        <strong className="mr-auto">{notification.title}</strong>
      </Toast.Header>
      <Toast.Body className="d-flex align-items-center">
        <div
          className={classNames({
            'text-info': isInfo,
            'text-success': isSuccess,
            'text-danger': isError,
            'text-primary': isInProgress,
          })}
        >
          {isInfo && <InfoCircle height="20" width="20" className="mr-2" />}
          {isSuccess && (
            <Check2Circle height="20" width="20" className="mr-2" />
          )}
          {isError && (
            <ExclamationCircle height="20" width="20" className="mr-2" />
          )}
          {isInProgress && (
            <ArrowRepeat height="20" width="20" className="mr-2" />
          )}
        </div>
        <div className="body-content">{notification.content}</div>
      </Toast.Body>
    </Toast>
  );
}

export default NotificationToast;
