import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { AreasVisibility } from 'src/components/AnalysisResultsMap/areaValidationLayer';
import { polygonTypes } from 'src/components/AnalysisResultsMap/polygons';
import MapLegend, {
  LegendCategory,
  LegendType,
} from 'src/components/MapLegend';

interface Props {
  onAreasVisibilityChange: (visibility: AreasVisibility) => void;
}

const AnalysisResultsMapLegend = ({
  onAreasVisibilityChange,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const [areaVisibility, setAreaVisibility] = useState<AreasVisibility>({
    showStorageTank: true,
    showUndamagedBuilding: true,
    showDamagedBuilding: true,
    showDestroyedBuilding: true,
    showUnknownBuilding: true,
  });

  const [legendCategories, setLegendCategories] = useState<LegendCategory[]>(
    [],
  );

  useEffect(() => {
    onAreasVisibilityChange(areaVisibility);
    setLegendCategories(getLegendCategories(areaVisibility));
  }, [areaVisibility]);

  const getLegendCategories = (
    areaVisibilityStatuses: AreasVisibility,
  ): LegendCategory[] => {
    return [
      {
        categoryTitle: t('map.legend.categories.infrastructure'),
        items: [
          {
            title: t('map.legend.items.siteFootPrint'),
            colors: polygonTypes.white,
            legendType: LegendType.Polygon,
          },
          {
            title: t('map.legend.items.storageTank'),
            colors: polygonTypes.yellow,
            legendType: LegendType.Polygon,
            visibility: {
              toggleVisitiblity: () =>
                setAreaVisibility({
                  ...areaVisibilityStatuses,
                  showStorageTank: !areaVisibilityStatuses.showStorageTank,
                }),
              isVisible: areaVisibilityStatuses.showStorageTank,
            },
          },
          {
            title: t('map.legend.items.undamagedBuilding'),
            colors: polygonTypes.purpleBlue,
            legendType: LegendType.Polygon,
            visibility: {
              toggleVisitiblity: () =>
                setAreaVisibility({
                  ...areaVisibilityStatuses,
                  showUndamagedBuilding:
                    !areaVisibilityStatuses.showUndamagedBuilding,
                }),
              isVisible: areaVisibilityStatuses.showUndamagedBuilding,
            },
          },
          {
            title: t('map.legend.items.damagedBuilding'),
            colors: polygonTypes.purple,
            legendType: LegendType.Polygon,
            visibility: {
              toggleVisitiblity: () =>
                setAreaVisibility({
                  ...areaVisibilityStatuses,
                  showDamagedBuilding:
                    !areaVisibilityStatuses.showDamagedBuilding,
                }),
              isVisible: areaVisibilityStatuses.showDamagedBuilding,
            },
          },
          {
            title: t('map.legend.items.destroyedBuilding'),
            colors: polygonTypes.black,
            legendType: LegendType.Polygon,
            visibility: {
              toggleVisitiblity: () =>
                setAreaVisibility({
                  ...areaVisibilityStatuses,
                  showDestroyedBuilding:
                    !areaVisibilityStatuses.showDestroyedBuilding,
                }),
              isVisible: areaVisibilityStatuses.showDestroyedBuilding,
            },
          },
          {
            title: t('map.legend.items.unknownBuilding'),
            colors: polygonTypes.brown,
            legendType: LegendType.Polygon,
            visibility: {
              toggleVisitiblity: () =>
                setAreaVisibility({
                  ...areaVisibilityStatuses,
                  showUnknownBuilding:
                    !areaVisibilityStatuses.showUnknownBuilding,
                }),
              isVisible: areaVisibilityStatuses.showUnknownBuilding,
            },
          },
        ],
      },
    ];
  };

  return <MapLegend legendCategories={legendCategories} />;
};

export default AnalysisResultsMapLegend;
