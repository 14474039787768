import { useReducer } from 'react';

import { useApi } from 'src/api/APIContext';
import {
  CreateSurveyExportRequest,
  CreateSurveyExportResponse,
  IsSurveyUpToDateResponse,
  StatusSurveyExportResponse,
  SurveyStatus,
} from 'src/api/internalApi';
import { useAuth } from 'src/auth/AuthContext';
import { sleep } from 'src/utils/sleep';

type Survey123Handler = {
  exportToSurvey123: (
    surveyKey: string,
    progressNotificationUUID: string,
  ) => void;
  state: State;
  fetchSurveyStatus: (surveyKey: string) => Promise<SurveyStatus | undefined>;
};

type State = {
  status: string;
  isExporting: boolean;
  sendSurveyExportError: string | null;
  statusSurveyExportErrors: string[] | null;
  surveyExportRunUUID: string | null;
  surveyExportProgressNotificationUUID: string | null;
};

type Action =
  | { type: 'reset' }
  | {
      type: 'send-export-progress';
      surveyExportProgressNotificationUUID: State['surveyExportProgressNotificationUUID'];
    }
  | {
      type: 'send-export-error';
      sendSurveyExportError: State['sendSurveyExportError'];
    }
  | {
      type: 'send-export-done';
      surveyExportRunUUID: State['surveyExportRunUUID'];
    }
  | {
      type: 'export-error';
      statusSurveyExportErrors: State['statusSurveyExportErrors'];
    }
  | { type: 'export-done' };

// Constants
export const IDLE = 'IDLE';
export const SEND_EXPORT_PROGRESS = 'SEND_EXPORT_PROGRESS';
export const SEND_EXPORT_ERROR = 'SEND_EXPORT_ERROR';
export const STATUS_EXPORT_PROGRESS = 'STATUS_EXPORT_PROGRESS';
export const STATUS_EXPORT_DONE = 'STATUS_EXPORT_DONE';
export const STATUS_EXPORT_ERROR = 'STATUS_EXPORT_ERROR';

const initialState: State = {
  status: IDLE,
  isExporting: false,
  sendSurveyExportError: null,
  statusSurveyExportErrors: null,
  surveyExportRunUUID: null,
  surveyExportProgressNotificationUUID: null,
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'reset':
      return initialState;
    case 'send-export-progress':
      return {
        ...state,
        status: SEND_EXPORT_PROGRESS,
        isExporting: true,
        surveyExportProgressNotificationUUID:
          action.surveyExportProgressNotificationUUID,
      };
    case 'send-export-error':
      return {
        ...state,
        status: SEND_EXPORT_ERROR,
        isExporting: false,
        sendSurveyExportError: action.sendSurveyExportError,
      };
    case 'send-export-done':
      return {
        ...state,
        status: STATUS_EXPORT_PROGRESS,
        surveyExportRunUUID: action.surveyExportRunUUID,
      };
    case 'export-error':
      return {
        ...state,
        status: STATUS_EXPORT_ERROR,
        isExporting: false,
        statusSurveyExportErrors: action.statusSurveyExportErrors,
      };
    case 'export-done':
      return {
        ...state,
        status: STATUS_EXPORT_DONE,
        isExporting: false,
      };
    default:
      return state;
  }
};

const useSurvey123Handler = (): Survey123Handler => {
  const httpClient = useApi();
  const auth = useAuth();
  const [state, dispatch] = useReducer(reducer, initialState);

  const reset = (): void => {
    dispatch({ type: 'reset' });
  };

  const exportToSurvey123 = async (
    surveyKey: string,
    progressNotificationUUID: string,
  ) => {
    reset();
    dispatch({
      type: 'send-export-progress',
      surveyExportProgressNotificationUUID: progressNotificationUUID,
    });
    const createSurveyExportBody: CreateSurveyExportRequest = {
      arcgis_user_uuid: auth.getUserId() ?? '',
    };
    try {
      const { data } = await httpClient.post<CreateSurveyExportResponse>(
        `/survey/${surveyKey}/create`,
        createSurveyExportBody,
      );
      dispatch({
        type: 'send-export-done',
        surveyExportRunUUID: data.run_uuid,
      });
      monitorSurveyExport(data.run_uuid);
    } catch {
      dispatch({
        type: 'send-export-error',
        sendSurveyExportError: '',
      });
    }
  };

  const monitorSurveyExport = async (runUUID: string) => {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      try {
        const { data } = await httpClient.get<StatusSurveyExportResponse>(
          `/survey/${runUUID}/status`,
        );
        if (data.status === 'success') {
          dispatch({ type: 'export-done' });
          return;
        } else if (data.status === 'failed' || data.errors) {
          dispatch({
            type: 'export-error',
            statusSurveyExportErrors: data.errors || [],
          });
          return;
        }
        await sleep(3000);
      } catch {
        dispatch({
          type: 'export-error',
          statusSurveyExportErrors: [],
        });
        return;
      }
    }
  };

  const fetchSurveyStatus = async (surveyKey: string) => {
    try {
      const { data } = await httpClient.get<IsSurveyUpToDateResponse>(
        `/site/vhr-survey-status/${surveyKey}`,
      );
      return data.survey_status;
    } catch (e) {
      console.error(e);
    }
  };

  return {
    exportToSurvey123,
    fetchSurveyStatus,
    state,
  };
};

export default useSurvey123Handler;
