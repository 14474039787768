import React from 'react';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';

import { ReactComponent as EsriLogo } from 'src/assets/images/esri-logo.svg';
import { useAuth } from 'src/auth/AuthContext';
import { dashboardPath } from 'src/auth/routes';
import PublicPageLayout from 'src/layouts/PublicPageLayout';

const Login: React.FC = (): React.ReactElement => {
  const auth = useAuth();
  const { t } = useTranslation();

  if (auth.isAuthenticated()) {
    return <Redirect to={dashboardPath} />;
  }

  return (
    <PublicPageLayout>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between">
          <span className="h4 mb-0">{t('login.signinWith')}</span>
          <EsriLogo height="24" />
        </Card.Header>
        <Card.Body className="d-flex flex-column justify-content-center">
          {auth.isUnauthorized && (
            <Alert variant="danger">
              <Alert.Heading className="h6">
                {t('unauthorized.sorry')}
              </Alert.Heading>
              <p>{t('unauthorized.contact')}</p>
            </Alert>
          )}
          <Button
            variant="brand-dark"
            size="lg"
            block
            onClick={async () => auth.signIn()}
          >
            {t('login.signIn')}
          </Button>
        </Card.Body>
      </Card>
    </PublicPageLayout>
  );
};

export default Login;
