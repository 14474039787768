import React, { useEffect, useState, useMemo } from 'react';

import Collection from '@arcgis/core/core/Collection';
import Polygon from '@arcgis/core/geometry/Polygon';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import { Position } from 'geojson';
import { useTranslation, TFunction } from 'react-i18next';

import { useApi } from 'src/api/APIContext';
import { ResultArea } from 'src/api/internalApi';
import { getSourceImageBounds } from 'src/api/internalApi/tiles';
import { useAuth } from 'src/auth/AuthContext';
import {
  generatePopupTemplate,
  newAreaValidationLayer,
  polygonRenderer,
} from 'src/components/AnalysisResultsMap/areaValidationLayer';
import AnalysisResultsMapLegend from 'src/components/AnalysisResultsMapLegend';
import {
  CustomWidget,
  LayerWithIndex,
  MapViewProperties,
  WebMapProperties,
} from 'src/components/ArcGisMap';
import Map from 'src/components/Map';
import { createSourceImageLayer } from 'src/components/Map/SourceImageLayer';
import environment from 'src/environments/environment';

interface Props {
  runUUID: string;
  resultAreaList: ResultArea[];
  changeAreaValidation: (areaID: number, isValid: boolean) => void;
  footprintCoordinates?: number[][];
}

// From https://services.arcgis.com/nGt4QxSblgDfeJn9/arcgis/rest/services/GlobalBackground/FeatureServer/0
const earthPolygon102100SpatialRef = [
  [-20037507.8427882, 30240971.4583861],
  [20037507.8427882, 30240971.4583861],
  [20037507.8427882, -30240971.4583861],
  [-20037507.8427882, -30240971.4583861],
  [-20037507.8427882, 30240971.4583861],
];

const imageryURL = (uuid: string) =>
  `${environment.apiBaseUrl}/tiles/vhr/${uuid}/xyz/{z}/{x}/{y}.png`;

const AnalysisResultsMap = ({
  runUUID,
  resultAreaList,
  changeAreaValidation,
  footprintCoordinates,
}: Props): React.ReactElement => {
  const { getUserToken } = useAuth();
  const httpClient = useApi();
  const { t } = useTranslation();
  const [layers, setLayers] = useState<LayerWithIndex[]>([]);

  const graphics = useMemo(
    () => resultAreaList.map((area) => resultAreaToGraphic(area, t)),
    [resultAreaList],
  );
  const webMapOptions: WebMapProperties = useMemo(
    () => ({
      basemap: 'gray',
    }),
    [],
  );
  const mapViewOptions: MapViewProperties = useMemo(
    () => ({
      extent: getExtent(graphics),
      popup: {
        collapseEnabled: false,
        dockOptions: {
          buttonEnabled: false,
        },
      },
    }),
    [graphics],
  );

  const featureLayer = useMemo(
    () =>
      newAreaValidationLayer(
        'areaLayer',
        graphics,
        changeAreaValidation,
        `{areaClassName} – ${t(
          'analysisResultsPanel.validationPopup.source',
        )}: {areaSource}`,
      ),
    [],
  );

  useEffect(() => {
    featureLayer.source = new Collection(graphics);
  }, [graphics]);

  useEffect(() => {
    featureLayer.popupTemplate = generatePopupTemplate(
      featureLayer,
      changeAreaValidation,
      featureLayer.popupTemplate.title as string,
    );
  }, [changeAreaValidation]);

  const customWidgets: CustomWidget[] = useMemo(
    () => [
      {
        widget: (
          <AnalysisResultsMapLegend
            onAreasVisibilityChange={(areaVisibility) => {
              featureLayer.renderer = polygonRenderer(areaVisibility);
              featureLayer.refresh();
            }}
          />
        ),
        position: 'top-right',
        id: 'AnalysisResultsMapLegend',
      },
    ],
    [featureLayer],
  );

  useEffect(() => {
    setLayers((settedLayers) => {
      return [
        ...settedLayers.filter((layer) => layer.index !== 2),
        { layer: featureLayer, index: 2 },
      ];
    });
  }, [featureLayer]);

  useEffect(() => {
    if (footprintCoordinates) {
      let rings = [earthPolygon102100SpatialRef];
      // Close the polygon by adding the first X,Y coordinates at the end of the array
      // Reverse it to make it anti-clockwise, in order to extrude Earth polygon
      const completeFootprintCoordinates = [
        ...footprintCoordinates,
        footprintCoordinates[0],
      ].reverse();
      rings = [...rings, completeFootprintCoordinates];

      const polygon = new Polygon({
        rings,
        spatialReference: {
          // See https://developers.arcgis.com/documentation/spatial-references/
          // and https://support.esri.com/en/technical-article/000013950
          wkid: 3857,
        },
      });

      const graphic = new Graphic({
        geometry: polygon,
        symbol: new SimpleFillSymbol({
          color: [0, 0, 0, 0.2],
          outline: {
            color: [255, 255, 255],
            width: 1,
            style: 'short-dot',
          },
        }),
      });

      const footprintLayer = new GraphicsLayer({
        graphics: [graphic],
      });

      setLayers((settedLayers) => {
        return [
          ...settedLayers.filter((layer) => layer.index !== 1),
          { layer: footprintLayer, index: 1 },
        ];
      });
    }
  }, [footprintCoordinates]);

  useEffect(() => {
    async function getSourceImageLayer() {
      const sourceImageBounds = await getSourceImageBounds(runUUID, httpClient);
      const imageryLayer = createSourceImageLayer(
        imageryURL(runUUID),
        getUserToken() ?? '',
        sourceImageBounds,
      );
      imageryLayer.id = 'sourceImage';
      setLayers((settedLayers) => {
        return [
          ...settedLayers.filter((layer) => layer.index !== 0),
          { layer: imageryLayer, index: 0 },
        ];
      });
    }
    getSourceImageLayer();
  }, [runUUID]);

  return (
    <Map
      webMapOptions={webMapOptions}
      mapViewOptions={mapViewOptions}
      customWidgets={customWidgets}
      layers={layers}
    />
  );
};

export default AnalysisResultsMap;

function resultAreaToGraphic(area: ResultArea, t: TFunction): __esri.Graphic {
  const polygonClass = getAreaPolygonClass(area);
  return new Graphic({
    geometry: new Polygon({
      rings: geoJsonCoordinatesToPolygonRings(area.geometry.coordinates),
    }),
    attributes: {
      ObjectID: area.properties.id,
      areaID: area.properties.id,
      areaSource: (area.properties.source || [])
        .map(parseAreaSource(t))
        .join(', '),
      areaClassCode: polygonClass,
      areaClassName: areaClassName(polygonClass, t),
    },
  });
}

function getAreaPolygonClass(area: ResultArea): string {
  const properties = area.properties;
  if (properties.level1 === 'tank') {
    return 'storageTank';
  }
  if (properties.level1 === 'building') {
    switch (properties.level2) {
      case 'building-nodamage':
        return 'undamagedBuilding';
      case 'building-damaged':
        return 'damagedBuilding';
      case 'building-destroyed':
        return 'destroyedBuilding';
      default:
        return 'unknownBuilding';
    }
  }
  console.error(`unclassificable area ${area.id}`);
  console.error(area);
  return '';
}

function areaClassName(areaClassCode: string, t: TFunction): string {
  if (!t) return '';
  switch (areaClassCode) {
    case 'undamagedBuilding':
      return t('map.legend.items.undamagedBuilding');
    case 'damagedBuilding':
      return t('map.legend.items.damagedBuilding');
    case 'destroyedBuilding':
      return t('map.legend.items.destroyedBuilding');
    case 'unknownBuilding':
      return t('map.legend.items.unknownBuilding');
    case 'storageTank':
      return t('map.legend.items.storageTank');
    default:
      return `${t('analysisResultsPanel.unknownAreaType')}: ${areaClassCode}`;
  }
}

function geoJsonCoordinatesToPolygonRings(
  coordinates: Position[][],
): number[][][] {
  let polygonCoordinates: number[][] = [];
  if (coordinates.length) {
    polygonCoordinates = coordinates[0];
  }
  return [polygonCoordinates];
}

function getExtent(graphics: __esri.Graphic[]) {
  const extent = { xmin: 180, xmax: -180, ymin: 90, ymax: -90 };
  graphics
    .map((graphic) => (graphic.geometry as Polygon).rings)
    .filter((rings) => rings && rings.length && rings[0] && rings[0].length)
    .flatMap((rings) => rings[0])
    .forEach((coordinate) => {
      extent.xmin = Math.min(extent.xmin, coordinate[0]);
      extent.xmax = Math.max(extent.xmax, coordinate[0]);
      extent.ymin = Math.min(extent.ymin, coordinate[1]);
      extent.ymax = Math.max(extent.ymax, coordinate[1]);
    });
  return extent;
}

function parseAreaSource(t: TFunction) {
  return (value: string): string => {
    if (!t) return value;
    switch (value.replaceAll(`'`, '').toLowerCase()) {
      case 'vbml':
        return t('analysisResultsPanel.sources.vbml');
      case 'pai':
        return t('analysisResultsPanel.sources.pai');
    }
    return value;
  };
}
