import React, {
  FormEvent,
  useReducer,
  useState,
  useContext,
  useEffect,
} from 'react';

import { AxiosInstance } from 'axios';
import { useTranslation } from 'react-i18next';

import { useApi } from 'src/api/APIContext';
import { RunResponse, RunStatus, VHRRunStatus } from 'src/api/internalApi';
import { useAuth } from 'src/auth/AuthContext';
import { DashboardContext } from 'src/pages/Dashboard/context';
import { sleep } from 'src/utils/sleep';

export type FileAnalysisHandler = {
  submitHandler: (e: FormEvent<HTMLFormElement>) => void;
  reset: () => void;
  setSelectedFiles: React.Dispatch<React.SetStateAction<FileList | null>>;
  state: State;
  dispatch: React.Dispatch<Action>;
  monitorAnalysis: (runUUID: string) => void;
  monitorExport: (runUUID: string) => void;
};

type State = {
  status: string;
  runUUID: string | null;
  siteId: string | null;
  siteName: string | null;
  uploadProgress: number | null;
  uploadDone: boolean;
  uploadError: string | null;
  analysisProgress: boolean;
  analysisDone: boolean;
  analysisError: string[] | null;
  exportProgress: boolean;
  exportDone: boolean;
  exportError: string[] | null;
  uploadProgressNotificationUUID: string | null;
  analysisProgressNotificationUUID: string | null;
  exportProgressNotificationUUID: string | null;
};

type Action =
  | { type: 'reset' }
  | { type: 'init'; siteId: State['siteId']; siteName: State['siteName'] }
  | {
      type: 'upload-progress';
      uploadProgress: State['uploadProgress'];
      uploadProgressNotificationUUID?: State['uploadProgressNotificationUUID'];
    }
  | {
      type: 'upload-done';
      runUUID: State['runUUID'];
    }
  | { type: 'upload-error'; uploadError: State['uploadError'] }
  | {
      type: 'analysis-progress';
      analysisProgressNotificationUUID: State['analysisProgressNotificationUUID'];
    }
  | { type: 'analysis-error'; analysisError: State['analysisError'] }
  | { type: 'analysis-done' }
  | {
      type: 'export-progress';
      exportProgressNotificationUUID: State['exportProgressNotificationUUID'];
    }
  | { type: 'export-error'; exportError: State['exportError'] }
  | { type: 'export-done' };

// Constants
export const IDLE = 'IDLE';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const UPLOAD_DONE = 'UPLOAD_DONE';
export const UPLOAD_ERROR = 'UPLOAD_ERROR';
export const ANALYSIS_PROGRESS = 'ANALYSIS_PROGRESS';
export const ANALYSIS_DONE = 'ANALYSIS_DONE';
export const ANALYSIS_ERROR = 'ANALYSIS_ERROR';
export const EXPORT_DONE = 'EXPORT_DONE';
export const EXPORT_PROGRESS = 'EXPORT_PROGRESS';
export const EXPORT_ERROR = 'EXPORT_ERROR';

const initialState: State = {
  status: IDLE,
  runUUID: null,
  siteId: null,
  siteName: null,
  uploadProgress: null,
  uploadDone: false,
  uploadError: null,
  analysisProgress: false,
  analysisDone: false,
  analysisError: null,
  exportProgress: false,
  exportDone: false,
  exportError: null,
  uploadProgressNotificationUUID: null,
  analysisProgressNotificationUUID: null,
  exportProgressNotificationUUID: null,
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'reset':
      return initialState;
    case 'init':
      return {
        ...state,
        siteId: action.siteId,
        siteName: action.siteName,
        status: IDLE,
      };
    case 'upload-progress':
      return {
        ...state,
        uploadProgress: action.uploadProgress,
        uploadProgressNotificationUUID:
          action.uploadProgressNotificationUUID ??
          state.uploadProgressNotificationUUID,
        status: UPLOAD_PROGRESS,
      };
    case 'upload-done':
      return {
        ...state,
        uploadDone: true,
        runUUID: action.runUUID,
        status: UPLOAD_DONE,
      };
    case 'upload-error':
      return {
        ...state,
        uploadError: action.uploadError,
        status: UPLOAD_ERROR,
      };
    case 'analysis-progress':
      return {
        ...state,
        analysisProgress: true,
        analysisProgressNotificationUUID:
          action.analysisProgressNotificationUUID,
        status: ANALYSIS_PROGRESS,
      };
    case 'analysis-done':
      return {
        ...state,
        analysisDone: true,
        status: ANALYSIS_DONE,
      };
    case 'analysis-error':
      return {
        ...state,
        analysisError: action.analysisError,
        status: ANALYSIS_ERROR,
      };
    case 'export-progress':
      return {
        ...state,
        exportProgress: true,
        exportProgressNotificationUUID: action.exportProgressNotificationUUID,
        status: EXPORT_PROGRESS,
      };
    case 'export-error':
      return {
        ...state,
        exportError: action.exportError,
        status: EXPORT_ERROR,
      };
    case 'export-done':
      return {
        ...state,
        exportDone: true,
        status: EXPORT_DONE,
      };
    default:
      return state;
  }
};

const useFileAnalysisHandler = (): FileAnalysisHandler => {
  const httpClient = useApi();
  const auth = useAuth();
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { siteData, setIsInBusyState, runUUIDToValidate } =
    useContext(DashboardContext);

  const checkProcessStatus = async (
    _httpClient: AxiosInstance,
    runUUID: string,
    successStatus: VHRRunStatus,
    errorStatus: VHRRunStatus,
  ) => {
    try {
      // eslint-disable-next-line no-async-promise-executor
      return await new Promise(async (resolve, reject) => {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          try {
            const { data: runStatusData } = await _httpClient.get<RunStatus>(
              `/vhr/${runUUID}/status`,
            );
            if (runStatusData.status === successStatus) {
              resolve(runStatusData);
              break;
            } else if (
              runStatusData.status === errorStatus ||
              runStatusData.errors
            ) {
              reject(runStatusData);
              break;
            }
            await sleep(3000);
          } catch (error) {
            reject(error);
            break;
          }
        }
      });
    } catch (error) {
      let errors: string[] = [];
      if (
        typeof error === 'object' &&
        error != null &&
        'errors' in error &&
        Array.isArray(error.errors)
      ) {
        errors = error.errors;
      } else {
        console.error(JSON.stringify(error));
        errors = [
          `${t('misc.errors.unexpectedError')} ${t('misc.errors.tryAgain')}`,
        ];
      }
      throw errors;
    }
  };

  useEffect(() => {
    setIsInBusyState(
      [
        UPLOAD_PROGRESS,
        UPLOAD_DONE,
        ANALYSIS_PROGRESS,
        ANALYSIS_DONE,
        EXPORT_PROGRESS,
      ].includes(state.status) || !!runUUIDToValidate,
    );
  }, [state.status, runUUIDToValidate]);

  const reset = (): void => {
    dispatch({ type: 'reset' });
  };

  const submitHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!siteData || !selectedFiles) return;
    const { siteUUID, siteName } = siteData;
    const formData = new FormData();

    formData.append('file', selectedFiles[0]);
    formData.append('arcgis_user_uuid', auth.getUserId() ?? '');
    formData.append('site_uuid', siteUUID);

    try {
      dispatch({
        siteId: siteUUID,
        type: 'init',
        siteName,
      });
      dispatch({
        type: 'upload-progress',
        uploadProgress: 0,
      });
      const { data } = await httpClient.post<RunResponse>(
        `/vhr/run`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (uploadProgressData) => {
            dispatch({
              type: 'upload-progress',
              uploadProgress: Math.round(
                (100 * uploadProgressData.loaded) / uploadProgressData.total,
              ),
            });
          },
        },
      );
      dispatch({
        type: 'upload-done',
        runUUID: data.run_uuid,
      });
    } catch (error) {
      dispatch({ type: 'upload-error', uploadError: JSON.stringify(error) });
    }
  };

  const monitorAnalysis = async (runUUID: string) => {
    try {
      await checkProcessStatus(
        httpClient,
        runUUID,
        'fusion_success',
        'fusion_failed',
      );
      dispatch({ type: 'analysis-done' });
    } catch (error) {
      dispatch({
        type: 'analysis-error',
        analysisError: error as string[] | null,
      });
    }
  };

  const monitorExport = async (runUUID: string) => {
    try {
      await checkProcessStatus(
        httpClient,
        runUUID,
        'export_success',
        'export_failed',
      );
      dispatch({ type: 'export-done' });
    } catch (error) {
      dispatch({ type: 'export-error', exportError: error as string[] | null });
    }
  };

  return {
    submitHandler,
    setSelectedFiles,
    state,
    dispatch,
    reset,
    monitorAnalysis,
    monitorExport,
  };
};

export default useFileAnalysisHandler;
