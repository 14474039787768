import React from 'react';

import Spinner from 'react-bootstrap/Spinner';

const LoadingSpinner: React.FC = (): React.ReactElement => {
  return (
    <div className="d-flex align-items-center justify-content-center w-100 h-100">
      <Spinner animation="border" variant="primary" />
    </div>
  );
};

export default LoadingSpinner;
