import MapView from '@arcgis/core/views/MapView';

import { addBaseMapToggle } from './baseMapToggle';
import { addCoordinateConversion } from './coordinateConversion';
import { addScaleBar } from './scaleBar';
import { addZoom, removeZoom } from './zoom';
import { NativeWidgets } from '../../models';

export function initNativeWidgets(
  mapView: MapView,
  nativeWidgets?: NativeWidgets,
): void {
  removeZoom(mapView);
  if (nativeWidgets) {
    const { baseMapToggle, coordinateConversion, scaleBar, zoom } =
      nativeWidgets;
    if (zoom) addZoom(mapView, 'bottom-right');
    if (scaleBar) addScaleBar(mapView, 'bottom-right');
    if (baseMapToggle) addBaseMapToggle(mapView, 'bottom-left');
    if (coordinateConversion) addCoordinateConversion(mapView, 'bottom-left');
  }
}
