import React, { useEffect, useRef, useState } from 'react';

import Extent from '@arcgis/core/geometry/Extent';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import MapView from '@arcgis/core/views/MapView';
import WebMap from '@arcgis/core/WebMap';

import {
  CustomWidget,
  LayerClickListener,
  LayerWithIndex,
  MapViewProperties,
  NativeWidgets,
  WebMapProperties,
} from '../models';
import { usePrevious } from '../utils/usePrevious';
import { initNativeWidgets } from '../widget/native';

import './index.scss';

export interface ArcGisMapProps {
  webMapOptions: WebMapProperties;
  layers: LayerWithIndex[];
  mapViewOptions?: Omit<MapViewProperties, 'map'>;
  customWidgets?: CustomWidget[];
  nativeWidgets?: NativeWidgets;
  mapKey?: string | null;
  onWebMap?: (webMap?: __esri.WebMap) => void;
  layerClickListeners?: LayerClickListener[];
  boundingBox?: GeoJSON.BBox;
}

function ArcGisMap({
  webMapOptions,
  mapViewOptions,
  customWidgets,
  nativeWidgets,
  layers = [],
  mapKey,
  onWebMap,
  layerClickListeners: featureLayerClickListeners = [],
  boundingBox,
}: ArcGisMapProps): React.ReactElement {
  const mapElementRef = useRef<HTMLDivElement | null>(null);
  const [mapView, setMapView] = useState<__esri.MapView>();
  const [, setLayerHiglights] = useState<{ [key: string]: __esri.Handle }>({});
  const prevLayers = usePrevious<LayerWithIndex[]>(layers);

  useEffect(() => {
    if (!mapElementRef.current) return;
    const webMap = new WebMap(webMapOptions);
    let newMapView: MapView | null = createMapView(
      webMap,
      mapViewOptions || {},
    );
    newMapView.container = mapElementRef.current;

    addLayers(layers, webMap);
    initNativeWidgets(newMapView, nativeWidgets);

    newMapView.when().then(() => {
      onWebMap && onWebMap(webMap);
      newMapView && webMap && handleFeatureSelection(newMapView, webMap);
    });
    setMapView(newMapView);

    return () => {
      newMapView = null;
      setMapView(undefined);
    };
  }, [mapKey]);

  useEffect(() => {
    if (mapView) {
      customWidgets?.forEach((customWidget) => {
        const customWidgetElement = document.getElementById(customWidget.id);
        if (customWidgetElement !== null) {
          mapView.ui.add(customWidgetElement, customWidget.position);
        }
      });
    }
  }, [mapView, customWidgets]);

  useEffect(() => {
    const layersToAdd = layers.filter(
      (layer) =>
        prevLayers?.filter((prevLayer) => prevLayer.layer.id === layer.layer.id)
          .length === 0,
    );
    const layersToRemove =
      prevLayers?.filter(
        (prevLayer) =>
          layers.filter((layer) => prevLayer.layer.id === layer.layer.id)
            .length === 0,
      ) ?? [];
    addLayers(layersToAdd, mapView?.map);
    removeLayers(layersToRemove, mapView?.map);
  }, [layers]);

  useEffect(() => {
    if (!boundingBox) return;
    const extent = new Extent({
      xmin: boundingBox[0],
      ymin: boundingBox[1],
      xmax: boundingBox[2],
      ymax: boundingBox[3],
    });
    mapView?.goTo(extent).catch(function () {
      // avoid ArcGIS returning an error when the goTo() animation has been interrupted
      return;
    });
  }, [boundingBox]);

  function handleFeatureSelection(
    _mapView: __esri.MapView,
    webMap: __esri.WebMap,
  ) {
    featureLayerClickListeners.forEach((layerClickListener) => {
      const layer = webMap.layers
        .toArray()
        .find(
          (webMapLayer) =>
            webMapLayer.title &&
            webMapLayer.title === layerClickListener.layerTitle,
        );

      if (!(layer instanceof FeatureLayer)) return;

      _mapView.whenLayerView(layer).then((layerView) => {
        _mapView.on('click', (clickEvent) => {
          _mapView.hitTest(clickEvent, { include: layer }).then((event) => {
            const result = layerClickListener.hitTest(event.results);
            if (result) {
              removeLayerHighlight(layerClickListener.layerTitle);
              setLayerHighlight(layerClickListener, layerView, result.graphic);
              layerClickListener.onHit({
                mapView: _mapView,
                layerUrl: getLayerUrl(layer),
                clickedGraphic: result.graphic,
                onDeselection: () =>
                  removeLayerHighlight(layerClickListener.layerTitle),
              });
            }
          });
        });
      });
    });
  }

  function removeLayerHighlight(layerTitle: string) {
    setLayerHiglights((oldHighlights) => {
      if (oldHighlights[layerTitle]) {
        oldHighlights[layerTitle].remove();
      }
      return oldHighlights;
    });
  }

  function setLayerHighlight(
    layerClickListener: LayerClickListener,
    layerView: __esri.FeatureLayerView,
    graphic: __esri.Graphic,
  ) {
    const { layerTitle, hideHighlight } = layerClickListener;
    if (!hideHighlight && layerView.highlight) {
      setLayerHiglights((oldHighlights) => {
        oldHighlights[layerTitle] = layerView.highlight(graphic);
        return oldHighlights;
      });
    }
  }

  return (
    <div className="map-wrapper" data-testid="map-wrapper" ref={mapElementRef}>
      {customWidgets?.map(({ widget, id }) => (
        <div key={id} id={id}>
          {widget}
        </div>
      ))}
    </div>
  );
}

export function getLayerUrl(layer: __esri.FeatureLayer): string {
  if (!layer.url) return '';
  const layerIdPath = layer.layerId ? `/${layer.layerId}` : '';
  return `${layer.url}${layerIdPath}`;
}

function addLayers(layers: LayerWithIndex[], map?: __esri.Map) {
  layers.forEach((layer) => {
    map?.add(layer.layer, layer.index);
  });
}

function removeLayers(layers: LayerWithIndex[], map?: __esri.Map) {
  layers.forEach((layer) => {
    map?.layers.remove(layer.layer);
  });
}

function createMapView(
  webMap: WebMap,
  mapViewOptions: Omit<__esri.MapViewProperties | undefined, 'map'>,
): __esri.MapView {
  return new MapView({
    map: webMap,
    popup: { autoOpenEnabled: false },
    ...(mapViewOptions || {}),
  });
}

export default ArcGisMap;
