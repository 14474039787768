type Environment = {
  mode: string;
  apiBaseUrl: string;
  oauth2AppId: string;
  oauth2PortalUrl: string;
  basicAuthUsername: string;
  basicAuthPassword: string;
  swirActivityMaxDate?: string;
};

const getEnvironment = (): Environment => {
  if (process.env.NODE_ENV === 'test') {
    // jest test automatically set NODE_ENV
    return {
      mode: 'C3_STAGE',
      apiBaseUrl: 'API_BASE_URL',
      oauth2AppId: 'OAUTH2_APP_ID',
      oauth2PortalUrl: 'OAUTH2_PORTAL_URL',
      basicAuthUsername: 'TMP_BASIC_AUTH_USERNAME',
      basicAuthPassword: 'TMP_BASIC_AUTH_PASSWORD',
      swirActivityMaxDate: undefined,
    };
  }
  return {
    mode: window._env_.C3_STAGE,
    apiBaseUrl: window._env_.API_BASE_URL,
    oauth2AppId: window._env_.OAUTH2_APP_ID,
    oauth2PortalUrl: window._env_.OAUTH2_PORTAL_URL,
    basicAuthUsername: window._env_.TMP_BASIC_AUTH_USERNAME,
    basicAuthPassword: window._env_.TMP_BASIC_AUTH_PASSWORD,
    swirActivityMaxDate: window._env_.SWIR_ACTIVITY_MAX_DATE || undefined,
  };
};

const environment = getEnvironment();

export default environment;
