import { allCountriesPolygons } from './allCountriesPolygons';
import { francePolygon } from './francePolygon';
import { germanyPolygon } from './germanyPolygon';

export default {
  areas_of_operation: [
    {
      ao_polygon: allCountriesPolygons,
      ao_title: 'All Areas of Operation',
      ao_id: 99,
      is_swir_monitored: false,
    },
    {
      ao_polygon: francePolygon,
      ao_title: 'France',
      ao_id: 1,
      is_swir_monitored: true,
    },
    {
      ao_polygon: germanyPolygon,
      ao_title: 'Germany',
      ao_id: 2,
      is_swir_monitored: true,
    },
  ],
};
