import React, { useState } from 'react';

import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import PopupTemplate from '@arcgis/core/PopupTemplate';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import { ReactComponent as RevertArrow } from 'bootstrap-icons/icons/arrow-counterclockwise.svg';
import Button from 'react-bootstrap/Button';
import { createRoot } from 'react-dom/client';
import { useTranslation } from 'react-i18next';

import {
  blackFillSymbol,
  blueFillSymbol,
  brownFillSymbol,
  colorFillSymbol,
  purpleFillSymbol,
  yellowFillSymbol,
} from 'src/components/AnalysisResultsMap/polygons';

export interface AreasVisibility {
  showStorageTank: boolean;
  showUndamagedBuilding: boolean;
  showDamagedBuilding: boolean;
  showDestroyedBuilding: boolean;
  showUnknownBuilding: boolean;
}

const polygonColorRender = (
  areaClassCode: string,
  colorFunction: colorFillSymbol,
) => [
  {
    value: `${areaClassCode}, true`,
    symbol: colorFunction(),
  },
  {
    value: `${areaClassCode}, false`,
    symbol: colorFunction({ isOutlineDashed: true }),
  },
];

export const polygonRenderer = (
  areas: AreasVisibility,
): __esri.UniqueValueRenderer => {
  const storageTankRender = areas.showStorageTank
    ? polygonColorRender('storageTank', yellowFillSymbol)
    : [];
  const undamagedBuildingRender = areas.showUndamagedBuilding
    ? polygonColorRender('undamagedBuilding', blueFillSymbol)
    : [];
  const damagedBuildingRender = areas.showDamagedBuilding
    ? polygonColorRender('damagedBuilding', purpleFillSymbol)
    : [];
  const destroyedBuildingRender = areas.showDestroyedBuilding
    ? polygonColorRender('destroyedBuilding', blackFillSymbol)
    : [];
  const unknownBuildingRender = areas.showUnknownBuilding
    ? polygonColorRender('unknownBuilding', brownFillSymbol)
    : [];
  return new UniqueValueRenderer({
    field: 'areaClassCode',
    field2: 'isValid',
    fieldDelimiter: ', ',
    uniqueValueInfos: [
      ...storageTankRender,
      ...undamagedBuildingRender,
      ...damagedBuildingRender,
      ...destroyedBuildingRender,
      ...unknownBuildingRender,
    ],
  });
};

export const renderer = polygonRenderer({
  showStorageTank: true,
  showUndamagedBuilding: true,
  showDamagedBuilding: true,
  showDestroyedBuilding: true,
  showUnknownBuilding: true,
});

const fieldSchema: Partial<__esri.Field>[] = [
  {
    name: 'areaID',
    type: 'integer',
    defaultValue: '',
  },
  {
    name: 'areaSource',
    type: 'string',
    defaultValue: '',
  },
  {
    name: 'areaClassCode',
    type: 'string',
    defaultValue: '',
  },
  {
    name: 'areaClassName',
    type: 'string',
    defaultValue: '',
  },
  {
    name: 'isValid',
    type: 'string',
    defaultValue: 'true',
  },
];

type areaValidationFunction = (areaID: number, isValid: boolean) => void;

export const newAreaValidationLayer = (
  id: string,
  areas: __esri.Graphic[],
  changeAreaValidation: areaValidationFunction,
  title: string,
): __esri.FeatureLayer => {
  const featureLayer = new FeatureLayer({
    id: id,
    source: areas,
    geometryType: 'polygon',
    objectIdField: 'ObjectID',
    renderer: renderer,
    outFields: ['*'],
    fields: fieldSchema,
    spatialReference: SpatialReference.WGS84,
  });
  featureLayer.popupTemplate = generatePopupTemplate(
    featureLayer,
    changeAreaValidation,
    title,
  );
  featureLayer.when().then(async () => {
    // We need to update the feature of the layer because on first draw polygons border are not all visible. The last border (between first and last coordinate) is absent. Applying this edit fixes this issue.
    await featureLayer.applyEdits({ updateFeatures: areas });
  });
  return featureLayer;
};

export function generatePopupTemplate(
  featureLayer: __esri.FeatureLayer,
  changeAreaValidation: areaValidationFunction,
  title: string,
): __esri.PopupTemplate {
  return new PopupTemplate({
    title,
    content: function (feature: { graphic: __esri.Graphic }) {
      const graphicID = feature.graphic.getObjectId();
      return generatePopupContent(
        featureLayer,
        graphicID,
        changeAreaValidation,
      );
    },
    overwriteActions: true,
  });
}

function generatePopupContent(
  featureLayer: __esri.FeatureLayer,
  graphicID: number,
  changeAreaValidation: areaValidationFunction,
): HTMLElement {
  const selectedGraphic = featureLayer.source.find(
    (graphic) => graphic.getObjectId() === graphicID,
  );

  const setIsValid = async (isAreaValid: boolean): Promise<void> => {
    selectedGraphic.setAttribute('isValid', isAreaValid ? 'true' : 'false');
    return featureLayer
      .applyEdits({ updateFeatures: [selectedGraphic] })
      .then(() => {
        changeAreaValidation(
          selectedGraphic.getAttribute('areaID'),
          isAreaValid,
        );
      });
  };

  const isValid = selectedGraphic.getAttribute('isValid');
  const popupContentNode = document.createElement('div');
  const root = createRoot(popupContentNode);

  root.render(
    <ValidationPopupContent
      isValid={isValid !== 'false'}
      setIsValid={setIsValid}
    />,
  );
  popupContentNode.setAttribute(
    'data-testid',
    'analysis-results-validation-popup',
  );
  return popupContentNode;
}

const ValidationPopupContent = (props: {
  setIsValid: (isValid: boolean) => Promise<void>;
  isValid: boolean;
}): React.ReactElement => {
  const [isInvalidated, setIsInvalidated] = useState(!props.isValid);
  const { t } = useTranslation();

  return (
    <>
      {isInvalidated && (
        <>
          <span>
            {t('analysisResultsPanel.validationPopup.detectionMarkedAsError')}
          </span>
          <Button
            className="mt-2 d-flex align-items-center"
            variant="secondary"
            size="sm"
            onClick={() => {
              setIsInvalidated(false);
              props.setIsValid(true);
            }}
          >
            <RevertArrow className="mr-1" />
            {t('analysisResultsPanel.validationPopup.revert')}
          </Button>
        </>
      )}
      {!isInvalidated && (
        <Button
          size={'sm'}
          onClick={() => {
            setIsInvalidated(true);
            props.setIsValid(false);
          }}
        >
          {t('analysisResultsPanel.validationPopup.markAsDetectionError')}
        </Button>
      )}
    </>
  );
};
