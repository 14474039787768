import { PropsWithChildren } from 'react';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { ReactComponent as PreligensLogo } from 'src/assets/images/preligens-logo.svg';

import './index.scss';

const PublicPageLayout = ({ children }: PropsWithChildren) => {
  return (
    <Container fluid>
      <Row className="vh-100 d-flex justify-content-center">
        <Col sm={6} lg={4}>
          <PreligensLogo className="logo" />
          {children}
        </Col>
      </Row>
    </Container>
  );
};

export default PublicPageLayout;
