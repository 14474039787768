import React, { useState, useEffect } from 'react';

import { ReactComponent as ArrowBarLeft } from 'bootstrap-icons/icons/arrow-bar-left.svg';
import { ReactComponent as ArrowBarRight } from 'bootstrap-icons/icons/arrow-bar-right.svg';
import classNames from 'classnames';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/esm/Button';
import { useTranslation } from 'react-i18next';

import { LegendColorType } from 'src/components/AnalysisResultsMap/polygons';
import MapLegendVisibilityButton from 'src/components/MapLegendVisibilityButton';

import './index.scss';

export enum LegendType {
  Polygon,
  PowerPlant,
}

type LegendItemType = {
  title: string;
  colors: LegendColorType;
  legendType: LegendType;
  visibility?: {
    toggleVisitiblity: () => void;
    isVisible: boolean;
  };
};

export type LegendCategory = {
  categoryTitle: string;
  items: LegendItemType[];
};

const MapLegend = ({
  legendCategories,
  changeLegendDisplay,
}: {
  legendCategories: LegendCategory[];
  changeLegendDisplay?: (isVisible: boolean) => void;
}): React.ReactElement => {
  const { t } = useTranslation();
  const [isLegendVisible, setLegendVisibility] = useState<boolean>(true);

  useEffect(() => {
    if (changeLegendDisplay && changeLegendDisplay !== undefined) {
      changeLegendDisplay(isLegendVisible);
    }
  }, [isLegendVisible]);

  const LegendIcon = (legendItemType: LegendItemType) => {
    switch (legendItemType.legendType) {
      case LegendType.Polygon: {
        return (
          <dt
            className="legendList__itemSymbol"
            style={{
              background: `rgba(${legendItemType.colors.fillColor})`,
              borderColor: `rgba(${legendItemType.colors.outlineColor})`,
            }}
          />
        );
      }
      case LegendType.PowerPlant:
        return (
          <dt
            className="legendList__powerPlantSymbol"
            style={{
              background: `rgba(${legendItemType.colors.outlineColor})`,
            }}
          />
        );
    }
  };

  if (!isLegendVisible) {
    return (
      <div className="legend-button" data-testid="map-legend-closed">
        <Button
          size="sm"
          className="mapButton legendButton"
          onClick={() => setLegendVisibility(true)}
        >
          <ArrowBarLeft />
        </Button>
      </div>
    );
  }

  return (
    <div className="legend" data-testid="map-legend-open">
      <Card className="legend__card" style={{ width: '100%' }}>
        <Card.Body>
          <Card.Title className="legend__title">
            {t('map.legend.title')}
            <Button
              size="sm"
              className="mapButton"
              onClick={() => setLegendVisibility(false)}
            >
              <ArrowBarRight />
            </Button>
          </Card.Title>
          {legendCategories.map((category, index) => {
            return (
              <React.Fragment key={index}>
                <Card.Subtitle
                  className={classNames('text-muted mb-1', {
                    'mt-1': index === 0,
                    'mt-2': index > 0,
                  })}
                >
                  <small>{category.categoryTitle}</small>
                </Card.Subtitle>

                <dl className="legendList">
                  {category.items.map((legendItemType, i) => {
                    return (
                      <React.Fragment key={i}>
                        {LegendIcon(legendItemType)}
                        <dd className="legendList__itemTitle">
                          {legendItemType.title}
                          {legendItemType.visibility && (
                            <MapLegendVisibilityButton
                              onVisibilityToggle={
                                legendItemType.visibility.toggleVisitiblity
                              }
                              isVisible={legendItemType.visibility.isVisible}
                            />
                          )}
                        </dd>
                      </React.Fragment>
                    );
                  })}
                </dl>
              </React.Fragment>
            );
          })}
        </Card.Body>
      </Card>
    </div>
  );
};

export default MapLegend;
