import MapView from '@arcgis/core/views/MapView';
import ScaleBar from '@arcgis/core/widgets/ScaleBar';

export function addScaleBar(
  mapView: MapView,
  position?: __esri.UIAddPosition,
): void {
  const scaleBarWidget = new ScaleBar({
    view: mapView,
  });
  mapView.ui.add(scaleBarWidget, position);
}
