import React, { useEffect } from 'react';

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import './App.scss';

import { APIProvider } from 'src/api/APIContext';
import { useAuth } from 'src/auth/AuthContext';
import ProtectedRoute from 'src/auth/ProtectedRoute';
import { dashboardPath, widgetPath } from 'src/auth/routes';
import SecurityProvider from 'src/auth/SecurityProvider';
import FullScreenSpinner from 'src/components/FullScreenSpinner';
import { ConfigProvider, useConfig } from 'src/config/ConfigContext';
import { FileAnalysisProvider } from 'src/fileAnalysis/fileAnalysisContext';
import LogProvider from 'src/log/LogProvider';
import UnauthorizedModal from 'src/modals/UnauthorizedModal';
import { NotificationProvider } from 'src/notification/NotificationProvider';
import Dashboard from 'src/pages/Dashboard';
import { DashboardProvider } from 'src/pages/Dashboard/context';
import Login from 'src/pages/Login';
import Unavailable from 'src/pages/Unavailable';
import Widget from 'src/pages/Widget';

const AppContent: React.FC = (): React.ReactElement => {
  const { isFetchingCredential, setItemsAccessCheck } = useAuth();
  const { isError, isFetchingConfig, config } = useConfig();

  useEffect(() => {
    if (config && config.arcgis_webmap_id) {
      setItemsAccessCheck([config.arcgis_webmap_id]);
    }
  }, [config]);

  if (isError) {
    return <Unavailable />;
  }

  if (isFetchingConfig || isFetchingCredential) {
    return <FullScreenSpinner />;
  }

  return (
    <APIProvider>
      <DashboardProvider>
        <NotificationProvider>
          <main className="main">
            <Router>
              <FileAnalysisProvider>
                <LogProvider>
                  <Switch>
                    <Route exact path="/" component={Login} />
                    <ProtectedRoute
                      path={dashboardPath}
                      component={Dashboard}
                    />
                    <ProtectedRoute path={widgetPath} component={Widget} />
                    <Redirect to={dashboardPath} />
                  </Switch>
                </LogProvider>
              </FileAnalysisProvider>
            </Router>
            <UnauthorizedModal />
          </main>
        </NotificationProvider>
      </DashboardProvider>
    </APIProvider>
  );
};

const App = () => {
  return (
    <ConfigProvider>
      <SecurityProvider>
        <AppContent />
      </SecurityProvider>
    </ConfigProvider>
  );
};

export default App;
