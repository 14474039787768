export const config = {
  arcgis_webmap_id: '7ce8cf9e7168497aa56d022965283cef',
  arcgis_webmap_pp_title: 'export_power_plant',
  arcgis_webmap_footprints_title: 'export_footprints',
  arcgis_fs_url_swir_activity:
    'https://services7.arcgis.com/YKRtfHOU5bA85Ydi/arcgis/rest/services/preligens_egm_swir_20211015-133321/FeatureServer/0',
  arcgis_fs_url_swir_chimney_activity:
    'https://services7.arcgis.com/YKRtfHOU5bA85Ydi/arcgis/rest/services/preligens_egm_swir_20211015-133321/FeatureServer/1',
  arcgis_insights: {
    overview_pai: '73bf20904f9a4a198a63f5f88cea15eb',
    lines: '4e77a2cb072044e590159475ee05e0d9',
    consumption: '0fadbc63c6984504a6d69d30c7cd538c',
    dependency: '23a599d4cf694b1c901351a285f1e89d',
    evaluation: 'df1fc69347dc403a9868f8ee54165a20',
    social_media: '7af6453182cd48a685e824adb9f075f7',
  },
};
