import React, { useState } from 'react';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useTranslation } from 'react-i18next';

type numberOfMonth = number;

interface Props {
  timeframeOptions: numberOfMonth[];
  defaultValue: numberOfMonth;
  onSelect: (value: numberOfMonth) => void;
}

const TimeframeSelector = ({
  timeframeOptions,
  defaultValue,
  onSelect,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const [selectedTimeframe, setselectedTimeframe] = useState(defaultValue);

  function selectTimeframe(value: numberOfMonth) {
    if (value !== selectedTimeframe) {
      setselectedTimeframe(value);
      onSelect(value);
    }
  }

  return (
    <ButtonGroup size="sm">
      {timeframeOptions.map((value) => {
        const isSelected = value === selectedTimeframe;
        let label = t('timeframe.lastMonthWithCount_plural', { count: value });
        if (value <= 1) {
          label = t('timeframe.lastMonthWithCount', { count: value });
        }
        return (
          <Button
            key={value}
            variant={isSelected ? 'primary' : 'light'}
            onClick={() => selectTimeframe(value)}
            className={'pt-0 pb-0'}
          >
            {label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default TimeframeSelector;
