import React, { FormEvent, useEffect, useState } from 'react';

import QueryTask from '@arcgis/core/tasks/QueryTask';
import Query from '@arcgis/core/tasks/support/Query';
import { ReactComponent as ArrowLeft } from 'bootstrap-icons/icons/arrow-left.svg';
import { Polygon as GeoJsonPolygon, FeatureCollection } from 'geojson';
import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Navbar from 'react-bootstrap/Navbar';
import { useTranslation } from 'react-i18next';

import { useApi } from 'src/api/APIContext';
import {
  RunResult,
  isValidRunResult,
  RunValidation,
  ResultAreaProperty,
} from 'src/api/internalApi';
import AnalysisResultsMap from 'src/components/AnalysisResultsMap';
import AnalysisResultsPowerPlantClassForm from 'src/components/AnalysisResultsPowerPlantClassForm';
import {
  PowerPlantClass,
  toPowerPlantClass,
} from 'src/components/AnalysisResultsPowerPlantClassForm/powerPlantClass';
import LoadingSpinner from 'src/components/LoadingSpinner';

import './index.scss';

interface Props {
  onDiscard: () => void;
  onValidate: () => void;
  runUUID: string;
  footprintsLayerUrl?: string;
}

const AnalysisResultsPanel = ({
  onDiscard: closePanel,
  runUUID,
  onValidate,
  footprintsLayerUrl,
}: Props): React.ReactElement => {
  const httpClient = useApi();
  const { t } = useTranslation();
  const [invalidAreaIDs, setInvalidAreaIDs] = useState<number[]>([]);
  const [selectedPowerPlantClass, setSelectedPowerPlantClass] =
    useState<PowerPlantClass>();
  const [runResult, setRunResult] = useState<RunResult>();
  const [isLoading, setLoading] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isQueryFailedMessage, setQueryFailMessage] = useState('');
  const [isValidationFailedMessage, setValidationFailedMessage] = useState('');
  const [footprintCoordinates, setFootprintCoordinates] =
    useState<number[][]>();

  function changeAreaValidation(areaID: number, isValid: boolean): void {
    if (isValid) {
      setInvalidAreaIDs((existing) =>
        existing.filter((invalidAreaID) => invalidAreaID !== areaID),
      );
    } else {
      setInvalidAreaIDs((existing) => [...existing, areaID]);
    }
  }

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setValidationFailedMessage('');
    setSubmitting(true);
    if (!runResult || !selectedPowerPlantClass) return;

    const validatedFeatureCollection: FeatureCollection<
      GeoJsonPolygon,
      ResultAreaProperty
    > = {
      type: 'FeatureCollection',
      features: runResult.pai_vhr_fusioned.features.map((area) => {
        area.properties.is_validated = !invalidAreaIDs.includes(
          area.properties.id,
        );
        return area;
      }),
    };

    try {
      await postValidatedResults(
        runUUID,
        validatedFeatureCollection,
        selectedPowerPlantClass,
      );
      onValidate();
    } catch (error) {
      setValidationFailedMessage(error as string);
      setSubmitting(false);
    }
  }

  function getResults(_runUUID: string) {
    setLoading(true);
    setQueryFailMessage('');
    queryRunResults(_runUUID)
      .then((res) => {
        setRunResult(res);
        setSelectedPowerPlantClass(toPowerPlantClass(res.power_plant_type));
        return res.site_uuid;
      })
      .catch((errorMessage) => {
        setQueryFailMessage(errorMessage);
        setRunResult(undefined);
      })
      .then(async (siteUUID) => {
        if (!siteUUID) throw new Error('Error retrieving results data');
        const fetchedFootprintCoordinates =
          await getFootprintCoordinates(siteUUID);
        setFootprintCoordinates(fetchedFootprintCoordinates);
      })
      .catch((error) => {
        console.error('Error retrieving site footprint:', error);
      })
      .finally(() => setLoading(false));
  }

  async function getFootprintCoordinates(
    siteUUID: string,
  ): Promise<number[][]> {
    if (!footprintsLayerUrl) throw new Error('Missing footprints layer URL');
    const queryTask = new QueryTask({
      url: footprintsLayerUrl,
    });

    const query = new Query({
      outFields: ['site_uuid'],
      where: `site_uuid='${siteUUID}'`,
      returnGeometry: true,
    });

    const queryResponse = await queryTask.execute(query);
    if (!queryResponse.features[0]) {
      throw new Error(`Unknown site with UUID ${siteUUID}`);
    }
    const geometry = queryResponse.features[0].geometry.toJSON();
    if (!geometry.rings[0]) {
      throw new Error(`Unavailable footprint on site with UUID ${siteUUID}`);
    }
    return geometry.rings[0];
  }

  async function queryRunResults(_runUUID: string): Promise<RunResult> {
    const defaultErrorMessage = t('analysisResultsPanel.errorRequest');
    try {
      const res = await httpClient.get<RunResult>(`/vhr/${_runUUID}/results`);
      if (isValidRunResult(res.data)) {
        return res.data;
      } else {
        throw defaultErrorMessage;
      }
    } catch {
      throw defaultErrorMessage;
    }
  }

  useEffect(() => {
    getResults(runUUID);
  }, [runUUID]);

  async function postValidatedResults(
    _runUUID: string,
    validatedAreas: FeatureCollection<GeoJsonPolygon, ResultAreaProperty>,
    powerPlantClass: PowerPlantClass,
  ): Promise<void> {
    const body: RunValidation = {
      power_plant_type_validated: powerPlantClass,
      pai_vhr_validated: validatedAreas,
    };
    try {
      const res = await httpClient.post<{ error: string }>(
        `/vhr/${_runUUID}/validate`,
        body,
      );
      if (res.data && res.data.error) {
        throw res.data.error;
      }
    } catch {
      throw t('analysisValidation.validationError');
    }
  }

  return (
    <div className="analysisResultsPanel" data-testid="analysis-results-panel">
      <Navbar>
        <Button
          data-testid="analysis-results-panel-close-button"
          variant="link"
          onClick={() => closePanel()}
        >
          <ArrowLeft /> {t('analysisResultsPanel.discardChanges')}
        </Button>
      </Navbar>
      <Card className="w-100 h-100 rounded-0">
        <Card.Header>
          <h6 className="m-0">
            {runResult
              ? (runResult.site_name ||
                  t('analysisResultsPanel.unknownSiteName')) + ' - '
              : ''}
            {t('analysisResultsPanel.analysisResults')}
          </h6>
        </Card.Header>
        <Card.Body className="results">
          <>
            {isLoading && <LoadingSpinner />}

            {isQueryFailedMessage && (
              <div
                className="w-100 m-2"
                data-testid="analysis-results-panel-query-error"
              >
                <Alert variant="danger">
                  {`${isQueryFailedMessage} `}
                  <Alert.Link onClick={() => getResults(runUUID)}>
                    {t('analysisResultsPanel.tryAgain')}
                  </Alert.Link>
                </Alert>
              </div>
            )}

            {!isLoading && !isQueryFailedMessage && runResult && (
              <>
                <div className="resultsMap">
                  <AnalysisResultsMap
                    runUUID={runUUID}
                    resultAreaList={runResult.pai_vhr_fusioned.features}
                    changeAreaValidation={changeAreaValidation}
                    footprintCoordinates={footprintCoordinates}
                  />
                </div>
                <div className="resultsPanel">
                  <div className="resultsPanel__notice">
                    <p>{t('analysisResultsPanel.warning')}</p>
                    <p>{t('analysisResultsPanel.notice')}</p>
                  </div>
                  <div className="resultsPanel__form">
                    <AnalysisResultsPowerPlantClassForm
                      predictedPowerPlantClass={toPowerPlantClass(
                        runResult.power_plant_type,
                      )}
                      onPowerPlantClassChange={setSelectedPowerPlantClass}
                      disableSubmit={!selectedPowerPlantClass || isSubmitting}
                      onSubmit={onSubmit}
                      isSubmitting={isSubmitting}
                      submitFailedErrorMessage={isValidationFailedMessage}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AnalysisResultsPanel;
