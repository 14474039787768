export const results = {
  site_name: 'The site name',
  power_plant_type: 'power-uranium',
  pai_vhr_fusioned: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        id: 1738,
        geometry: {
          type: 'Polygon',
          crs: {
            type: 'name',
            properties: {
              name: 'EPSG:4326',
            },
          },
          coordinates: [
            [
              [10, 10],
              [10.4, 10],
              [10.4, 10.4],
              [10, 10.4],
            ],
          ],
        },
        properties: {
          id: 1738,
          level1: 'building',
          level2: 'building-nodamage',
          run_uuid: '5c572e8b-0602-451b-9e18-f1a74a533b7c',
          site_uuid: '63561ebb-43cd-4113-8b49-d45f4f90e25e',
          source: ['vbml'],
          source_geometry: 'vbml',
        },
      },

      {
        type: 'Feature',
        id: 1739,
        geometry: {
          type: 'Polygon',
          crs: {
            type: 'name',
            properties: {
              name: 'EPSG:4326',
            },
          },
          coordinates: [
            [
              [10.5, 10],
              [10.9, 10],
              [10.9, 10.4],
              [10.5, 10.4],
            ],
          ],
        },
        properties: {
          id: 1739,
          level1: 'building',
          level2: 'building-damaged',
          run_uuid: '5c572e8b-0602-451b-9e18-f1a74a533b7c',
          site_uuid: '63561ebb-43cd-4113-8b49-d45f4f90e25e',
          source: ['vbml'],
          source_geometry: 'vbml',
        },
      },

      {
        type: 'Feature',
        id: 1740,
        geometry: {
          type: 'Polygon',
          crs: {
            type: 'name',
            properties: {
              name: 'EPSG:4326',
            },
          },
          coordinates: [
            [
              [10, 10.5],
              [10.4, 10.5],
              [10.4, 10.9],
              [10, 10.9],
            ],
          ],
        },
        properties: {
          id: 1740,
          level1: 'building',
          level2: 'building-destroyed',
          run_uuid: '5c572e8b-0602-451b-9e18-f1a74a533b7c',
          site_uuid: '63561ebb-43cd-4113-8b49-d45f4f90e25e',
          source: ['pai'],
          source_geometry: 'vbml',
        },
      },

      {
        type: 'Feature',
        id: 1741,
        geometry: {
          type: 'Polygon',
          crs: {
            type: 'name',
            properties: {
              name: 'EPSG:4326',
            },
          },
          coordinates: [
            [
              [10.5, 10.5],
              [10.9, 10.5],
              [10.9, 10.9],
              [10.5, 10.9],
            ],
          ],
        },
        properties: {
          id: 1741,
          level1: 'tank',
          run_uuid: '5c572e8b-0602-451b-9e18-f1a74a533b7c',
          site_uuid: '63561ebb-43cd-4113-8b49-d45f4f90e25e',
          source: ['vbml', 'pai'],
          source_geometry: 'vbml',
        },
      },

      {
        type: 'Feature',
        id: 1742,
        geometry: {
          type: 'Polygon',
          crs: {
            type: 'name',
            properties: {
              name: 'EPSG:4326',
            },
          },
          coordinates: [
            [
              [11, 10],
              [11.4, 10],
              [11.4, 10.4],
              [11, 10.4],
            ],
          ],
        },
        properties: {
          id: 1742,
          level1: 'building',
          run_uuid: '5c572e8b-0602-451b-9e18-f1a74a533b7c',
          site_uuid: '63561ebb-43cd-4113-8b49-d45f4f90e25e',
          source: ['vbml', 'pai'],
          source_geometry: 'vbml',
        },
      },

      {
        type: 'Feature',
        id: 1743,
        geometry: {
          type: 'Polygon',
          crs: {
            type: 'name',
            properties: {
              name: 'EPSG:4326',
            },
          },
          coordinates: [
            [
              [10, 11],
              [10.4, 11],
              [10.4, 11.4],
              [10, 11.4],
            ],
          ],
        },
        properties: {
          id: 1743,
          level1: 'building',
          level2: 'building-nodamage',
          run_uuid: '5c572e8b-0602-451b-9e18-f1a74a533b7c',
          site_uuid: '63561ebb-43cd-4113-8b49-d45f4f90e25e',
          source: ['vbml'],
          source_geometry: 'vbml',
        },
      },

      {
        type: 'Feature',
        id: 1744,
        geometry: {
          type: 'Polygon',
          crs: {
            type: 'name',
            properties: {
              name: 'EPSG:4326',
            },
          },
          coordinates: [
            [
              [10.5, 11],
              [10.9, 11],
              [10.9, 11.4],
              [10.5, 11.4],
            ],
          ],
        },
        properties: {
          id: 1744,
          level1: 'building',
          level2: 'building-damaged',
          run_uuid: '5c572e8b-0602-451b-9e18-f1a74a533b7c',
          site_uuid: '63561ebb-43cd-4113-8b49-d45f4f90e25e',
          source: ['vbml'],
          source_geometry: 'vbml',
        },
      },

      {
        type: 'Feature',
        id: 1745,
        geometry: {
          type: 'Polygon',
          crs: {
            type: 'name',
            properties: {
              name: 'EPSG:4326',
            },
          },
          coordinates: [
            [
              [10, 11.5],
              [10.4, 11.5],
              [10.4, 11.9],
              [10, 11.9],
            ],
          ],
        },
        properties: {
          id: 1745,
          level1: 'building',
          level2: 'building-destroyed',
          run_uuid: '5c572e8b-0602-451b-9e18-f1a74a533b7c',
          site_uuid: '63561ebb-43cd-4113-8b49-d45f4f90e25e',
          source: ['pai'],
          source_geometry: 'vbml',
        },
      },

      {
        type: 'Feature',
        id: 1746,
        geometry: {
          type: 'Polygon',
          crs: {
            type: 'name',
            properties: {
              name: 'EPSG:4326',
            },
          },
          coordinates: [
            [
              [10.5, 11.5],
              [10.9, 11.5],
              [10.9, 11.9],
              [10.5, 11.9],
            ],
          ],
        },
        properties: {
          id: 1746,
          level1: 'tank',
          run_uuid: '5c572e8b-0602-451b-9e18-f1a74a533b7c',
          site_uuid: '63561ebb-43cd-4113-8b49-d45f4f90e25e',
          source: ['vbml', 'pai'],
          source_geometry: 'vbml',
        },
      },

      {
        type: 'Feature',
        id: 1747,
        geometry: {
          type: 'Polygon',
          crs: {
            type: 'name',
            properties: {
              name: 'EPSG:4326',
            },
          },
          coordinates: [
            [
              [11, 11],
              [11.4, 11],
              [11.4, 11.4],
              [11, 11.4],
            ],
          ],
        },
        properties: {
          id: 1747,
          level1: 'building',
          run_uuid: '5c572e8b-0602-451b-9e18-f1a74a533b7c',
          site_uuid: '63561ebb-43cd-4113-8b49-d45f4f90e25e',
          source: ['vbml', 'pai'],
          source_geometry: 'vbml',
        },
      },
    ],
  },
};
