import React, { useEffect, useState } from 'react';

import axios, { AxiosError } from 'axios';
import { ReactComponent as BoxArrowUpRight } from 'bootstrap-icons/icons/box-arrow-up-right.svg';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Spinner from 'react-bootstrap/Spinner';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';

import { useApi } from 'src/api/APIContext';
import { SurveyStatus, LatestSurveyResponse } from 'src/api/internalApi';
import ContentErrorAndDetail from 'src/components/Notification/ContentErrorAndDetail';
import useSurvey123Handler, {
  SEND_EXPORT_ERROR,
  STATUS_EXPORT_DONE,
  STATUS_EXPORT_ERROR,
} from 'src/components/SurveyExportControl/useSurvey123Export';
import {
  NotificationType,
  useNotification,
} from 'src/notification/NotificationProvider';

import './index.scss';

interface Props {
  surveyName: string;
  surveyKey: string;
}

const SurveyExportControl = ({
  surveyKey,
  surveyName,
  ...props
}: Props): React.ReactElement => {
  const [surveyStatus, setSurveyStatus] = useState<SurveyStatus>();
  const [isRunning, setIsRunning] = useState(false);
  const httpClient = useApi();
  const { t } = useTranslation();
  const { showNotification, removeNotificationByID } = useNotification();
  const { state, exportToSurvey123, fetchSurveyStatus } = useSurvey123Handler();
  const isExporting = state.isExporting;

  useEffect(() => {
    switch (state.status) {
      case SEND_EXPORT_ERROR:
        removeInProgressNotification();
        showExportErrorNotifications([state.sendSurveyExportError || '']);
        break;
      case STATUS_EXPORT_ERROR:
        removeInProgressNotification();
        showExportErrorNotifications(state.statusSurveyExportErrors || []);
        break;
      case STATUS_EXPORT_DONE:
        removeInProgressNotification();
        showExportSuccessNotification();
        break;
    }
    async function getSurveyStatus() {
      const currentSurveyStatus = await fetchSurveyStatus(surveyKey);
      setSurveyStatus(currentSurveyStatus);
    }
    getSurveyStatus();
  }, [state.status]);

  const showInProgressNotification = (): string =>
    showNotification({
      title: t('survey123.exportNotifications.progress.title'),
      content: t('survey123.exportNotifications.progress.content', {
        surveyName,
      }),
      type: NotificationType.InProgress,
      hideClose: true,
      dataTestID: 'toast-export-survey-progress',
    });

  const removeInProgressNotification = () =>
    state.surveyExportProgressNotificationUUID &&
    removeNotificationByID(state.surveyExportProgressNotificationUUID);

  const showExportSuccessNotification = () =>
    showNotification({
      title: t('survey123.exportNotifications.success.title'),
      content: t('survey123.exportNotifications.success.content', {
        surveyName,
      }),
      type: NotificationType.Success,
      dataTestID: 'toast-export-survey-success',
    });

  const showExportErrorNotifications = (errors: string[]) => {
    const cleanedErrors = errors.filter((error) => !!error);
    if (!cleanedErrors.length)
      cleanedErrors.push(t('misc.errors.unexpectedError'));
    cleanedErrors.forEach(showExportErrorNotification);
  };

  const showExportErrorNotification = (errorMessage: string) =>
    showNotification({
      title: t('survey123.exportNotifications.error.title'),
      content: ContentErrorAndDetail(
        t('survey123.exportNotifications.error.content', { surveyName }),
        errorMessage,
      ),
      type: NotificationType.Error,
      dataTestID: 'toast-export-survey-error',
    });

  const handleExport = () => {
    exportToSurvey123(surveyKey, showInProgressNotification());
  };

  const findLatestSurveyID = async (): Promise<string> => {
    try {
      const { data } = await httpClient.get<LatestSurveyResponse>(
        `/survey/${surveyKey}/latest`,
      );
      return data.id;
    } catch (error) {
      let errorMessage = t('misc.errors.unexpectedError');
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;
        if (axiosError.response?.status === 404) {
          errorMessage = t(
            'survey123.openLatestNotifications.error.noSurveyFound',
            { surveyName },
          );
        }
      }
      showNotification({
        title: t('survey123.openLatestNotifications.error.title'),
        content: ContentErrorAndDetail(errorMessage),
        type: NotificationType.Error,
        dataTestID: 'toast-see-survey-error',
      });
      return '';
    }
  };

  const openLatestExport = async () => {
    if (isRunning) return;
    setIsRunning(true);
    const latestSurveyId = await findLatestSurveyID();
    if (latestSurveyId) {
      const surveyURL = createSurveyURL(latestSurveyId);
      window.open(surveyURL, '_blank');
    }
    setIsRunning(false);
  };

  const createSurveyURL = (surveyID: string): string =>
    `https://survey123.arcgis.com/surveys/${surveyID}/data`;

  return (
    <Dropdown as={ButtonGroup} size="sm" {...props}>
      <Button
        disabled={isExporting}
        onClick={handleExport}
        variant="brand-dark"
        data-testid="survey-export-button"
      >
        {isExporting && (
          <Spinner className="mr-2" animation="border" size="sm" />
        )}
        {t('navBar.exportToSurvey123', { surveyName })}
        {surveyStatus === 'outdated' && (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="show-export-notification">
                {t('navBar.exportBadgeTooltip')}
              </Tooltip>
            }
          >
            <span
              className="notificationBadge"
              data-testid="survey-export-outdated-badge"
            />
          </OverlayTrigger>
        )}
      </Button>
      <Dropdown.Toggle
        split
        variant="brand-dark"
        data-testid="survey-export-toggle"
      />
      <Dropdown.Menu data-testid="survey-export-menu">
        <Dropdown.Item
          as="button"
          className="d-flex align-items-center"
          onClick={openLatestExport}
          data-testid="open-latest-survey-button"
        >
          {t('navBar.seeLatestSurvey123Export')}
          <BoxArrowUpRight width="14" height="14" className="ml-2" />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SurveyExportControl;
