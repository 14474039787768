import React from 'react';

import { ReactComponent as ArrowRepeat } from 'bootstrap-icons/icons/arrow-repeat.svg';
import { ReactComponent as ExclamationTriangle } from 'bootstrap-icons/icons/exclamation-triangle.svg';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';

import PublicPageLayout from 'src/layouts/PublicPageLayout';

const Unavailable: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();

  function refreshPage() {
    window.location.reload();
  }

  return (
    <PublicPageLayout>
      <Card>
        <Card.Header className="d-flex align-items-center">
          <ExclamationTriangle width="21" height="21" className="mr-2" />
          <h4 className="m-0">{t('unavailable.title')}</h4>
        </Card.Header>
        <Card.Body className="d-flex flex-column justify-content-center">
          <p>{t('unavailable.text')}</p>
          <Button
            className="d-flex align-items-center justify-content-center"
            variant="brand-dark"
            block
            onClick={refreshPage}
          >
            <ArrowRepeat className="mr-2" />
            {t('unavailable.button')}
          </Button>
        </Card.Body>
      </Card>
    </PublicPageLayout>
  );
};

export default Unavailable;
