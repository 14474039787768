import { filter, groupBy, meanBy, camelCase, sortBy, mapKeys } from 'lodash';
import moment from 'moment';

import {
  ChimneyActivity,
  SiteActivity,
  WeeklySwirActivity,
} from 'src/models/swir.interface';

interface GroupedActivities<T> {
  [key: string]: T[];
}
export function groupByWeek<T extends SiteActivity | ChimneyActivity>(
  data: SiteActivity[] | ChimneyActivity[],
): GroupedActivities<T> {
  const filteredData = filter(data, (item: T) => item && item.streamDate);
  return groupBy(filteredData, (item: T) => {
    const itemFirstDayOfWeek = moment(new Date(item.streamDate)).weekday(0);
    return itemFirstDayOfWeek.format('YYYY MM DD');
  }) as GroupedActivities<T>;
}

export const processData = <T extends SiteActivity | ChimneyActivity>(
  data: SiteActivity[] | ChimneyActivity[],
  averageFields: (keyof SiteActivity)[] | (keyof ChimneyActivity)[],
): WeeklySwirActivity[] => {
  const groupedWeeklyActivity = groupByWeek<T>(data);
  const allFields = Object.values(groupedWeeklyActivity).map((weeklyData) => {
    const fields: Partial<WeeklySwirActivity> = {};
    averageFields.forEach(
      (field: keyof SiteActivity | keyof ChimneyActivity) => {
        if (field !== 'streamDate') {
          fields[field] = meanBy(weeklyData, field);
        }
      },
    );
    weeklyData.sort((a, b) => a.streamDate - b.streamDate);
    const firstDate = weeklyData[0].streamDate;
    fields.firstWeekDate = firstDate;
    fields.firstWeekDay = moment(firstDate).format('MM/DD/YY');
    return fields;
  });
  return sortBy(allFields as WeeklySwirActivity[], 'firstWeekDate');
};

export const flattenAndCamelCase = <T>(data: __esri.FeatureSet): T[] => {
  return data.features.map((graphic: __esri.Graphic) => {
    return mapKeys(graphic.attributes, (_v, k) => camelCase(k)) as T;
  });
};
