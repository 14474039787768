import Extent from '@arcgis/core/geometry/Extent';
import BaseTileLayer from '@arcgis/core/layers/BaseTileLayer';
import esriRequest from '@arcgis/core/request';

import { SourceImageBounds } from '../models';
import {
  mercatorXfromLng,
  mercatorYfromLat,
  validateBounds,
} from '../utils/geo.utils';

export class CustomLayer extends BaseTileLayer {
  buildTileUrl: (level: number, row: number, col: number) => string;
  requestHeader?: () => { [key: string]: string };
  bounds?: GeoJSON.BBox;
  boundsExtent: Extent;
  minZoom;
  maxZoom;

  constructor({
    buildTileUrl,
    requestHeader,
    bounds,
    ...properties
  }: __esri.BaseTileLayerProperties & {
    buildTileUrl: (level: number, row: number, col: number) => string;
    requestHeader?: () => { [key: string]: string };
    bounds?: SourceImageBounds;
  }) {
    super(properties);
    this.buildTileUrl = buildTileUrl;
    this.requestHeader = requestHeader;
    this.minZoom = bounds?.minZoom ?? 0;
    this.maxZoom = bounds?.maxZoom ?? 24;
    this.bounds = bounds?.bounds;
    this.boundsExtent = this.buildBoundsExtent(validateBounds(bounds?.bounds));
  }

  buildBoundsExtent(bounds: [number, number, number, number]): Extent {
    return new Extent({
      xmin: bounds[0],
      ymin: bounds[1],
      xmax: bounds[2],
      ymax: bounds[3],
      spatialReference: {
        wkid: 3857,
      },
    });
  }

  contains(z: number, y: number, x: number): boolean {
    const worldSize = Math.pow(2, z);
    const level = {
      minX: Math.floor(mercatorXfromLng(this.boundsExtent.xmin) * worldSize),
      minY: Math.floor(mercatorYfromLat(this.boundsExtent.ymax) * worldSize),
      maxX: Math.ceil(mercatorXfromLng(this.boundsExtent.xmax) * worldSize),
      maxY: Math.ceil(mercatorYfromLat(this.boundsExtent.ymin) * worldSize),
    };

    const hit =
      z >= this.minZoom &&
      z <= this.maxZoom &&
      x >= level.minX &&
      x < level.maxX &&
      y >= level.minY &&
      y < level.maxY;
    return hit;
  }

  async fetchTile(
    level: number,
    row: number,
    col: number,
    options?: __esri.BaseTileLayerFetchTileOptions,
  ): Promise<HTMLImageElement | HTMLCanvasElement> {
    if (this.bounds) {
      const contained = this.contains(level, row, col);
      // return empty canvas and don't try to request tile if it's not in tile bounds
      if (!contained) return document.createElement('canvas');
    }

    const url = this.getTileUrl(level, row, col);
    if (url.length <= 0) {
      return document.createElement('canvas');
    }
    return esriRequest(url, {
      responseType: 'image',
      signal: options?.signal,
      query: {
        _ts: options?.timestamp,
      },
      headers: this.requestHeader && this.requestHeader(),
    }).then((response) => response.data);
  }

  getTileUrl(level: number, row: number, col: number): string {
    return this.buildTileUrl(level, row, col);
  }
}
