import moment from 'moment';

export const dateMonthsAgo = (
  numberOfMonth: number,
  date = new Date(),
): string => moment(date).subtract(numberOfMonth, 'month').format('YYYY-MM-DD');

// Returns an array of labels of max length equal to maxNumber
export const filterLabels = (labels: string[], maxNumber: number): string[] => {
  if (maxNumber === 0) return [];
  const tickFrequency = getLegibleTickFrequency(labels.length, maxNumber);
  return labels.filter((label, index) => index % tickFrequency === 0);
};

// Returns a number N meaning "display a label every N ticks"
// eg. 5 : Display a label every 5 ticks.
// eg. 1 : Display a label every ticks.
// expect labelCount > 0 and maxTick > 0
export const getLegibleTickFrequency = (
  labelCount: number,
  maxTick: number,
): number => {
  if (maxTick < 1 || labelCount < 1) return 1; // maxTick or labelCount < 1 do not make sense. This is a fail-safe.
  return Math.ceil(labelCount / maxTick);
};
