import React, { createContext, useContext } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios, { AxiosInstance } from 'axios';

import mockAPI, { isAPIMockEnabled } from 'src/api/mock/mockAPI';
import { useAuth } from 'src/auth/AuthContext';
import environment from 'src/environments/environment';

const APIContext = createContext<AxiosInstance>(axios.create());
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
    },
  },
});

function APIProvider({
  children,
}: React.PropsWithChildren<unknown>): React.ReactElement {
  const { getUserToken } = useAuth();
  const httpClient = axios.create({
    baseURL: environment.apiBaseUrl,
    headers: {
      'User-Token': getUserToken() ?? '',
    },
    auth: {
      username: environment.basicAuthUsername,
      password: environment.basicAuthPassword,
    },
  });
  httpClient.interceptors.response.use(
    (result) => result,
    (error) => {
      console.error(error);
      return Promise.reject(error);
    },
  );

  if (isAPIMockEnabled()) {
    mockAPI(httpClient);
  }

  return (
    <QueryClientProvider client={queryClient}>
      <APIContext.Provider value={httpClient}>{children}</APIContext.Provider>
    </QueryClientProvider>
  );
}

const useApi = (): AxiosInstance => useContext(APIContext);
export { APIProvider, useApi };
