import React from 'react';

import ArcGisMap, { ArcGisMapProps } from 'src/components/ArcGisMap';

function Map(props: ArcGisMapProps): React.ReactElement {
  return (
    <ArcGisMap
      nativeWidgets={{
        zoom: true,
        coordinateConversion: true,
        scaleBar: true,
        baseMapToggle: true,
      }}
      {...props}
    />
  );
}

export default Map;
