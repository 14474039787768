import React, { useMemo } from 'react';

import { ReactComponent as BoxArrowUpRight } from 'bootstrap-icons/icons/box-arrow-up-right.svg';
import classNames from 'classnames';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Config } from 'src/api/internalApi';
import { ReactComponent as Logo } from 'src/assets/images/preligens-logo.svg';
import { useAuth } from 'src/auth/AuthContext';
import { dashboardPath } from 'src/auth/routes';
import SurveyExportControl from 'src/components/SurveyExportControl';
import { useConfig } from 'src/config/ConfigContext';

import './index.scss';

interface InsightsLink {
  key: keyof Config['arcgis_insights'];
  title: string;
  id: string;
}

export function getInitials(fullName: string): string {
  return fullName
    .split(' ')
    .filter((_, index, array) => index === 0 || index === array.length - 1)
    .map((word) => (word.length && word[0]) || '')
    .join('')
    .toUpperCase();
}

const Navigation: React.FC = (): React.ReactElement => {
  const auth = useAuth();
  const { t } = useTranslation();
  const config = useConfig().config!;
  const path = window.location.pathname;
  const widgetBaseURL = 'https://insights.arcgis.com/#/view/';

  const handleClickLogout: Parameters<Dropdown['Item']>[0]['onClick'] = (e) => {
    e.preventDefault();
    auth.signOut();
  };

  const insightsLinksRepository = useMemo<InsightsLink[]>(() => {
    const menuItems: Omit<InsightsLink, 'id'>[] = [
      {
        key: 'overview_pai',
        title: t('navBar.arcgisWidgets.overviewPai'),
      },
      {
        key: 'lines',
        title: t('navBar.arcgisWidgets.lines'),
      },
      {
        key: 'consumption',
        title: t('navBar.arcgisWidgets.consumption'),
      },
      {
        key: 'dependency',
        title: t('navBar.arcgisWidgets.dependency'),
      },
      {
        key: 'social_media',
        title: t('navBar.arcgisWidgets.socialMedia'),
      },
      {
        key: 'evaluation',
        title: t('navBar.arcgisWidgets.evaluation'),
      },
    ];
    return menuItems
      .filter((link) =>
        Object.keys(config.arcgis_insights).some((key) => link.key === key),
      )
      .map((link) => ({ ...link, id: config.arcgis_insights[link.key] }));
  }, [config.arcgis_insights]);

  return (
    <Navbar
      collapseOnSelect
      bg="white"
      expand="lg"
      data-testid="main-navbar"
      className="Navigation"
    >
      <Navbar.Brand href={dashboardPath}>
        <Logo width="30" height="30" className="d-inline-block align-top" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto" data-testid="navbar">
          <Nav.Link
            as={Link}
            to={dashboardPath}
            eventKey={'home'}
            className={classNames({ active: path === dashboardPath })}
          >
            {t('navBar.home')}
          </Nav.Link>
          {insightsLinksRepository.map((link) => (
            <Nav.Link
              href={`${widgetBaseURL}${link.id}`}
              target="_blank"
              key={link.key}
              eventKey={link.key}
              className={'d-flex align-items-center'}
              data-testid="widget-link"
            >
              {link.title}&nbsp;
              <BoxArrowUpRight width={14} height={14} />
            </Nav.Link>
          ))}
        </Nav>
        <Nav>
          <Nav.Item className="mr-lg-3 my-2 my-lg-0">
            <SurveyExportControl
              surveyKey="power_plant"
              surveyName={t('navBar.surveys.powerPlant')}
              data-testid="electric-power-survey-dropdown"
            />
          </Nav.Item>
          <Nav.Item className="mr-lg-3 my-2 my-lg-0">
            <SurveyExportControl
              surveyKey="area_of_operation"
              surveyName={t('navBar.surveys.areaOfOperation')}
              data-testid="power-network-survey-dropdown"
            />
          </Nav.Item>
          <Nav.Item className="my-2 my-lg-0">
            <DropdownButton
              menuAlign="right"
              title={getInitials(auth.user?.fullName || '')}
              variant="primary"
              size="sm"
              data-testid="user-dropdown"
            >
              <Dropdown.Item
                className="color-danger"
                onClick={handleClickLogout}
                data-testid="logout-action"
              >
                {t('navBar.logout')}
              </Dropdown.Item>
            </DropdownButton>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
