import React, {
  createContext,
  useState,
  useContext,
  PropsWithChildren,
} from 'react';

import { v4 as uuidv4 } from 'uuid';

export enum NotificationType {
  Info = 1,
  Success,
  Error,
  InProgress,
}

type NewNotification = {
  type?: NotificationType;
  title: string;
  content: string | React.ReactElement;
  hideClose?: boolean;
  duration?: number;
  dataTestID?: string;
};

type NotificationContextType = {
  showNotification: (notification: NewNotification) => string;
  getAllNotifications: () => Notification[];
  updateNotificationByID: (
    notificationID: string,
    notification: Partial<NewNotification>,
  ) => void;
  removeNotificationByID: (notificationID: string) => void;
};

const NotificationContext: React.Context<NotificationContextType> =
  createContext<NotificationContextType>({
    showNotification: () => '',
    getAllNotifications: () => [],
    updateNotificationByID: () => undefined,
    removeNotificationByID: () => undefined,
  });

export type Notification = {
  id: string;
  type: NotificationType;
  title: string;
  content: string | React.ReactElement;
  hideClose: boolean;
  duration?: number;
  dataTestID?: string;
};

const NotificationProvider = ({ children }: PropsWithChildren) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const showNotification = (notification: NewNotification): string => {
    const newNotificationID = uuidv4();
    setNotifications((current) => [
      ...current,
      {
        id: newNotificationID,
        type: notification.type || NotificationType.Info,
        duration: parseDuration(notification.duration),
        hideClose: !!notification.hideClose,
        ...notification,
      },
    ]);
    return newNotificationID;
  };

  const getAllNotifications = (): Notification[] => {
    return notifications;
  };

  const updateNotificationByID = (
    notificationID: string,
    updatedNotification: Partial<NewNotification>,
  ): void => {
    setNotifications((current) =>
      current.map((notification) => {
        if (notification.id === notificationID) {
          return {
            ...notification,
            ...updatedNotification,
          };
        }
        return notification;
      }),
    );
  };

  const removeNotificationByID = (notificationID: string): void => {
    setNotifications((current) =>
      current.filter((notification) => notification.id !== notificationID),
    );
  };

  return (
    <NotificationContext.Provider
      value={{
        showNotification,
        getAllNotifications,
        updateNotificationByID,
        removeNotificationByID,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

function parseDuration(duration?: number): number | undefined {
  if (duration && duration > 0) {
    return duration;
  }
  return undefined;
}

const useNotification = (): NotificationContextType =>
  useContext(NotificationContext);

export { NotificationProvider, useNotification };
