import MapView from '@arcgis/core/views/MapView';

export function addZoom(
  mapView: MapView,
  position?: __esri.UIAddPosition,
): void {
  mapView.ui.add('zoom', position);
}

export function removeZoom(mapView: MapView): void {
  mapView.ui.remove('zoom');
}
