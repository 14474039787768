import { AxiosInstance } from 'axios';

import { SourceImageBounds } from 'src/components/ArcGisMap';

export const getSourceImageBounds = async (
  siteId: string,
  httpClient: AxiosInstance,
): Promise<SourceImageBounds> => {
  try {
    const { data: tileMapResourceFile } = await httpClient.get(
      `/tiles/vhr/${siteId}/xyz/tilemapresource.xml`,
    );
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(tileMapResourceFile, 'text/xml');
    const boundingBox = xmlDoc.getElementsByTagName('BoundingBox')[0];
    const bounds: [number, number, number, number] = [
      +(boundingBox.getAttribute('minx') || -180),
      +(boundingBox.getAttribute('miny') || -90),
      +(boundingBox.getAttribute('maxx') || 180),
      +(boundingBox.getAttribute('maxy') || 90),
    ];

    const tileSets = xmlDoc.getElementsByTagName('TileSet');
    const zoomLevels = Array.from(tileSets)
      .map((tileSet) => {
        const zoomValue = tileSet.getAttribute('href');
        return zoomValue !== null ? +zoomValue : zoomValue;
      })
      .filter(
        (zoomValue): zoomValue is number =>
          zoomValue !== null && typeof zoomValue === 'number',
      );

    const minZoom = Math.min(...zoomLevels);
    const maxZoom = Math.max(...zoomLevels);
    return {
      bounds,
      minZoom,
      maxZoom,
    };
  } catch (error) {
    console.group();
    console.error('Failed to retrieve source image bounds for site %s', siteId);
    console.error(JSON.stringify(error));
    console.groupEnd();
    return {
      bounds: undefined,
      minZoom: undefined,
      maxZoom: undefined,
    };
  }
};
