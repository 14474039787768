import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from 'react';

import Config from '@arcgis/core/config';

import { isEsriError } from 'src/models/guards/esriError.guard';

interface SiteData {
  siteUUID: string;
  siteName: string;
}

export interface Area {
  id: string;
  name: string;
  boundingBox: GeoJSON.BBox;
  polygon: GeoJSON.Polygon;
  isSwirMonitored: boolean;
}

export interface DashboardContextType {
  siteData: SiteData | null;
  setSiteData: React.Dispatch<SiteData>;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  isInBusyState: boolean;
  setIsInBusyState: React.Dispatch<React.SetStateAction<boolean>>;
  selectedSiteUUID: string | null;
  setSelectedSiteUUID: React.Dispatch<React.SetStateAction<string | null>>;
  runUUIDToValidate: string | null;
  setRunUUIDToValidate: React.Dispatch<React.SetStateAction<string | null>>;
  isMapLegendVisible: boolean;
  setMapLegendVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  mapKey: string | null;
  setMapKey: React.Dispatch<React.SetStateAction<string | null>>;
  setAuthorizationError: (errorMessage: string) => void;
  resetAuthorizationErrors: () => void;
  authorizationErrors: string[];
}

const DashboardContext = createContext<DashboardContextType>({
  siteData: null,
  setSiteData: () => void 0,
  show: false,
  setShow: () => void 0,
  isInBusyState: false,
  setIsInBusyState: () => void 0,
  selectedSiteUUID: null,
  setSelectedSiteUUID: () => void 0,
  runUUIDToValidate: null,
  setRunUUIDToValidate: () => void 0,
  isMapLegendVisible: false,
  setMapLegendVisibility: () => void 0,
  mapKey: null,
  setMapKey: () => void 0,
  setAuthorizationError: () => void 0,
  resetAuthorizationErrors: () => void 0,
  authorizationErrors: [],
});

const DashboardProvider = (props: PropsWithChildren) => {
  const [siteData, setSiteData] = useState<SiteData | null>(null);
  const [show, setShow] = useState(false);
  const [isInBusyState, setIsInBusyState] = useState(false);
  const [selectedSiteUUID, setSelectedSiteUUID] = useState<string | null>(null);
  const [runUUIDToValidate, setRunUUIDToValidate] = useState<string | null>(
    null,
  );
  const [isMapLegendVisible, setMapLegendVisibility] = useState(false);
  const [mapKey, setMapKey] = useState<string | null>(null);
  const [authorizationErrors, setAuthorizationErrors] = useState<string[]>([]);

  const setAuthorizationError = (errorMessage: string): void => {
    setAuthorizationErrors((existing) => [...existing, errorMessage]);
  };

  const resetAuthorizationErrors = (): void => {
    setAuthorizationErrors([]);
  };

  useEffect(() => {
    listenToBeforeunload(isInBusyState);
    return () => listenToBeforeunload(false);
  }, [isInBusyState]);

  useEffect(() => {
    const config = Config;
    config.log.interceptors.push(
      function (level, _module, _name, _message, error) {
        if (
          level === 'error' &&
          isEsriError(error) &&
          isEsriError(error.details.error)
        ) {
          setAuthorizationError(error.details.error.message);
        }
        // Return false so that the default log handler still writes log messages to the console
        return false;
      },
    );
  }, []);

  return (
    <DashboardContext.Provider
      value={{
        siteData,
        setSiteData,
        show,
        setShow,
        isInBusyState,
        setIsInBusyState,
        selectedSiteUUID,
        setSelectedSiteUUID,
        runUUIDToValidate,
        setRunUUIDToValidate,
        isMapLegendVisible,
        setMapLegendVisibility,
        mapKey,
        setMapKey,
        setAuthorizationError,
        resetAuthorizationErrors,
        authorizationErrors,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};

export { DashboardContext, DashboardProvider };

function unloadHandler(e: BeforeUnloadEvent) {
  e.preventDefault();
  e.returnValue = '';
}

function listenToBeforeunload(listen: boolean) {
  if (listen) {
    window.addEventListener('beforeunload', unloadHandler, { capture: true });
  } else {
    window.removeEventListener('beforeunload', unloadHandler, {
      capture: true,
    });
  }
}
