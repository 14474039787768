import React, { useContext } from 'react';

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { dashboardPath } from 'src/auth/routes';
import NotificationAnchor from 'src/components/Notification/NotificationAnchor';
import { DashboardContext } from 'src/pages/Dashboard/context';

import './index.scss';

function NotificationAnchorPlacement(): React.ReactElement {
  const location = useLocation();
  const { isMapLegendVisible } = useContext(DashboardContext);
  const isOnDashboard = location.pathname === dashboardPath;
  return (
    <div className="notification__relativeContainer">
      <div
        className={classNames({
          notification__anchorContainer: true,
          '-max-height-small': isOnDashboard,
          '-offset-right': isOnDashboard && isMapLegendVisible,
          '-offset-right-small': isOnDashboard && !isMapLegendVisible,
        })}
      >
        <NotificationAnchor />
      </div>
    </div>
  );
}

export default NotificationAnchorPlacement;
