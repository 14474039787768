import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';

export interface PolygonType {
  fillColor: [number, number, number, number] | [number, number, number];
  outlineColor: [number, number, number, number] | [number, number, number];
}

export type LegendColorType = PolygonType;

export const polygonTypes: { [key: string]: PolygonType } = {
  yellow: {
    fillColor: [255, 212, 0, 0.25],
    outlineColor: [255, 212, 0],
  },
  purpleBlue: {
    fillColor: [96, 109, 181, 0.3],
    outlineColor: [96, 109, 181],
  },
  black: {
    fillColor: [0, 0, 0, 0.4],
    outlineColor: [0, 0, 0],
  },
  white: {
    fillColor: [255, 255, 255, 0.4],
    outlineColor: [255, 255, 255],
  },
  darkBlue: {
    fillColor: [0, 99, 130, 0.2],
    outlineColor: [0, 99, 130],
  },
  purple: {
    fillColor: [177, 98, 204, 0.3],
    outlineColor: [177, 98, 204],
  },
  brown: {
    fillColor: [183, 129, 74, 0.25],
    outlineColor: [183, 129, 74],
  },
  greenWithNoFill: {
    fillColor: [255, 255, 255, 0],
    outlineColor: [5, 191, 146],
  },
};

export const legendTypes: { [key: string]: LegendColorType } = {
  ...polygonTypes,
  darkGray: {
    fillColor: [142, 148, 149, 1],
    outlineColor: [142, 148, 149],
  },
  lightGray: {
    fillColor: [207, 205, 205, 1],
    outlineColor: [207, 205, 205],
  },
};

interface PolygonFillSymbolOptions {
  isOutlineDashed?: boolean;
}

function createPolygonFillSymbol(
  polygonType: PolygonType,
  options?: PolygonFillSymbolOptions,
): __esri.SimpleFillSymbol {
  return new SimpleFillSymbol({
    color: polygonType.fillColor,
    outline: {
      color: polygonType.outlineColor,
      width: 3,
      style: (options?.isOutlineDashed && 'short-dot') || undefined,
    },
  });
}

export const yellowFillSymbol = (
  options?: PolygonFillSymbolOptions,
): __esri.SimpleFillSymbol =>
  createPolygonFillSymbol(polygonTypes.yellow, options);

export const blueFillSymbol = (
  options?: PolygonFillSymbolOptions,
): __esri.SimpleFillSymbol =>
  createPolygonFillSymbol(polygonTypes.purpleBlue, options);

export const blackFillSymbol = (
  options?: PolygonFillSymbolOptions,
): __esri.SimpleFillSymbol =>
  createPolygonFillSymbol(polygonTypes.black, options);

export const brownFillSymbol = (
  options?: PolygonFillSymbolOptions,
): __esri.SimpleFillSymbol =>
  createPolygonFillSymbol(polygonTypes.brown, options);

export const purpleFillSymbol = (
  options?: PolygonFillSymbolOptions,
): __esri.SimpleFillSymbol =>
  createPolygonFillSymbol(polygonTypes.purple, options);

export type colorFillSymbol = (
  options?: PolygonFillSymbolOptions,
) => __esri.SimpleFillSymbol;
