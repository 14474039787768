import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import enLocale from './en/translation.json';
import testLocale from './test/translation.json';

export const resources = {
  en: { translation: enLocale },
  test: { translation: testLocale },
} as const;

i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'en',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    resources,
  });

export default i18next;
