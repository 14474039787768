import React, { FormEvent } from 'react';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';

import {
  PowerPlantClass,
  powerPlantClassList,
} from 'src/components/AnalysisResultsPowerPlantClassForm/powerPlantClass';

import './index.scss';

interface Props {
  predictedPowerPlantClass?: PowerPlantClass;
  onPowerPlantClassChange: (value: PowerPlantClass) => void;
  disableSubmit: boolean;
  isSubmitting: boolean;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  submitFailedErrorMessage?: string;
}

const AnalysisResultsPowerPlantClassForm = ({
  predictedPowerPlantClass,
  onPowerPlantClassChange,
  disableSubmit,
  isSubmitting,
  onSubmit,
  submitFailedErrorMessage,
}: Props): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <div className="powerPlantForm_title">
        {t('analysisResultsPanel.powerPlantType')}
      </div>
      <Form className="powerPlantForm_source" onSubmit={onSubmit}>
        <Form.Group>
          {powerPlantClassList.map((powerPlantClass) => {
            const isDefaultChoice =
              powerPlantClass === predictedPowerPlantClass;
            let radioLabel = t(`powerPlantsSources.${powerPlantClass}`);
            if (isDefaultChoice) {
              radioLabel = `${radioLabel} ${t(
                'powerPlantsSources.preligensAlgorithmOutput',
              )}`;
            }
            return (
              <Form.Check
                key={powerPlantClass}
                type="radio"
                name="class"
                id={powerPlantClass}
                label={radioLabel}
                defaultChecked={isDefaultChoice}
                onClick={() => onPowerPlantClassChange(powerPlantClass)}
              />
            );
          })}
        </Form.Group>
        <div className="d-flex flex-column">
          {submitFailedErrorMessage && (
            <div
              className="w-100"
              data-testid="analysis-results-panel-validation-error"
            >
              <Alert variant="danger">{submitFailedErrorMessage}</Alert>
            </div>
          )}
          <Button
            className="align-self-end"
            type="submit"
            disabled={disableSubmit}
            data-testid="analysis-validate-button"
          >
            {isSubmitting && (
              <Spinner className="mr-2" animation="border" size="sm" />
            )}
            {t('analysisResultsPanel.validateAndSave')}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AnalysisResultsPowerPlantClassForm;
