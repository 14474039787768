/* istanbul ignore file */

import { AxiosInstance } from 'axios';
import MockAdaptater from 'axios-mock-adapter';

import {
  RunResponse,
  RunStatus,
  RunValidationResponse,
  Config,
} from 'src/api/internalApi';

import areasOfOperation from './areas-of-operation';
import { config } from './config';
import { surveyExportOutdated, surveyExportUpToDate } from './site';
import {
  results,
  run,
  analysisRunning,
  analysisSuccess,
  validationRunning,
  validationSuccess,
  surveyOutdated,
} from './vhr';

export default function mockAPI(axiosInstance: AxiosInstance): void {
  console.log('🔥🔥🔥 API IS (partially) MOCKED 🔥🔥🔥');
  const mock = new MockAdaptater(axiosInstance);
  mock.onPost('/vhr/run').reply<RunResponse>(201, run);
  mock
    .onGet(new RegExp(`/vhr/.*/status`))
    .replyOnce<RunStatus>(200, analysisRunning)
    .onGet(new RegExp(`/vhr/.*/status`))
    .replyOnce<RunStatus>(200, analysisSuccess)
    .onGet(new RegExp(`/vhr/.*/status`))
    .replyOnce<RunStatus>(200, validationRunning)
    .onGet(new RegExp(`/vhr/.*/status`))
    .reply<RunStatus>(200, validationSuccess)
    .onGet(new RegExp(`/site/vhr-status`))
    .reply(200, surveyOutdated);
  mock.onGet(new RegExp(`/vhr/.*/results`)).reply(200, results);
  mock
    .onPost(new RegExp(`/vhr/.*/validate`))
    .reply<RunValidationResponse>(200, run);
  mock
    .onGet(new RegExp(`/site/vhr-survey-status/area_of_operation`))
    .reply(200, surveyExportUpToDate);
  mock
    .onGet(new RegExp(`/site/vhr-survey-status/power_plant`))
    .reply(200, surveyExportOutdated);
  mock.onGet('/areas-of-operation/').reply(200, areasOfOperation);
  mock.onAny().passThrough();
}

export function mockConfigAPI(axiosInstance: AxiosInstance): void {
  const mock = new MockAdaptater(axiosInstance);
  mock.onGet(new RegExp(`/config`)).reply<Config>(200, config);
}

export function isAPIMockEnabled(): boolean {
  return process.env.REACT_APP_MOCK_API === 'true';
}
