/**
 *
 * @param lng
 * @returns number
 * Project Lng coordinate to map pixel positon
 */
export function mercatorXfromLng(lng: number): number {
  return (180 + lng) / 360;
}

/**
 *
 * @param lat
 * @returns number
 * Project Lat coordinate to map pixel positon
 */
export function mercatorYfromLat(lat: number): number {
  return (
    (180 -
      (180 / Math.PI) *
        Math.log(Math.tan(Math.PI / 4 + (lat * Math.PI) / 360))) /
    360
  );
}

/**
 *
 * @param bounds
 * @returns bounds
 * Check bounds array, replace with default value if there is a problem to keep bounds consistent
 */
export function validateBounds(
  bounds?: GeoJSON.BBox,
): [number, number, number, number] {
  // make sure the bounds property contains valid longitude and latitudes
  if (!Array.isArray(bounds) || bounds.length !== 4)
    return [-180, -90, 180, 90];
  return [
    Math.max(-180, bounds[0]),
    Math.max(-90, bounds[1]),
    Math.min(180, bounds[2]),
    Math.min(90, bounds[3]),
  ];
}
