import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import IdentityManager from '@arcgis/core/identity/IdentityManager';
import OAuthInfo from '@arcgis/core/identity/OAuthInfo';
import Portal from '@arcgis/core/portal/Portal';
import PortalItem from '@arcgis/core/portal/PortalItem';

import environment from 'src/environments/environment';
import { isEsriError } from 'src/models/guards/esriError.guard';

export type AuthContextType = {
  user: __esri.PortalUser | null;
  isFetchingCredential: boolean;
  isAuthenticated: () => boolean;
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
  initialize: () => void;
  getUserToken: () => string | undefined;
  getUserId: () => string | undefined;
  isUnauthorized: boolean;
  setItemsAccessCheck: React.Dispatch<React.SetStateAction<string[] | null>>;
};

const AuthContext = createContext<AuthContextType>({
  user: null,
  isFetchingCredential: true,
  isAuthenticated: () => false,
  signIn: () => new Promise(() => Promise.resolve()),
  signOut: () => new Promise(() => Promise.resolve()),
  initialize: () => null,
  getUserToken: () => '',
  getUserId: () => '',
  isUnauthorized: false,
  setItemsAccessCheck: () => void 0,
});

const oauthInfo = new OAuthInfo({
  appId: environment.oauth2AppId,
  portalUrl: environment.oauth2PortalUrl,
  popup: false,
});

const sleep = (time: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(() => resolve(), time);
  });

function AuthProvider({ children }: PropsWithChildren) {
  const [user, setUser] = useState<__esri.PortalUser | null>(null);
  const [credential, setCredential] = useState<__esri.Credential | null>(null);
  const [isFetchingCredential, setFetchingCredential] = useState(true);
  const [isUnauthorized, setUnauthorized] = useState<boolean>(false);
  const [itemsAccessCheck, setItemsAccessCheck] = useState<string[] | null>([]);

  useEffect(() => {
    if (Array.isArray(itemsAccessCheck) && itemsAccessCheck.length <= 0) return;
    async function init() {
      await initialize();
      try {
        const values = await Promise.all([checkCurrentStatus(), sleep(1000)]);
        setCredential(values[0]);
        const portal = new Portal();
        await portal.load();
        setUser(portal.user);
        await checkPermissions(itemsAccessCheck || []);
      } catch (error) {
        setCredential(null);
        setUser(null);
        if (
          isEsriError(error) &&
          error.name === 'identity-manager:not-authorized'
        ) {
          await signOut();
        }
      } finally {
        setFetchingCredential(false);
      }
    }
    setUnauthorized(false);
    init();
    // eslint-disable-next-line no-sparse-arrays
  }, [, itemsAccessCheck]);

  const checkPermissions = async (portalItemIds: string[]) =>
    Promise.all([
      ...portalItemIds.map((id) => new PortalItem({ id }).load()),
    ]).catch((error) => {
      setUnauthorized(true);
      throw error;
    });

  const isAuthenticated = (): boolean => Boolean(credential);

  const initialize = async () =>
    IdentityManager.registerOAuthInfos([oauthInfo]);

  const checkCurrentStatus = async () =>
    IdentityManager.checkSignInStatus(`${oauthInfo.portalUrl}/sharing`);

  const fetchCredentials = async () =>
    IdentityManager.getCredential(`${oauthInfo.portalUrl}/sharing`);

  const getUserToken = (): string | undefined => credential?.token;

  const getUserId = (): string | undefined => credential?.userId;

  const signIn = async () => {
    if (!credential) {
      try {
        const fetchedCredential = await checkCurrentStatus();
        setCredential(fetchedCredential);
      } catch (error) {
        const fetchedCredential = await fetchCredentials();
        setCredential(fetchedCredential);
      }
    }
  };

  const signOut = async () => {
    // make sure the identitymanager has
    // the credential so it can destroy it
    await signIn();
    IdentityManager.destroyCredentials();
    setCredential(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isFetchingCredential,
        isAuthenticated,
        signIn,
        signOut,
        initialize,
        getUserToken,
        getUserId,
        isUnauthorized,
        setItemsAccessCheck,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = (): AuthContextType => useContext(AuthContext);
export { AuthContext, AuthProvider, useAuth };
